import React, { Fragment, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogActions, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';

import { MCustomSnackbar } from '../../../components/ui/MCustomSnackbar.js';
import { useComparisonContext } from '../../../context/ComparisonContext';
import { createEventCall, pryvApiCall, updateEventCall } from '../../../data/apiCalls.js';
import { loadPatientData } from '../../../utils/auxFunctions.js';

const CustomTextField = withStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.warning.main
    },
    '& .MuiFilledInput-underline.Mui-error:after': {
      borderBottomColor: theme.palette.warning.main
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.palette.warning.main
    }
  }
}))(TextField);

/**
 * Renders the MSaveComparisonDialog element
 * @param {Object} props The component properties.
 * @return {jsx} The component.
 */
export const MSaveComparisonDialog = ({
  isOpen,
  setOpen,
  savedComparisons,
  setSavedComparisons
}) => {
  const { selectionA, selectionB } = useComparisonContext();
  const [comparisonName, setComparisonName] = useState('');
  const [snackbar, setSnackbar] = React.useState({
    message: '',
    severity: '',
    show: false
  });

  const textRef = useRef();

  const patientData = loadPatientData();
  const selectedActivities = {
    'Group A': selectionA.map((act) => act.id),
    'Group B': selectionB.map((act) => act.id)
  };

  const savedComparisonsNames = savedComparisons.map((item) => item.content.name);
  const onInputChange = () => {
    setComparisonName(textRef.current.value);
  };

  const onSaveClick = () => {
    if (comparisonName === '') {
      setSnackbar({
        message: 'Please Enter a Name',
        severity: 'error',
        show: true
      });
      setComparisonName('');
      return;
    }

    const comparisonContent = {
      patient: patientData.username,
      name: comparisonName,
      activities: selectedActivities
    };

    let call;
    let callType;
    if (savedComparisonsNames.includes(comparisonName)) {
      callType = 'updated';

      const comparisonEvent = savedComparisons.filter(
        (item) => item.content.name === comparisonName
      )[0];
      call = updateEventCall(comparisonEvent.id, comparisonContent);
    } else {
      callType = 'created';
      call = createEventCall(
        ['comparisons'],
        'comparison-magnes/nushu-v1',
        comparisonContent,
        new Date().getTime() / 1000
      );
    }

    const dbEndpoint = sessionStorage.getItem('docEP');

    pryvApiCall(dbEndpoint, call)
      .then((res) => {
        if (Object.keys(res).includes('error')) {
          throw Error(`Bad api call, DB returned ${res}`);
        }
        const newEvent = res[0].event;

        // If event already exists, replace it, otherwise append.
        const savedComparisonsArray = [...savedComparisons];
        if (callType === 'updated') {
          const elementIndex = savedComparisonsArray.findIndex((item) => {
            return item.content.name === newEvent.content.name;
          });
          savedComparisonsArray.splice(elementIndex, 1, newEvent);
        }
        savedComparisonsArray.push(newEvent);

        setSavedComparisons(savedComparisonsArray);

        setSnackbar({
          message: `Comparison successfully ${callType}`,
          severity: 'success',
          show: true
        });
        setOpen(false);
      })
      .catch((err) => {
        setSnackbar({
          message: `Ooops, something went wrong.`,
          severity: 'error',
          show: true
        });
        console.error(err);
      });
  };

  const onCancelClick = () => {
    setComparisonName('');
    setOpen(false);
  };

  return (
    <Fragment>
      {snackbar.show && (
        <MCustomSnackbar
          content={snackbar}
          handleClose={() =>
            setSnackbar({
              message: '',
              severity: '',
              show: false
            })
          }
        />
      )}
      <Dialog maxWidth="xl" open={isOpen}>
        <Box sx={{ width: 400, padding: 1 }}>
          <CustomTextField
            label="Enter Comparison Name"
            variant="filled"
            helperText={
              savedComparisonsNames.includes(comparisonName)
                ? 'A comparison already exists with that name. Do you want to update it?'
                : undefined
            }
            inputRef={textRef}
            onChange={onInputChange}
            error={savedComparisonsNames.includes(comparisonName)}
            sx={{ padding: 1, display: 'flex' }}
          />
        </Box>
        <DialogActions sx={{ marginTop: -1 }}>
          <Button onClick={onCancelClick} variant="outlined">
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSaveClick}>
            {savedComparisonsNames.includes(comparisonName) ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

MSaveComparisonDialog.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  savedComparisons: PropTypes.array,
  setSavedComparisons: PropTypes.func
};
