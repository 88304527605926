import React, { Fragment, useEffect, useState } from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab';
import { Avatar, Box, CircularProgress, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton.js';
import {
  createEventCall,
  deleteEventCall,
  eventsFromStreams,
  pryvApiCall,
  updateEventCall
} from '../../../data/apiCalls.js';
import { timelineDefs } from '../../../definitions/timeline.js';
import { makeDateString, makeTimeOfDayString } from '../../../utils/auxFunctions.js';

import { MAddTimelineEntryDialog } from './MAddTimelineEntryDialog.js';
import { MDeleteTimelineEntryDialog } from './MDeleteTimelineEntryDialog.js';
import { MTimelineCard } from './MTimelineCard.js';

const DATE_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
};

export const generateEntryStr = (type, date, content) => {
  if (!Object.keys(timelineDefs).includes(type)) {
    throw new Error('Passed entry type not recognised!');
  }
  const entry = {
    type: type,
    date: date.getTime() / 1000,
    content: content
  };
  return JSON.stringify(entry);
};

/**
 * Renders the "MPatientTimeline" component.
 * @param {Object} props of the page.
 * @return {jsx} The patient timeline component.
 */
export const MTimeline = ({endpoint, macroEvents, firstAssessmentDate, registrationDate}) => {
  const [openEntryDialog, setOpenEntryDialog] = useState(false);
  const [timelineEntries, setTimelineEntries] = useState([]);
  const [deletionDialog, setDeletionDialog] = useState({
    open: false,
    id: undefined
  });
  
  useEffect(() => {
    pryvApiCall(endpoint, eventsFromStreams(['comments'])).then((res) => {
      const timelineEvents = res[0].events;
      const entries = timelineEvents.map( (te) => {
        return {
          ...JSON.parse(te.content),
          id: te.id,
        }
      })
      // Check if default entries, create if necessary
      if (entries.filter( (entry) => entry.type === 'First Assessment').length === 0) {
        const firstAssessmentEntry = generateEntryStr(
          'First Assessment',
          firstAssessmentDate,
          'Completed the first exercise using Nushus.'
        );
        
        const makeFirstAssEntry = createEventCall(
          ['comments'],
          'note/txt',
          firstAssessmentEntry,
          new Date().getTime() / 1000
        )
        pryvApiCall(endpoint, makeFirstAssEntry)
        entries.unshift(JSON.parse(firstAssessmentEntry));
      }

      if (entries.filter( (entry) => entry.type === 'Registration').length === 0) {
        const registerEntry = generateEntryStr(
          'Registration',
          registrationDate,
          'Signed up using the Nushu App.'
        );

        const makeRegisterEntry = createEventCall(
          ['comments'],
          'note/txt',
          registerEntry,
          new Date().getTime() / 1000
        )
        pryvApiCall(endpoint, makeRegisterEntry)
        
        entries.unshift(JSON.parse(registerEntry));
      }

      setTimelineEntries(entries);
    })
  }, []);

  const sortedEntries = () => timelineEntries.sort((a, b) => (a.date < b.date ? 1 : -1));

  const handleDeleteEntry = () => {
    const selectedEntry = timelineEntries.find((tl) => tl.id === deletionDialog.id);

    pryvApiCall(endpoint, deleteEventCall(deletionDialog.id))
      .then(() => {
        const newEntries = [];
        timelineEntries.forEach((entry) => {
          if (entry.id !== deletionDialog.id) {
            newEntries.push(entry);
          }
        });
        setTimelineEntries(newEntries);
        setDeletionDialog({ open: false, id: undefined });
      })
      .catch((err) => console.error(err));

    if (selectedEntry.type === 'Fall') {
      // Original date of the tl entry to find id of macro event.
      const maEv = macroEvents.find((me) => me.time === selectedEntry.date);
      const maIdx = macroEvents.indexOf(maEv);
      pryvApiCall(endpoint, [
        {
          method: 'events.delete',
          params: {
            id: maEv.id
          }
        }
      ])
        .then(() => {
          macroEvents.slice(maIdx, 1);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleAddEntry = (entry) => {
    const newEntry = generateEntryStr(entry.type, new Date(entry.date * 1000), entry.content);
    pryvApiCall(
      endpoint,
      createEventCall(['comments'], 'note/txt', newEntry, new Date().getTime() / 1000)
    )
      .then((res) => {
        entry.id = res[0].event.id;
        setTimelineEntries([entry, ...timelineEntries]);
        setOpenEntryDialog(false);
      })
      .catch((err) => {
        console.error(err);
      });

    // Generate macro event if it's a fall
    if (entry.type === 'Fall') {
      pryvApiCall(endpoint, [
        {
          method: 'events.create',
          params: {
            streamIds: ['fall'],
            type: 'activity/plain',
            time: entry.date
          }
        }
      ])
        .then((res) => {
          macroEvents.push(res[0].event);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleEditEntry = (entry) => {
    const editedEntry = generateEntryStr(entry.type, new Date(entry.date * 1000), entry.content);
    pryvApiCall(endpoint, updateEventCall(entry.id, editedEntry));
    const newEntries = [...timelineEntries];
    const entryIndex = newEntries.indexOf(newEntries.filter((item) => item.id === entry.id)[0]);
    newEntries[entryIndex] = entry;
    setTimelineEntries(newEntries);

    if (entry.type === 'Fall') {
      // Original date of the tl entry to find id of macro event.
      const date = timelineEntries.find((tl) => tl.id === entry.id).date;
      const maEv = macroEvents.find((me) => me.time === date);

      const maIdx = macroEvents.indexOf(maEv);
      pryvApiCall(endpoint, [
        {
          method: 'events.update',
          params: {
            id: maEv.id,
            update: {
              time: entry.date
            }
          }
        }
      ]).then((res) => {
        macroEvents[maIdx] = res[0].event;
      });
    }
  };

  if (timelineEntries.length !== 0) {
    return (
      <Fragment>
        <Box
          sx={{
            height: '100%',
            overflow: 'scroll',
            pb: 2
          }}
        >
          <Timeline>
            {sortedEntries().map((entry, idx) => {
              const Icon = timelineDefs[entry.type].icon;
              const dayDate = new Date(entry.date * 1000);
              return (
                <TimelineItem key={uuidv4()}>
                  <TimelineOppositeContent
                    sx={{
                      maxWidth: 70,
                      p: 0
                    }}
                  >
                    <Box sx={{ marginTop: 1 }}>
                      <Typography color="textSecondary" sx={{ fontSize: 10, paddingRight: 1 }}>
                        {makeDateString(dayDate, DATE_FORMAT)} <br />
                        {makeTimeOfDayString(dayDate)}
                      </Typography>
                    </Box>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <Avatar variant="rounded" sx={{ width: 30, height: 30, top: 5 }}>
                      <Icon sx={{ fontSize: 20 }} />
                    </Avatar>
                    {idx - sortedEntries().length !== -1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <MTimelineCard
                      entry={entry}
                      index={idx}
                      handleDeleteEntry={() => {
                        setDeletionDialog({ open: true, id: entry.id });
                      }}
                      handleEditEntry={handleEditEntry}
                    />
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Box>
        <MTooltipIconButton
          buttonValue="addEntry"
          title="Add Entry"
          onClickEvent={() => setOpenEntryDialog(true)}
          buttonType="absoluteTooltipMIconButton"
          buttonStyle={{ position: 'relative', right: 0, bottom: 1 }}
        />

        <MAddTimelineEntryDialog
          entry={null}
          isOpen={openEntryDialog}
          onSubmit={handleAddEntry}
          onCancel={() => setOpenEntryDialog(false)}
        />
        <MDeleteTimelineEntryDialog
          isOpen={deletionDialog.open}
          handleSetDeletionDialog={setDeletionDialog}
          deleteEntry={handleDeleteEntry}
        />
      </Fragment>
    );
  } else {
    return (
      <Box
        style={{
          position: 'absolute',
          left: '43%',
          top: '43%'
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
};


MTimeline.propTypes = {
  endpoint: PropTypes.string,
  macroEvents: PropTypes.array,
  firstAssessmentDate: PropTypes.object,
  registrationDate: PropTypes.object
};