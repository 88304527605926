import React, { Fragment, useEffect, useState } from 'react';
import { IoGitCompareOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MCustomSnackbar } from '../../components/ui/MCustomSnackbar.js';
import { MSelectAssessmentsList } from '../../components/ui/MSelectAssessmentsList.js';
import { MTooltipIconButton } from '../../components/ui/MTooltipIconButton';
import { useComparisonContext } from '../../context/ComparisonContext';
import { gaitActivityTypes } from '../../definitions/activityTypes.js';
import { loadPatientData } from '../../utils/auxFunctions.js';

/**
 * Setup Comparison Component
 * @return {jsx} Setup comparison component.
 */
export const MSetupComparison = ({ computeStats }) => {
  const { selectionA, setSelectionA, selectionB, setSelectionB } = useComparisonContext();

  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false
  });

  const theme = useTheme();
  const history = useHistory();
  const patientData = loadPatientData();
  const toPatientClick = () => {
    history.push({ pathname: '/patient/' + patientData.username });
  };

  const onComputeClick = () => {
    if (selectionA.length < 1 || selectionB.length < 1) {
      setSnackbar({
        message: `Nothing to compare!
          Make sure to have selected assessments from each group!`,
        severity: 'warning',
        show: true
      });
      return;
    }
    computeStats(selectionA, selectionB);
  };

  const analyzedGaitActivities = patientData.activities.filter((act) => {
    return (
      gaitActivityTypes.map((gt) => gt.id).includes(act.type) && act.analysisStatus === 'analyzed'
    );
  });
  
  const location = useLocation()
  useEffect(() => {
    if (location.state) {
      const selected = analyzedGaitActivities.find( (aa) => aa.id === location.state.activity)
      setSelectionA([selected])
    }
  }, [])
  
  return (
    <Fragment>
      {snackbar.show && (
        <MCustomSnackbar
          content={snackbar}
          handleClose={() =>
            setSnackbar({
              message: '',
              severity: '',
              show: false
            })
          }
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 40,
          width: 40,
          top: 0,
          right: 5
        }}>
        <MTooltipIconButton
          buttonValue="arrowFromButton"
          title="Go to Patient Page"
          onClickEvent={() => toPatientClick()}
          buttonType="tooltipIconButton"
        />
      </Box>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 1,
          height: '100%'
        }}>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          justify="center"
          alignItems="center"
          sx={{
            height: 'calc(100% - 50px)',
            paddingBottom: 2
          }}>
          <Grid item xs={12} md={6} align="center" sx={{ height: '100%' }}>
            <MSelectAssessmentsList
              activities={analyzedGaitActivities}
              group={'A'}
              setSelection={setSelectionA}
              selectedActs={selectionA}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            align="center"
            sx={{
              height: '100%',
              [theme.breakpoints.down('md')]: { mt: 10 }
            }}>
            <MSelectAssessmentsList
              activities={analyzedGaitActivities}
              group={'B'}
              setSelection={setSelectionB}
              selectedActs={selectionB}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={0}
          sx={{
            height: 50
          }}>
          <Grid item align="center" xs={4}>
            <Typography sx={{ fontSize: 14 }}>Group A</Typography>
          </Grid>
          <Grid item align="center" xs={2}>
            <Tooltip title={'Run Comparison'}>
              <IconButton
                id="run-comparison-button"
                sx={{
                  color: theme.palette.secondary.main,
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    color: theme.palette.secondary.main,
                    opacity: 0.8,
                    backgroundColor: theme.palette.primary.main
                  }
                }}
                onClick={() => onComputeClick()}>
                <IoGitCompareOutline style={{ fontSize: 30 }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item align="center" xs={4}>
            <Typography sx={{ fontSize: 14 }}>Group B</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

MSetupComparison.propTypes = {
  computeStats: PropTypes.func
};
