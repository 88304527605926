import React, { Fragment, useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Box, Dialog, DialogTitle } from '@mui/material';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { PropTypes } from 'prop-types';

import reference from  '../../../definitions/posturographyReference.json';
import { colors } from '../../../styles/colors.js';


const roundNumber = (n, dec) => {
  return Math.round(n * 10 ** dec) / 10 ** dec
}

export const MRombergPlot = ({title, data}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  
  const id = title.toLowerCase().split(' ').join('-')

  const referenceRange = {}
  for (const dir in reference.healthy[id]) {
    const val = reference.healthy[id][dir]
    referenceRange[dir] = {
      min: val.mean - val.std,
      max: val.mean + val.std
    }
  }
  
  const plotData = {
    datasets: [
      {
        label: 'previous',
        backgroundColor: `${colors.darkGrey}50`,
        borderWidth: 2,
        borderColor: colors.darkGrey,
        pointRadius: 10,
        data: data.slice(1).map( x => ({
            x: roundNumber(x.lateral, 2),
            y: roundNumber(x.anteposterior, 2)
        })),
        showLine: false,
      },
      {
        label: 'current',
        backgroundColor: `${colors.lightBlue}50`,
        borderColor: colors.lightBlue2,
        borderWidth: 2,
        pointRadius: 15,
        data: [{
            x: roundNumber(data[0].lateral, 2),
            y: roundNumber(data[0].anteposterior, 2)
        }],
        showLine: false,
      },
    ],
  };

  // Manually scaling x & y axis :'(
  const allValues = {};
  for (const dir in referenceRange) {
    allValues[dir] = [referenceRange[dir].min, referenceRange[dir].max];
    allValues[dir].push(
      ...data.map( (d) => d[dir])
    )
  }

  const ranges = [];
  for (const dir in allValues) {
    ranges.push(Math.max(...allValues[dir]) - Math.min(...allValues[dir]));
  }

  const range = Math.max(...ranges);
  
  const bound = {}
  for (const dir in allValues) {
    bound[dir] = {
      min: roundNumber(Math.min(...allValues[dir]) - 0.1 * range, 1),
      max: roundNumber(Math.max(...allValues[dir]) + 0.1 * range, 1),
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    legend: {
      display: true,
      labels: {
        padding: 1,
        boxWidth: 20,
        fontSize: 10,
        generateLabels: () => {
          return [
            {
              text: "Current  ", // Extra space added to hack Chart.js padding setting
              fillStyle: `${colors.lightBlue}50`,
              strokeStyle: colors.lightBlue2,
              lineWidth: 2,
            },
            {
              text: "Previous  ", // Extra space added to hack Chart.js padding setting
              fillStyle: `${colors.darkGrey}50`,
              lineWidth: 2,
              strokeStyle: colors.darkGrey,

            },
            {
              text: "Healthy Range",
              fillStyle: `${colors.lightGreen2}60`,
              strokeStyle: colors.lightGreen3,
              lineWidth: 2,
            }
          ]
        }
      }
   },
    tooltips: {
      mode: 'nearest',
      intersect: true
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Lateral',
          },
          ticks: {
            ...bound.lateral,
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Anteposterior',
          },
          ticks: {
            ...bound.anteposterior,
          }
        }
      ]
    },
    annotation: {
        events: ["click"],
        annotations: [
          {
            drawTime: 'beforeDatasetsDraw',
            type: 'box',
            xScaleID: 'x-axis-1',
            yScaleID: 'y-axis-1',
            xMin: referenceRange.lateral.min,
            xMax: referenceRange.lateral.max,
            yMin: referenceRange.anteposterior.min,
            yMax: referenceRange.anteposterior.max,
            borderColor: colors.lightGreen3,
            backgroundColor: `${colors.lightGreen2}60`,
            borderWidth: 1,
          },
        ],
    }
  }

  return(
    <Fragment>
      <Box onClick={ () => setDialogOpen(true) }>
        <Scatter options={options} data={plotData} plugins={ChartAnnotation} height={null} width={null} />
      </Box>
      <Dialog onClose={() => setDialogOpen(false)} maxWidth="xl" open={isDialogOpen}>
        <DialogTitle>{title}</DialogTitle>
        <div style={{ height: 700, width: 700, padding: 10 }}>
          <Scatter options={options} data={plotData} plugins={ChartAnnotation} height={null} width={null} />
        </div>
      </Dialog>
    </Fragment>
  )
}


MRombergPlot.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array
};