import React from 'react';
import { MdFindInPage, MdInfo } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

/**
 * Renders the "MMenuList" component. Lists the available pages.
 * @return {jsx} The about page component.
 */
export const MMenuList = () => {
  return (
    <List component="nav">
      {/* About */}
      <Link style={{ textDecoration: 'none', color: 'inherit' }} to={'/about'}>
        <ListItem button>
          <ListItemIcon>
            <MdInfo size={25} />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItem>
      </Link>

      {/* How Stats are computed */}
      <Link style={{ textDecoration: 'none', color: 'inherit' }} to={'/documentation'}>
        <ListItem button>
          <ListItemIcon>
            <MdFindInPage size={25} />
          </ListItemIcon>
          <ListItemText primary="Documentation" />
        </ListItem>
      </Link>
    </List>
  );
};
