export const definedDiagnoses = {
  'Movement Disorders': [
    "Parkinson's Disease",
    'Atypical / Secondary PD',
    'Normal Pressure Hydrocephalus',
    'Other'
  ],
  Stroke: [
    'with right Hemiparesis',
    'with left Hemiparesis',
    'with Ataxia',
    'with Sensoric Deficit',
    'Other'
  ],
  'Demyelinating disease': ['Multiple Sclerosis', 'Other'],
  'Cognitive Decline': [
    'Subjective Decline',
    'Mild cognitive Disorder',
    'Dementia',
    'Alzheimer Disease',
    'Frontotemporal Dementia',
    'Lewy-Body-Disease',
    'Vascular',
    'Other'
  ],
  Spondylogenic: ['Disc Protrusion', 'Spinal Canal Stenosis', 'Other'],
  Miscellenous: ['Polyneuropathy', 'Not listed (yet)', 'Healthy']
};
