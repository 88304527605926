import React from 'react';
import { Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { gaitParameters } from '../../../definitions/gaitParameters.js';

import { MComparisonResultCard } from './MComparisonResultCard.js';

/**
 * East container of comparison results and statistics
 * @return {jsx} East container of comparison results and statistics
 */
export const MComparisonResults = ({ statsA, statsB }) => {
  const formatStats = (param) => {
    return {
      'Group A': {
        L: statsA['step-stats']['L'][param],
        R: statsA['step-stats']['R'][param]
      },
      'Group B': {
        L: statsB['step-stats']['L'][param],
        R: statsB['step-stats']['R'][param]
      }
    };
  };

  return (
    <Grid container spacing={2} align="center" justifyContent="center" sx={{ p: 1 }}>
      {gaitParameters.map((para) => {
        return (
          <Grid item sm={12} md={6} xl={4} key={uuidv4()}>
            <MComparisonResultCard parameter={para} data={formatStats(para.id)} />
          </Grid>
        );
      })}
    </Grid>
  );
};

MComparisonResults.propTypes = {
  statsA: PropTypes.object,
  statsB: PropTypes.object
};
