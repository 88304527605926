import { saveAs } from 'file-saver';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import Pryv from 'pryv';

import { getRegisteredAffiliations, pdfReportsCall, pryvApiCall } from '../data/apiCalls.js';
import environments from '../definitions/environments.json';

const BLACK_RGB = rgb(0, 0, 0);

const toArrayBuffer = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsArrayBuffer(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

export const getAffiliationLogo = async (af) => {
  const storedInstance = window.sessionStorage.getItem('dbInstance')
  const instance = storedInstance ? storedInstance : 'development'

  const affiliationsURL = environments[instance].endpoints.affiliations_url
  
  const registry = await getRegisteredAffiliations(instance)
  if (registry === undefined) {
    return toArrayBuffer('/magnes-logo.png')
  } else {
    return toArrayBuffer(`https://${affiliationsURL}/logos/${registry[af]['logo-fn']}`)
  }
};

export const fetchPdf = async (apiToken, activityId, filename) => {
  const reportInfo = await pryvApiCall(apiToken, pdfReportsCall(activityId)).then((res) => {
    const _details = res[0].events[0].attachments.slice(-1)[0];
    return {
      fileId: _details.id,
      eventId: res[0].events[0].id
    };
  });

  const access = Pryv.utils.extractTokenAndApiEndpoint(apiToken);
  const header = { Authorization: access.token };
  const request = ['events', `${reportInfo.eventId}`, `${reportInfo.fileId}`, `${filename}`].join(
    '/'
  );

  const pdfBytes = await fetch(access.endpoint + request, { method: 'get', headers: header }).then(
    (res) => {
      return res.arrayBuffer();
    }
  );

  return pdfBytes;
};

const getLogoPosition = (embeddedLogo) => {
  const aspectRatio = embeddedLogo.height / embeddedLogo.width;
  
  let width = 100;
  let height;
  if (aspectRatio < 0.75) {
    height = aspectRatio * width;
  } else {
    height = 75;
    width = (1 / aspectRatio) * height;
  }

  return {
    x: 80,
    y: 841 - 75,
    width: width,
    height: height,
  }
}

export const annotateRemoteReport = async (pdfBytes, affiliationShorthand) => {
  const pdfDocument = await PDFDocument.load(pdfBytes)
  const firstPage = pdfDocument.getPage(0)

  const logo = await getAffiliationLogo(affiliationShorthand)
    .then((res) => pdfDocument.embedPng(res));

  firstPage.drawImage(logo, getLogoPosition(logo));

  return await pdfDocument.save() 
}

export const annotatePdf = async (pdfBytes, patientData, doctorData, comment) => {
  const pdfDoc = await PDFDocument.load(pdfBytes);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const logoAB = await getAffiliationLogo(doctorData.affiliationShorthand);
  const pdfLogo = await pdfDoc.embedPng(logoAB);
  const baseXPos = 100;
  const baseYPos = 630;
  const docShift = 210;

  pdfDoc.getPages().forEach((page, idx) => {
    if (idx === 0) {
      // patient
      page.drawText(
        `${patientData.firstName}` + `${' '}` + `${patientData.lastName}`,
        {
          x: baseXPos,
          y: baseYPos + 30,
          size: 10,
          font: font,
          color: BLACK_RGB
        }
      );
      page.drawText(`${patientData.birthdate.toLocaleDateString()}`, {
        x: baseXPos,
        y: baseYPos + 20,
        size: 10,
        font: font,
        color: BLACK_RGB
      });
      page.drawText(`${patientData.height}cm`, {
        x: baseXPos,
        y: baseYPos + 10,
        size: 10,
        font: font,
        color: BLACK_RGB
      });
      page.drawText(`${patientData.weight}kg`, {
        x: baseXPos,
        y: baseYPos,
        size: 10,
        font: font,
        color: BLACK_RGB
      });
      // HCP
      page.drawText(
        `${doctorData.firstName}` + `${' '}` + `${doctorData.lastName}`,
        {
          x: baseXPos + docShift,
          y: baseYPos + 30,
          size: 10,
          font: font,
          color: BLACK_RGB
        }
      );
      page.drawText(`${doctorData.affiliation}`, {
        x: baseXPos + docShift,
        y: baseYPos + 20,
        size: 10,
        font: font,
        color: BLACK_RGB
      });
      page.drawText(`${doctorData.email}`, {
        x: baseXPos + docShift,
        y: baseYPos + 10,
        size: 10,
        font: font,
        color: BLACK_RGB
      });
      page.drawText(`${doctorData.phone}`, {
        x: baseXPos + docShift,
        y: baseYPos,
        size: 10,
        font: font,
        color: BLACK_RGB
      });

      
      }
      page.drawImage(pdfLogo, getLogoPosition(pdfLogo));

    if (idx !== 0) {
      page.drawText(
        `${patientData.firstName}` + `${' '}` + `${patientData.lastName}`,
        {
          x: 400,
          y: page.getHeight() - 70,
          size: 10,
          font: font,
          color: BLACK_RGB
        }
      );
    }
    if (idx === pdfDoc.getPageCount() - 1) {
      page.drawText(`${comment}`, {
        x: baseXPos,
        y: page.getHeight() - 140,
        size: 8,
        font: font,
        color: BLACK_RGB,
        maxWidth: page.getWidth() - 2 * baseXPos,
        lineHeight: 10
      });
    }
  });
  const annotatedPdfBytes = await pdfDoc.save();

  return annotatedPdfBytes;
};

export const downloadPdf = async (pdfBytes, filename) => {
  const newBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  await saveAs(newBlob, `${filename}.pdf`);
};

