import React, {useState} from 'react';
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { Box, Button, Dialog, DialogContent, Divider, Grid, IconButton,Stack } from '@mui/material';
import { PropTypes } from 'prop-types';

import environments from '../../../definitions/environments.json';
import { sleep } from '../../../utils/auxFunctions';

import { MForgotPasswordForm } from './MForgotPasswordForm'
import { MLoginForm } from './MLoginForm'
import { MRegisterForm } from './MRegisterForm';


/**
 * Renders the "MAuthentication" component. Contains authentication forms.
 * @return {jsx} The about page component.
 */
export const MAuthentication = ({isOpen, setOpen}) => {
  const [view, setView] = useState('login')
  const instance = window.sessionStorage.getItem('dbInstance')
  
  if (!instance) return;
  const domain = environments[instance].domain;

  const onClose = () => {
    setOpen(false)
    // Hack to make sure close happens before reset to login view
    sleep(150).then(() => setView('login'))
  }

  const onForgotPasswordClick = () => {
    setView('Forgot Password')
  }

  const onRegisterClick = () => {
    setView('Register')
  }
  
  const backToInitialView = () => {
    setView('login')
  }

  const postLogin = () => {
    setOpen(false)
  }

  return (
    <Dialog open={isOpen} onClose={onClose} sx={{ color: 'yellow' }}>
      <DialogContent>
        {view === 'login' && (
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <Grid container spacing={2} width={400}>
              <MLoginForm domain={domain} postLogin={postLogin}/>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                <Button
                  onClick={onForgotPasswordClick}
                  variant="outlined"
                  sx={{ width: '100%', fontSize: 12 }}>
                  Forgot Password
                </Button>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                <Button
                  onClick={onRegisterClick}
                  variant="outlined"
                  sx={{ width: '100%', fontSize: 12 }}>
                  Register
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        {view === 'Forgot Password' && (
          <Stack
            spacing={2}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}>
            <IconButton
              color="primary"
              onClick={backToInitialView}
              sx={{ borderRadius: 0 }}>
                <MdOutlineArrowBackIosNew />
            </IconButton>
            <MForgotPasswordForm domain={domain}/>
          </Stack>
        )}
        {view === 'Register' && (
          <Stack
            spacing={2}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}>
            <IconButton
              color="primary"
              onClick={backToInitialView}
              sx={{ borderRadius: 0 }}>
                <MdOutlineArrowBackIosNew />
            </IconButton>
            <MRegisterForm
              domain={domain}
              backToInitialView={backToInitialView}
            />
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

MAuthentication.propTypes = {
    isOpen: PropTypes.bool,
    setOpen: PropTypes.func,
  };
