import { colors } from "../../../styles/colors"

// Some shared configs
export const PARAMETER_CFG = {
    initial_time_ms: {
      bandwidth: 25,
      color: colors.darkBlue,
      range: [0, 750],
      tickStep: 100,
      label: {
        title: 'Time',
        decimals: 1,
        unit: 'ms',
      }
    },
    duration_ms: {
      bandwidth: 25,
      color: colors.lightBlue2,
      range: [0, 500],
      tickStep: 100,
      label: {
        title: 'Duration',
        decimals: 1,
        unit: 'ms',
      }
    },
    intensity: {
      bandwidth: 0.75,
      color: colors.lightBlue,
      range: [0, 20],
      tickStep: 5,
      label: {
        title: 'Intensity',
        decimals: 2,
        unit: '',
      }
    },
  }