import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Tooltip
} from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { timelineDefs } from '../../../definitions/timeline.js';

/**
 * Contains the form to add a new comment.
 * @param {Object} props The component properties.
 * @return {jsx} The comment dialog component.
 */
export const MAddTimelineEntryDialog = ({ entry, isOpen, onSubmit, onCancel }) => {
  const theme = useTheme();

  const [entryType, setEntryType] = useState(entry ? entry.type : 'Comment');

  if (entry === null) {
    entry = {
      content: null,
      date: null,
    };
  }

  let timeString = null
  let dateString = null
  if (entry.date !== null) {
    const date = new Date(entry.date * 1000);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + String(date.getMonth() + 1)).slice(-2);
    
    dateString = date.getFullYear() + '-' + month + '-' + day;
    timeString = date.toLocaleTimeString().slice(0, -3);
  }

  const onChange = (_, newEntryType) => {
    if (newEntryType !== null) {
      setEntryType(newEntryType);
    }
  };

  const onAddClick = () => {
    const date = document.getElementById('date').value;
    const time = document.getElementById('time').value;

    if (date === '' || time === '') {
      alert('A date and time is required!');
      return;
    }

    const dateTime = date + 'T' + time;

    const newEntry = {
      type: entryType,
      content: document.getElementById('content').value,
      date: new Date(dateTime).getTime() / 1000
    };

    if (entry.id !== undefined) {
      newEntry.id = entry.id
    }

    onSubmit(newEntry);
  };

  const toggleButtons = Object.keys(timelineDefs).filter((item) => {
    if (!['Registration', 'First Assessment'].includes(item)) {
      return item;
    }
  });

  return (
    <Dialog
      open={isOpen}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
      }}
      scroll="body"
      disableEscapeKeyDown
    >
      <DialogContent>
        <Box
          justifyContent="center"
          sx={{
            display: 'flex',
            marginBottom: 2
          }}
        >
          <ToggleButtonGroup value={entryType} onChange={onChange} exclusive>
            {toggleButtons.map((key) => {
              const Icon = timelineDefs[key].icon;
              return (
                <ToggleButton
                  value={key}
                  key={uuidv4()}
                  sx={{
                    '&.Mui-selected': {
                      color: theme.palette.primary.main
                    }
                  }}
                >
                  <Tooltip title={key}>
                    <Box
                      sx={{
                        position: 'relative',
                        height: 20,
                        width: 20
                      }}
                    >
                      <Icon sx={{ fontSize: 18 }} />
                    </Box>
                  </Tooltip>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Box>
        <TextField
          margin="dense"
          id="date"
          label="Date"
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          defaultValue={dateString}
        />
        <TextField
          margin="dense"
          id="time"
          label="Time"
          type="time"
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          defaultValue={timeString}
        />
        <TextField
          fullWidth
          id="content"
          label="Write your notes here..."
          margin="dense"
          multiline
          rows={6}
          rowsmax={12}
          variant="outlined"
          defaultValue={entry.content}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={onAddClick} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MAddTimelineEntryDialog.propTypes = {
  entry: PropTypes.object,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};
