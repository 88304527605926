import React, { Fragment } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

/**
 * Renders a dropdown button to sort patients.
 * @param {Object} props The component properties.
 * @return {jsx} The sort button component.
 */
export const MSortButton = ({ options, onChange }) => {
  const [isAscending, setAscending] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(2);
  const theme = useTheme();

  const handleMenuItemClick = (index) => {
    setAnchorEl(null);
    if (index !== selectedIndex) {
      onChange(null, index);
      setSelectedIndex(index);
    }
  };

  const handleSelectionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOrderChange = () => {
    onChange(true, null);
    setAscending(!isAscending);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <ButtonGroup
        sx={{ marginLeft: 'auto', [theme.breakpoints.down('md')]: { width: '100%' } }}
        color="primary"
        variant="outlined">
        <Button
          onClick={handleSelectionClick}
          sx={{ [theme.breakpoints.down('md')]: { width: '100%' } }}>
          {options[selectedIndex]}
        </Button>
        <Button color="primary" size="small" onClick={handleOrderChange}>
          {isAscending ? <MdArrowDropUp size={20} /> : <MdArrowDropDown size={20} />}
        </Button>
      </ButtonGroup>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem
            key={uuidv4()}
            selected={index === selectedIndex}
            onClick={() => handleMenuItemClick(index)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

MSortButton.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  sortMode: PropTypes.number
};
