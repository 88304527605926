import React, { Fragment } from 'react';
import {
  FaHourglassHalf,
  FaRoute,
  FaShoePrints,
  FaStopwatch,
  FaTachometerAlt
} from 'react-icons/fa';
import { Box, Stack } from '@mui/material';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { MComparisonStatsCard } from './MComparisonStatsCard.js';

const PARAMETERS_AUX_DEF = {
  steps: {
    title: 'steps',
    icon: FaShoePrints,
    unit: '-',
    decimals: 0
  },
  cadence: {
    title: 'cadence',
    icon: FaStopwatch,
    unit: 'SPM',
    decimals: 0
  },
  distance: {
    title: 'distance',
    icon: FaRoute,
    unit: 'm',
    decimals: 0
  },
  duration: {
    title: 'duration',
    icon: FaHourglassHalf,
    unit: 's',
    decimals: 0
  },
  velocity: {
    title: 'gait speed',
    icon: FaTachometerAlt,
    unit: 'm/s',
    decimals: 2
  }
};

/**
 * Comparison statistics overview
 * @return {jsx} Comparison statistics overview.
 */
export const MComparisonStats = ({ statsA, statsB }) => {
  return (
    <Fragment>
      <Stack
        direction="column"
        justifyContent="space-around"
        spacing={4}
        sx={{ position: 'relative', width: '100%', minHeight: '100%', paddingBottom: 10 }}
      >
        {Object.keys(PARAMETERS_AUX_DEF).map((para) => {
          const title = PARAMETERS_AUX_DEF[para].title;
          const icon = PARAMETERS_AUX_DEF[para].icon;
          const unit = PARAMETERS_AUX_DEF[para].unit;
          const decimals = PARAMETERS_AUX_DEF[para].decimals;
          return (
            <Box
              key={uuidv4()}
              sx={{
                width: '100%',
                height: 150
              }}
            >
              <MComparisonStatsCard
                data={[statsA.totalStats[para], statsB.totalStats[para]]}
                title={title}
                unit={unit}
                icon={icon}
                decimals={decimals}
              />
            </Box>
          );
        })}
      </Stack>
    </Fragment>
  );
};

MComparisonStats.propTypes = {
  statsA: PropTypes.object,
  statsB: PropTypes.object
};
