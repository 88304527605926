import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';

import {
  createEventCall,
  eventsFromStreams,
  pryvApiCall,
  updateEventCall
} from '../../data/apiCalls.js';
import theme from '../../styles/theme.js';

import { MCustomSnackbar } from './MCustomSnackbar.js';
import { MTooltipIconButton } from './MTooltipIconButton';

const NO_COMMENT_TEXT = 'No Comments yet, author your own comments here!';

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.secondary.main
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: `${theme.palette.common.black}99`
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {},
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: theme.spacing(0.3)
      }
    }
  }
})(TextField);

/**
 * Field containing Comment.
 * @param {Object} props The component properties.
 * @return {jsx} The symmetry chart component.
 */
export const MCommentField = ({ title, apiToken }) => {
  const theme = useTheme();
  const [height, setHeight] = useState(0);
  const [editing, setEditing] = useState(false);
  const [commentEvent, setCommentEvent] = useState(null);
  const [callType, setCallType] = useState('');
  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false
  });

  const containerRef = useRef(null);
  useEffect(() => {
    setHeight(containerRef.current.clientHeight);
  });

  const getNoOfRows = () => {
    const rows = Math.floor((height - 50) / 23);
    return rows > 5 ? rows : 5;
  };

  const apiCalls = {};
  if (title.includes('Assessment')) {
    const activityId = window.location.pathname.split('/').reverse()[0];

    apiCalls.get = eventsFromStreams({
      all: ['activity-comments'],
      any: [`activity-${activityId}`]
    });
    apiCalls._update = updateEventCall;
    apiCalls._create = (comment) => {
      const commentTimeStamp = new Date().getTime() / 1000;
      return createEventCall(
        [`activity-${activityId}`, 'activity-comments'],
        'note/txt',
        comment,
        commentTimeStamp
      );
    };
  } else if (title.includes('Patient')) {
    apiCalls.get = eventsFromStreams(['notes']);
    apiCalls._update = updateEventCall;
    apiCalls._create = (comment) => {
      const commentTimeStamp = new Date().getTime() / 1000;
      return createEventCall(['notes'], 'note/txt', comment, commentTimeStamp);
    };
  }

  const getComment = () => {
    pryvApiCall(apiToken, apiCalls.get)
      .then((res) => {
        if (res[0]['events'].length === 0) {
          setCallType('create');
          document.getElementById('TextField').value = NO_COMMENT_TEXT;
          sessionStorage.setItem('assComment', NO_COMMENT_TEXT);
        } else {
          const commentEventRes = res[0]['events'][0];
          setCallType('update');
          setCommentEvent(commentEventRes);
          document.getElementById('TextField').value = commentEventRes['content'];
          sessionStorage.setItem('assComment', commentEventRes['content']);
        }
      })
      .catch((err) => {
        console.error(err);
        setSnackbar({
          message: err,
          severity: 'error',
          show: true
        });
      });
  };

  useEffect(() => {
    getComment();
  }, [window.location.pathname]);

  const startEditing = () => {
    let commentValue = document.getElementById('TextField').value;

    if (commentValue === NO_COMMENT_TEXT) {
      document.getElementById('TextField').value = '';
    }
    setEditing(!editing);

    if (!commentEvent) {
      document.getElementById('TextField').value = '';
    }
  };

  const exitWithoutSaving = () => {
    setEditing(false);
    const textField = document.getElementById('TextField');
    if (commentEvent === null) {
      textField.value = NO_COMMENT_TEXT;
    } else {
      textField.value = commentEvent['content'];
    }
  };

  const exitWithSave = () => {
    setEditing(false);
    const text = document.getElementById('TextField').value;
    let call = null;

    if (text === '') {
      document.getElementById('TextField').value = NO_COMMENT_TEXT;
      return;
    }

    if (callType === 'update') {
      call = apiCalls._update(commentEvent.id, text);
    } else {
      call = apiCalls._create(text);
    }
    pryvApiCall(apiToken, call)
      .then((res) => {
        setCallType('update');
        setCommentEvent(res[0].event);
        try {
          setSnackbar({
            message: 'Successfully Saved',
            severity: 'success',
            show: true
          });
        } catch (e) {
          setSnackbar({
            message: 'Oops, something went wrong, the comment was not saved!',
            severity: 'error',
            show: true
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <Fragment>
      <Box
        ref={containerRef}
        sx={{
          position: 'relative',
          height: '100%'
        }}
      >
        {snackbar.show && (
          <MCustomSnackbar content={snackbar} handleClose={() => setSnackbar({ show: false })} />
        )}
        <CustomTextField
          label={title}
          multiline
          rows={getNoOfRows()}
          id="TextField"
          variant="outlined"
          fullWidth
          disabled={!editing}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            readOnly: !editing,
            sx: { fontSize: 14 }
          }}
          margin="dense"
        />
        <Box
          sx={{
            position: 'absolute',
            right: 3,
            bottom: 23,
            height: 100,
            width: 50
          }}
        >
          {!editing ? (
            <MTooltipIconButton
              buttonValue="addComment"
              title="Edit Comment"
              onClickEvent={startEditing}
              buttonType="absoluteTooltipMIconButton"
              buttonStyle={{
                position: 'relative',
                bottom: 0,
                color: theme.palette.primary.main,
                fontSize: 25
              }}
            />
          ) : (
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%'
              }}
            >
              <MTooltipIconButton
                buttonValue="save"
                title="Save Changes"
                onClickEvent={exitWithSave}
                buttonType="absoluteTooltipMIconButton"
                buttonStyle={{
                  bottom: 70,
                  right: 0,
                  color: theme.palette.primary.main,
                  fontSize: 25
                }}
              />
              <MTooltipIconButton
                buttonValue="trash"
                title="Discard Changes"
                onClickEvent={exitWithoutSaving}
                buttonType="absoluteTooltipMIconButton"
                buttonStyle={{
                  right: 0,
                  bottom: 10,
                  color: theme.palette.primary.main,
                  fontSize: 25
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

MCommentField.propTypes = {
  title: PropTypes.string,
  apiToken: PropTypes.string
};
