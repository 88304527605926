import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';
import { makeDateString } from '../../../utils/auxFunctions.js';

import { MSelectDiagnosis } from './MSelectDiagnosis.js';

/**
 * Shows detailed info about a patient.
 * @param {Object} props containing the patient profile data.
 * @return {jsx} The patient info component.
 */
export const MInfoDialog = ({ patient, open, setOpen }) => {
  const [forceCondition, setForceCondition] = useState(false);
  const [chooseCondition, setChooseCondition] = useState(false);

  const hasAssessments = patient.activities.length > 0;

  const address = [
    patient.address.street,
    `${patient.address.zip} ${patient.address.town}`,
    patient.address.country
  ];

  useEffect(() => {
    try {
      const diagnosisEventContent = patient.diagnosis.content;
      if (diagnosisEventContent.length === 0) {
        setChooseCondition(true);
        setForceCondition(true);
        return;
      }
    } catch (err) {
      console.warn(err);
    }
    try {
      if (patient.diagnosis === 'none') {
        setChooseCondition(true);
        setForceCondition(true);
        return;
      }
    } catch (err) {
      console.warn(err);
    }
  }, []);

  const handleEditClick = () => {
    setChooseCondition(true);
  };

  let diagnosis;
  if (typeof patient.diagnosis === 'object') {
    if (patient.diagnosis.content.length === 0) {
      diagnosis = ['none'];
    } else {
      diagnosis = patient.diagnosis.content.map((condition) => {
        if (condition.includes('Other')) {
          return `${condition.split('-')[0]} (Other)`;
        } else {
          return condition.split('-')[1];
        }
      });
    }
  } else {
    diagnosis = ['none'];
  }

  const leftTableItems = [
    {
      label: 'Username',
      value: patient.username
    },
    {
      label: 'Address',
      value: address
    },
    {
      label: 'Phone No.',
      value: patient.phoneNo
    },
    {
      label: 'Date of Birth',
      value: makeDateString(patient.birthdate)
    },
    {
      label: 'Height',
      value: `${patient.height} cm`
    },
    {
      label: 'Weight',
      value: `${patient.weight} kg`
    }
  ];

  const rightTableItems = [
    {
      label: 'Condition',
      value: diagnosis.join('\n')
    },
    {
      label: 'Shoe Size',
      value: patient.shoeSize === -1 ? `n/a` : `${patient.shoeSize} (EU)`
    },
    {
      label: 'Impaired Side',
      value: patient.impairedSide
    },
    {
      label: 'Signed up',
      value: hasAssessments ? makeDateString(patient.regDate) : 'n/a'
    },
    {
      label: 'First Assessment',
      value: hasAssessments ? makeDateString(patient.firstAssessmentDate) : 'n/a'
    },
    {
      label: 'Latest Assessment',
      value: hasAssessments ? makeDateString(patient.lastAssessmentDate) : 'n/a'
    },
    {
      label: 'Total No. of Assessments',
      value: patient.activities.length
    }
  ];

  const theme = useTheme();

  return (
    <Fragment>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              width: '100%',
              height: '100%'
            },
            width: 630,
            height: 380,
            overflow: 'hidden'
          }}>
          <DialogTitle sx={{ paddingBottom: 2 }}>
            <Typography align="center" sx={{ fontSize: 18 }}>
              {`${patient.firstName} ${patient.lastName}`}
            </Typography>
          </DialogTitle>
          <Divider sx={{ marginBottom: 2 }} />
          <Grid container justifyContent="center" spacing={5}>
            <Grid item xs={12} md={6}>
              <Table size="small">
                <TableBody>
                  {leftTableItems.map((leftItem) => (
                    <TableRow key={uuidv4()} sx={{ position: 'relative', height: 30 }}>
                      <TableCell
                        align="left"
                        sx={{
                          position: 'absolute',
                          borderBottom: 'none'
                        }}>
                        <Typography sx={{ fontSize: 12 }} color="primary">
                          {leftItem.label}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" sx={{ position: 'relative' }}>
                        {leftItem.label === 'Address' ? (
                          <Typography sx={{ fontSize: 12 }}>
                            {leftItem.value[0]}
                            <br /> {leftItem.value[1]}
                            <br /> {leftItem.value[2]}
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: 12 }}>{leftItem.value}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} md={6}>
              <Table size="small">
                <TableBody>
                  {rightTableItems.map((rightItem) => (
                    <TableRow key={uuidv4()}>
                      <TableCell
                        align="left"
                        sx={{
                          position: 'absolute',
                          borderBottom: 'none'
                        }}>
                        <Typography sx={{ fontSize: 12 }} color="primary">
                          {rightItem.label}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          display: 'flex',
                          position: 'relative',
                          maxHeight: 100,
                          overflow: 'scroll'
                        }}>
                        {rightItem.label === 'Condition' && (
                          <MTooltipIconButton
                            buttonValue="fillEdit"
                            title="Edit"
                            onClickEvent={handleEditClick}
                            buttonType="tooltipIconButton"
                            buttonStyle={{ transform: 'translate( 10%, -20%)' }}
                          />
                        )}
                        <Typography sx={{ fontSize: 12, whiteSpace: 'pre-line' }}>
                          {rightItem.value}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Modal
        open={chooseCondition}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') return;
        }}
        sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Fragment>
          <MSelectDiagnosis
            patient={patient}
            onSave={() => setChooseCondition(false)}
            onCancel={() => setChooseCondition(false)}
            force={forceCondition}
          />
        </Fragment>
      </Modal>
    </Fragment>
  );
};

MInfoDialog.propTypes = {
  patient: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};
