import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FaClone } from 'react-icons/fa';
import { MdVibration } from 'react-icons/md';
import { ClickAwayListener, Popper, Tooltip, Typography } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { category2IconMap, gaitActivityTypes } from '../../definitions/activityTypes.js';
import { vibrationTypes } from '../../definitions/vibrationTypes.js';
import { colors } from '../../styles/colors.js';

const extendedCategory2IconMap = { All: FaClone };
Object.assign(extendedCategory2IconMap, category2IconMap);

const extendedActivityTypes = [
  {
    id: 'all',
    shorthand: 'n/a',
    title: 'All',
    category: 'All'
  }
].concat(gaitActivityTypes);

const extendedVibrationTypes = vibrationTypes.concat(
  {
    id: 'any',
    shorthand: 'any',
    title: 'Any'
  },
  {
    id: 'without',
    shorthand: 'w/o',
    title: 'Without'
  }
);

/**
 * Renders the "Activity type" toggle.
 * @param {Object} props of the toggle
 * @return {jsx} The patient page component.
 */
export const MActivityTypeToggle = ({
  replaceOverall,
  activityType,
  vibrationType,
  setActivityType,
  setVibrationType,
  popperPlacement,
  size
}) => {
  const theme = useTheme();
  const [selectedCategory, setCategory] = useState(
    extendedActivityTypes.filter((activity) => activity.id === activityType)[0].category
  );
  const [popperOpen, setPopperOpen] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [popperToggles, setPopperToggles] = useState([]);

  const [popperOpenVT, setPopperOpenVT] = useState(false);
  const [popperAnchorVT, setPopperAnchorVT] = useState(null);

  const propSize = size !== undefined ? size : 30;

  const onButtonClick = (event, value) => {
    if (value === 'All') {
      setActivityType('all');
      setCategory('All');
      return;
    }
    openPopper(event.currentTarget);
    setPopperToggles(extendedActivityTypes.filter((activity) => activity.category === value));
  };

  const onActivityTypeChange = (_, value) => {
    if (value !== null) {
      setActivityType(value);
      setCategory(extendedActivityTypes.filter((activity) => activity.id === value)[0].category);
      setPopperOpen(false);
    }
  };

  const openPopper = (anchor) => {
    setPopperAnchor(anchor);
    setPopperOpen(true);
  };

  const onVibTbClick = (event) => {
    if (vibrationType === 'none') openPopperVT(event.currentTarget);
    else setVibrationType('none');
  };
  const openPopperVT = (anchor) => {
    setPopperAnchorVT(anchor);
    setPopperOpenVT(true);
  };

  const onVibrationTypeChange = (event, value) => {
    if (value !== null) setVibrationType(value);
  };

  const popperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (popperRef.current && !popperRef.current.contains(event.target)) {
      setPopperOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  return (
    <Fragment>
      <ToggleButtonGroup>
        {Object.keys(extendedCategory2IconMap).map((cat) => {
          const Icon = extendedCategory2IconMap[cat];
          let title = cat;
          if (cat === 'All' && replaceOverall !== undefined) {
            title = replaceOverall;
          }
          return (
            <Tooltip title={`${title}`} key={title} placement="top">
              <ToggleButton
                value={cat}
                onClick={onButtonClick}
                sx={{
                  fontSize: propSize,
                  color:
                    selectedCategory === cat
                      ? theme.palette.common.white
                      : theme.palette.primary.main,
                  backgroundColor: selectedCategory === cat ? theme.palette.primary.main : null
                }}>
                <Icon />
              </ToggleButton>
            </Tooltip>
          );
        })}
        <Popper
          open={popperOpen}
          anchorEl={popperAnchor}
          placement={popperPlacement}
          ref={popperRef}
          sx={{
            zIndex: 1500,
            backgroundColor: theme.palette.common.white
          }}>
          <ToggleButtonGroup value={activityType} exclusive onChange={onActivityTypeChange}>
            {popperToggles.map((activity) => {
              const bcColor = activity.id === activityType ? colors['lightGrey2'] : null;
              return (
                <Tooltip title={activity.title} key={activity.title}>
                  <ToggleButton
                    value={activity.id}
                    sx={{
                      backgroundColor: bcColor
                    }}>
                    <Typography sx={{ fontSize: `0.6 * ${propSize}px` }}>
                      <small> {activity.shorthand} </small>
                    </Typography>
                  </ToggleButton>
                </Tooltip>
              );
            })}
          </ToggleButtonGroup>
        </Popper>
      </ToggleButtonGroup>
      {vibrationType && (
        <Fragment>
          <Tooltip title="Vibration">
            <ToggleButton
              value={'vibration'}
              onClick={onVibTbClick}
              sx={{
                marginLeft: 2,
                fontSize: propSize,
                color:
                  vibrationType !== 'none'
                    ? theme.palette.common.white
                    : theme.palette.primary.main,
                backgroundColor:
                  vibrationType !== 'none' ? theme.palette.primary.main : theme.palette.common.white
              }}>
              <MdVibration />
            </ToggleButton>
          </Tooltip>
          <Popper
            open={popperOpenVT}
            anchorEl={popperAnchorVT}
            placement={popperPlacement}
            sx={{
              zIndex: 1500,
              backgroundColor: theme.palette.common.white
            }}>
            <ClickAwayListener onClickAway={() => setPopperOpenVT(false)}>
              <ToggleButtonGroup
                value={vibrationType}
                exclusive
                onChange={onVibrationTypeChange}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap'
                }}>
                {extendedVibrationTypes.map((vt) => {
                  const bcColor = vt.id === vibrationType ? colors['lightGrey2'] : null;
                  let txtColor;
                  if (['without', 'any'].includes(vt.id)) {
                    txtColor = theme.palette.primary.main;
                  }
                  return (
                    <Tooltip title={vt.title} key={vt.title}>
                      <ToggleButton
                        value={vt.id}
                        sx={{
                          backgroundColor: bcColor
                        }}>
                        <Typography
                          variant={'caption'}
                          sx={{
                            fontSize: `0.6 * ${propSize}px`,
                            color: txtColor,
                            textTransform: 'none'
                          }}>
                          <small> {vt.shorthand} </small>
                        </Typography>
                      </ToggleButton>
                    </Tooltip>
                  );
                })}
              </ToggleButtonGroup>
            </ClickAwayListener>
          </Popper>
        </Fragment>
      )}
    </Fragment>
  );
};

MActivityTypeToggle.propTypes = {
  replaceOverall: PropTypes.string,
  activityType: PropTypes.string,
  vibrationType: PropTypes.string,
  setActivityType: PropTypes.func,
  setVibrationType: PropTypes.func,
  size: PropTypes.number,
  popperPlacement: PropTypes.string
};
