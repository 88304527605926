import React, { Fragment } from 'react';
import { Box, Button, Dialog, DialogContent, DialogContentText,DialogTitle, Divider  } from '@mui/material';
import { PropTypes } from 'prop-types';

const DEFAULT_TEXT = "Are you sure you want to proceed?"

/**
 * Renders a confirmation dialog.
 * @param {Object} props The component properties.
 * @return {jsx} The dialoge.
 */
export const MConfirmationBox = ({ title, text, isOpen, onConfirmation, onCancel }) => {
  return (
    <Dialog maxWidth="xs" open={isOpen} sx={{ zIndex: 1500 }}>
      {title && (
        <Fragment>
          <DialogTitle align="center">Warning</DialogTitle>
          <Divider />
        </Fragment>
      )}
      <DialogContent>
        <DialogContentText>
          {text ? text : DEFAULT_TEXT}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onConfirmation()}
          sx={{ margin: 2 }}
          id="confirm-button"
        >
          Yes
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onCancel()}
          sx={{ margin: 2 }}
        >
          No
        </Button>
      </Box>
    </Dialog>
  );
};

MConfirmationBox.propTypes = {
  title: PropTypes.string, 
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  isOpen: PropTypes.bool,
  onConfirmation: PropTypes.func,
  onCancel: PropTypes.func,
};
