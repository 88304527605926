import React, { createContext, useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
const ComparisonContext = createContext();

const ComparisonProvider = ({ children }) => {
  const [selectionA, setSelectionA] = useState([]);
  const [selectionB, setSelectionB] = useState([]);

  return (
    <ComparisonContext.Provider
      value={{
        selectionA,
        setSelectionA,
        selectionB,
        setSelectionB
      }}
    >
      {children}
    </ComparisonContext.Provider>
  );
};

export const useComparisonContext = () => {
  return useContext(ComparisonContext);
};

export { ComparisonContext, ComparisonProvider };

ComparisonProvider.propTypes = {
  children: PropTypes.node
};
