export const colors = {
  backgroundLightGrey: '#e3e4e6',
  green: '#499F69',
  lightGreen: '#54DEA9',
  lightGreen2: '#C0DECD',
  lightGreen3: '#00B359',
  lightGreen4: '#17FFA5',
  brown: '#6D5E52',
  lightBrown: '#9F7559',
  darkGrey: '#a9a9a9',
  lightGrey: '#D3D3D3',
  lightGrey2: '#E6E6E6',
  lightSlateGrey: '#778799',
  lightBlue: '#028FCC',
  lightBlue2: '#2EB2FF',
  transparent: '#FFFFFF00',
  black: '#000000',
  darkBlue: '#3a464c',
  nushuBlue: '#4ebfff',
};
