import React, { Fragment, useEffect, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { Portal } from '@mui/base';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField
} from '@mui/material';
import { PropTypes } from 'prop-types';

import { MConfirmationBox } from '../../components/ui/MConfirmationBox.js';
import { MCustomSnackbar } from '../../components/ui/MCustomSnackbar.js';
import { MTooltipIconButton } from '../../components/ui/MTooltipIconButton';
import { useGlobalContext } from '../../context/AppContext.js';
import {
  createEventCall,
  getRegisteredAffiliations,
  pryvApiCall,
  updateEventCall
} from '../../data/apiCalls.js';
import theme from '../../styles/theme.js';
import { getContent } from '../../utils/dataFetchFunctions.js';

const EDITABLE_PARAMS = ['name', 'address', 'phone'];

const defaultProfileInfo = {
  name: {
    'first-name': '',
    'last-name': ''
  },
  address: {
    street: '',
    zip: -1,
    town: '',
    country: ''
  },
  phone: ''
};

const eventTypes = {
  name: 'contact/magnes-v1',
  address: 'address/magnes-v1',
  phone: 'call/telephone'
};

/**
 * Renders the UserInfoDialog element, which displays the docs
 user info, such as the QR code, TODO: add editable vcard info.
 * @param {Object} props The component properties.
 * @return {jsx} The user dialog component.
 */
export const MAccountInfoDialog = ({ isOpen, onClose }) => {
  const instance = window.sessionStorage.getItem('dbInstance');

  const { doctorInfoEvents, setDoctorInfoEvents } = useGlobalContext();
  const affiliationShorthand = getContent(doctorInfoEvents, '.affiliation');

  const [affiliation, setAffiliation] = useState(affiliationShorthand);
  const [isEditing, setIsEditing] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false
  });

  useEffect(() => {
    getRegisteredAffiliations(instance).then((registry) => {
      if (registry !== undefined) {
        setAffiliation(registry[affiliationShorthand].name);
      }
    });
  }, []);

  const apiEndpoint = sessionStorage.getItem('docEP');

  const profileInfo = {
    ...defaultProfileInfo
  };
  const editInfoCalls = {};
  for (const parameter of EDITABLE_PARAMS) {
    try {
      const event = doctorInfoEvents.filter((item) => item.streamIds.includes(parameter))[0];
      const eventId = event.id;
      profileInfo[parameter] = event.content;

      editInfoCalls[parameter] = async (content) => {
        const res = await pryvApiCall(apiEndpoint, updateEventCall(eventId, content));
        return res;
      };
    } catch {
      editInfoCalls[parameter] = async (content) => {
        const timeStamp = Math.round(new Date().getTime() / 1000);
        return await pryvApiCall(
          apiEndpoint,
          createEventCall([parameter], eventTypes[parameter], content, timeStamp)
        );
      };
    }
  }

  for (const parameter of ['.email', '.username']) {
    profileInfo[parameter] = getContent(doctorInfoEvents, parameter);
  }

  const exitWithSave = () => {
    const updatedInfoEvents = [...doctorInfoEvents];

    for (const parameter of EDITABLE_PARAMS) {
      let txtFieldValue;
      if (parameter === 'phone') {
        txtFieldValue = document.getElementById(parameter).value;
      } else {
        txtFieldValue = {};
        for (const subParameter of Object.keys(profileInfo[parameter])) {
          if (subParameter !== 'middle-name') {
            txtFieldValue[subParameter] = document.getElementById(
              `${parameter}_${subParameter}`
            ).value;
            if (subParameter === 'zip') {
              txtFieldValue[subParameter] =
                txtFieldValue[subParameter] === '' ? -1 : Number(txtFieldValue[subParameter]);
            }
          }
        }
      }
      if (JSON.stringify(profileInfo[parameter]) !== JSON.stringify(txtFieldValue)) {
        editInfoCalls[parameter](txtFieldValue).then((res) => {
          const index = updatedInfoEvents.findIndex((x) => x.streamId === parameter);
          const event = res[0].event;

          if (index !== -1) {
            updatedInfoEvents[index] = event;
          } else {
            updatedInfoEvents.push(event);
          }
        });
      }
    }
    setSnackbar({
      message: 'Successfully updated Account Info',
      severity: 'info',
      show: true
    });
    setDoctorInfoEvents(updatedInfoEvents);
    setIsEditing(false);
  };

  const exitWithoutSaving = () => {
    setIsEditing(false);
    setWarningOpen(false);
    onClose();
  };

  const onDialogClose = () => {
    if (isEditing) {
      setWarningOpen(true);
      return;
    }
    onClose();
  };

  const accountItemsRow1 = [
    {
      size: 4,
      label: 'First Name',
      defaultValue: profileInfo.name['first-name'],
      id: 'name_first-name',
      isEditable: true
    },
    {
      size: 8,
      label: 'Last Name',
      defaultValue: profileInfo.name['last-name'],
      id: 'name_last-name',
      isEditable: true
    },
    {
      size: 7,
      label: 'E-Mail',
      defaultValue: profileInfo['.email'],
      id: '',
      isEditable: false
    },
    {
      size: 5,
      label: 'Phone No',
      defaultValue: profileInfo.phone,
      id: 'phone',
      isEditable: true
    }
  ];

  const accountItemsRow2 = [
    {
      size: 5,
      label: 'Affiliation',
      defaultValue: affiliation,
      id: '',
      isEditable: false
    },
    {
      size: 7,
      label: 'Street & No.',
      defaultValue: profileInfo.address.street,
      id: 'address_street',
      isEditable: true
    },
    {
      size: 2,
      label: 'ZIP',
      defaultValue: profileInfo.address.zip === -1 ? '' : profileInfo.address.zip,
      id: 'address_zip',
      isEditable: true
    },
    {
      size: 5,
      label: 'City',
      defaultValue: profileInfo.address.town,
      id: 'address_town',
      isEditable: true
    },
    {
      size: 5,
      label: 'Country',
      defaultValue: profileInfo.address.country,
      id: 'address_country',
      isEditable: true
    }
  ];

  return (
    <Fragment>
      <Portal>
        {snackbar.show && (
          <MCustomSnackbar content={snackbar} handleClose={() => setSnackbar({ show: false })} />
        )}
      </Portal>
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <IconButton
          sx={{
            position: 'absolute',
            right: 5,
            top: 5
          }}
          onClick={onDialogClose}>
          <MdOutlineClose />
        </IconButton>
        <DialogTitle>
          Account Information for
          <strong> {profileInfo['.username']}</strong>
        </DialogTitle>
        <Divider sx={{ marginBottom: 1 }} />
        <DialogContent>
          <Grid container>
            <Grid container spacing={2}>
              {accountItemsRow1.map((el) => (
                <Grid item xs={12} md={el.size} key={el.id}>
                  <TextField
                    variant="outlined"
                    label={el.label}
                    defaultValue={el.defaultValue}
                    disabled={!isEditing || !el.isEditable}
                    fullWidth
                    id={el.id}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              {accountItemsRow2.map((el) => (
                <Grid item xs={12} md={el.size} key={el.id}>
                  <TextField
                    variant="outlined"
                    label={el.label}
                    defaultValue={el.defaultValue}
                    disabled={!isEditing || !el.isEditable}
                    fullWidth
                    id={el.id}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} align="right" justify="center">
              <Divider sx={{ my: 4 }} />
              {!isEditing ? (
                <MTooltipIconButton
                  buttonValue="modeEdit"
                  title="Edit Information"
                  onClickEvent={() => setIsEditing(true)}
                  buttonType="absoluteTooltipMIconButton"
                  buttonStyle={{
                    right: 5,
                    bottom: 5,
                    color: theme.palette.primary.main
                  }}
                />
              ) : (
                <Box>
                  <MTooltipIconButton
                    buttonValue="save"
                    title="Save Changes"
                    onClickEvent={exitWithSave}
                    buttonType="absoluteTooltipMIconButton"
                    buttonStyle={{
                      bottom: 5,
                      color: theme.palette.primary.main
                    }}
                    tooltipPosition={55}
                  />

                  <MTooltipIconButton
                    buttonValue="trash"
                    title="Discard Changes"
                    onClickEvent={() => setIsEditing(false)}
                    buttonType="absoluteTooltipMIconButton"
                    buttonStyle={{
                      right: 5,
                      bottom: 5,
                      color: theme.palette.primary.main
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <MConfirmationBox
        text="There may be unsaved changes, do you want to proceed?"
        isOpen={warningOpen}
        onConfirmation={exitWithoutSaving}
        onCancel={() => setWarningOpen(false)}
      />
    </Fragment>
  );
};

MAccountInfoDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};
