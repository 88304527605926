import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, Grid, Typography } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { PropTypes } from 'prop-types';

/**
 * Select DB dialog component
 * @param {Oject} props returned from an api call
 * @return {jsx} select db instance component
 */
export const MSelectDBInstance = ({ onConfirm, isOpen }) => {
  const [selection, setSelection] = useState('production');

  const handleChange = (event, value) => {
    if (value === null) return;
    setSelection(value);
  };

  return (
    <Dialog open={isOpen} maxWidth="xs">
      <DialogTitle align="center">
        <strong> Select DB-Instance </strong>
      </DialogTitle>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <Typography variant="subtitle1">
            You must select a DB instance to connect to. Once selected you cannot switch instances
            during a session. To start a new session, close your tab and open the website in a new
            one. This will prompt this dialog to be shown again.
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" sx={{ my: 2 }}>
          <ToggleButtonGroup
            align="center"
            size="large"
            value={selection}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value="development">Development</ToggleButton>
            <ToggleButton value="staging">Staging</ToggleButton>
            <ToggleButton value="production">Production</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid container justifyContent="center" sx={{ mb: 3 }}>
          <Button
            align="center"
            variant="contained"
            color="primary"
            onClick={() => onConfirm(selection)}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

MSelectDBInstance.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};
