import React, { Fragment, useState } from 'react';
import { Box, Dialog, DialogTitle } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { PropTypes } from 'prop-types';

import { MCustomSnackbar } from '../../../components/ui/MCustomSnackbar.js';
import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';
import { deleteEventCall, pryvApiCall } from '../../../data/apiCalls.js';
import { loadPatientData } from '../../../utils/auxFunctions.js';
const makeRowData = (id, name, created, modified, noAssessments) => {
  return { id, name, created, modified, noAssessments };
};

/**
 * Renders the MLoadComparisonDialog element
 * @param {Object} props The component properties.
 * @return {jsx} The component.
 */
export const MLoadComparisonDialog = ({
  isOpen,
  setOpen,
  savedComparisons,
  setSavedComparisons,
  onLoad
}) => {
  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false
  });

  const patientData = loadPatientData();
  const analyzedActs = patientData.activities.filter((item) => item.analysisStatus === 'analyzed');

  const onLoadClick = (comparisonName) => {
    const comparison = savedComparisons.find((item) => item.content.name === comparisonName);
    const groupA = analyzedActs.filter((item) => {
      return comparison.content.activities['Group A'].includes(item.id);
    });
    const groupB = analyzedActs.filter((item) => {
      return comparison.content.activities['Group B'].includes(item.id);
    });

    if (
      groupA.length !== comparison.content.activities['Group A'].length ||
      groupB.length !== comparison.content.activities['Group B'].length
    ) {
      setSnackbar({
        message: `Seems like some of the comparison's activities are gone.
              They may have been deleted since saving this comparison.`,
        severity: 'warning',
        show: true
      });
    }
    onLoad(groupA, groupB);
    setOpen(false);

    setSnackbar({
      show: true,
      message: 'Loaded Comparison',
      severity: 'success'
    });
  };

  const onDeleteClick = (name) => {
    const itemToDelete = savedComparisons.find((item) => item.content.name === name);
    const docToken = sessionStorage.getItem('docEP');
    pryvApiCall(docToken, deleteEventCall(itemToDelete.id)).then(() => {
      setSnackbar({
        show: true,
        message: 'Comparison Deleted',
        severity: 'success'
      });
      setSavedComparisons(savedComparisons.filter((item) => item !== itemToDelete));
    });
  };

  const rows = savedComparisons.map((item, idx) => {
    const assessmentCount =
      item.content.activities['Group A'].length + item.content.activities['Group B'].length;

    const lastModified =
      item.modified - item.created > 10
        ? new Date(item.modified * 1000).toISOString().slice(0, -5).replace('T', ' ')
        : '';

    return makeRowData(
      idx,
      item.content.name,
      new Date(item.created * 1000).toISOString().slice(0, -5).replace('T', ' '),
      lastModified,
      assessmentCount
    );
  });
  rows.sort((a, b) => a.created - b.created);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      disableClickEventBubbling: true
    },
    {
      field: 'created',
      headerName: 'Created',
      width: 180
    },
    {
      field: 'modified',
      headerName: 'Modified',
      width: 180
    },
    {
      field: 'noAssessments',
      headerName: '# of Assessments',
      align: 'center',
      width: 90
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      filterable: false,
      width: 80,
      align: 'center',
      renderCell: (params) => {
        return (
          <MTooltipIconButton
            buttonValue="trash"
            title="Delete"
            onClickEvent={() => onDeleteClick(params.row.name)}
            buttonType="tooltipIconButton"
            iconStyle={{ fontSize: 15 }}
          />
        );
      }
    },
    {
      field: 'goto',
      headerName: 'Load',
      sortable: false,
      filterable: false,
      width: 80,
      align: 'center',
      renderCell: (params) => {
        return (
          <MTooltipIconButton
            buttonValue="chevronRight"
            title="Load"
            onClickEvent={() => onLoadClick(params.row.name)}
            buttonType="tooltipIconButton"
            iconStyle={{ fontSize: 25 }}
          />
        );
      }
    }
  ];

  return (
    <Fragment>
      {snackbar.show && (
        <MCustomSnackbar
          content={snackbar}
          handleClose={() =>
            setSnackbar({
              message: '',
              severity: '',
              show: false
            })
          }
        />
      )}
      <Dialog maxWidth="xl" open={isOpen} onClose={() => setOpen(false)}>
        <Box sx={{ width: 800, padding: 1.5 }}>
          <DialogTitle>Saved Comparisons</DialogTitle>
          <Box style={{ height: 400 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableColumnSelector
              autoPageSize
              disableSelectionOnClick
              GridLines="None"
            />
          </Box>
        </Box>
      </Dialog>
    </Fragment>
  );
};

MLoadComparisonDialog.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  onLoad: PropTypes.func,
  savedComparisons: PropTypes.array,
  setSavedComparisons: PropTypes.func
};
