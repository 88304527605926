import React, { Fragment, useState } from 'react';
import { FaShoePrints, FaTags } from 'react-icons/fa';
import { Box, ButtonGroup, Divider, Grid, Paper } from '@mui/material';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { MActivityTypeToggle } from '../../../components/ui/MActivityTypeToggle.js';
import { MStatisticsCard } from '../../../components/ui/MStatisticsCard.js';
import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';
import { gaitParameters } from '../../../definitions/gaitParameters.js';

import { MTrendChart } from './MTrendChart.js';
import { MTrendReportGenerator } from './MTrendReportGenerator.js';

const getIntialTimeframe = (daysSinceLast) => {
  if (daysSinceLast < 7) {
    return 'weekly';
  }
  if (daysSinceLast < 14) {
    return 'monthly';
  }

  return 'yearly';
};

const AUX_PLOT_OPTIONS = {
  weekly: {
    tm: -7,
    lb: Array.from({ length: 7 }, (_, i) => {
      const day = new Date();
      const weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
      day.setDate(day.getDate() - i);
      return weekDays[day.getDay()];
    }).reverse()
  },
  monthly: {
    tm: -28,
    lb: Array.from({ length: 28 }, (v, i) => {
      const dayInMs = 86400000;
      const date = new Date(new Date().getTime() - i * dayInMs);
      const dayMonth = date.toISOString().slice(5, 10).split('-').reverse().join('.');

      return dayMonth;
    }).reverse()
  },
  yearly: {
    tm: -365,
    lb: Array.from({ length: 365 }, (v, i) => {
      const dayInMs = 86400000;
      const date = new Date(new Date().getTime() - i * dayInMs);
      const dayMonth = date.toISOString().slice(5, 10).split('-').reverse().join('.');

      return dayMonth;
    }).reverse()
  }
};

const FILTER_LIST = [{ title: 'weekly' }, { title: 'monthly' }, { title: 'yearly' }];

const TREND_TYPES = [
  {
    'stat-id': 'steps',
    title: 'Steps',
    icon: FaShoePrints,
    unit: ''
  },
  {
    'stat-id': 'assessments',
    title: 'Assessments',
    icon: FaTags,
    unit: ''
  }
].concat(gaitParameters);

/**
 * Card with Gait progression statistics
 * @return {jsx} Card with Gait progression statistics.
 */
export const MGaitProgression = ({ trend }) => {
  const daysSinceLast = [...trend.all.steps.values].reverse().findIndex((val) => val > 0);

  const [activityType, setActivityType] = useState('all');
  const [trendView, setTrendView] = useState(getIntialTimeframe(daysSinceLast));

  const [isReportGeneratorOpen, setReportGenerator] = useState(false);

  const handleTrendViewChange = (event) => {
    setTrendView(event.currentTarget.value);
  };

  return (
    <Fragment>
      <Box sx={{ display: 'flex', width: '100%', position: 'absolute', top: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          <MTooltipIconButton
            id="download-report-button"
            buttonValue="filledDownload"
            title="Download Report"
            buttonType="tooltipIconButton"
            onClickEvent={() => setReportGenerator(true)}
          />
        </Box>
      </Box>
      <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{ padding: 1 }}>
        {TREND_TYPES.map((trendType) => {
          const data = {};
          Object.entries(trend[activityType][trendType['stat-id']]).forEach(([k, v]) => {
            data[k] = v.slice(AUX_PLOT_OPTIONS[trendView].tm);
          });
          return (
            <Grid item xs={12} md={6} key={uuidv4()}>
              <MStatisticsCard title={trendType.title} icon={trendType.icon}>
                <Box sx={{ minHeight: 200, height: '20vh' }}>
                  <MTrendChart
                    title={trendType.title}
                    data={data}
                    xAxisLabels={AUX_PLOT_OPTIONS[trendView].lb}
                    yAxisLabel={`${trendType.unit}`}
                  />
                </Box>
              </MStatisticsCard>
            </Grid>
          );
        })}
        <Grid item xs={12} sx={{ height: 75 }} />
      </Grid>
      <Box sx={{ pb: 1, height: 75, bottom: -10, width: '100%', position: 'absolute', zIndex: 15 }}>
        <Paper sx={{ margin: 0 }}>
          <Divider />
          <Box sx={{ marginTop: 2, paddingBottom: 2 }}>
            <Grid container alignItems="center" justifyContent="center" spacing={{ xs: 2, md: 0 }}>
              <Grid item xs={12} md={6} align="center">
                <MActivityTypeToggle
                  activityType={activityType}
                  setActivityType={setActivityType}
                  size={15}
                  popperPlacement="top"
                />
              </Grid>
              <Grid item xs={12} md={6} align="center">
                <ButtonGroup color="primary">
                  {FILTER_LIST.map((el) => (
                    <Fragment key={uuidv4()}>
                      {
                        <MTooltipIconButton
                          buttonValue={el.title}
                          title={`Show ${el.title} progress`}
                          value={el.title}
                          onClickEvent={handleTrendViewChange}
                          variant={trendView === el.title}
                          buttonStyle={{ fontSize: 10 }}
                        />
                      }
                    </Fragment>
                  ))}
                </ButtonGroup>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <MTrendReportGenerator isOpen={isReportGeneratorOpen} setOpen={setReportGenerator} />
    </Fragment>
  );
};

MGaitProgression.propTypes = {
  trend: PropTypes.object
};
