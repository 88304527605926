/*eslint-disable*/

import React, { Fragment } from 'react';
import {
  CgArrowAlignH,
  CgArrowAlignV,
} from 'react-icons/cg';
import {
  FaPercentage,
} from "react-icons/fa";
import {
  FcRadarPlot,
} from 'react-icons/fc';
import {
  GiUnbalanced,
} from 'react-icons/gi';
import {
  MdOutlineInfo,
} from 'react-icons/md';
import { Box, Divider, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import { PropTypes } from 'prop-types';

import { MStatisticsCard } from '../../../components/ui/MStatisticsCard';
import { extractMeanRombergValues } from '../../../utils/dataFetchFunctions';

import { MEnvelopeAreaPlot } from './MEnvelopeAreaPlot';
import { MPathEnvelope } from './MPathEnvelope';
import { MRombergEllipse } from './MRombergEllipse';
import { MRombergPlot } from './MRombergPlot';

const RATIOS = {
  'Romberg Ratio': { id: 'romberg-ratio', icon: FaPercentage },
  'Alternative Romberg Ratio': { id: 'alternative-romberg-ratio', icon: GiUnbalanced },
}

const ViewInfo = ({ type }) => {
  let tooltipContent;
  if (type === 'Romberg Ratio') {
    tooltipContent = (
      <Fragment>
        The <b> Romberg Ratio </b>, M<sub>c</sub> / M<sub>o</sub>, quantifies the amount
        of movement when standing still with closed (M<sub>c</sub>) vs. open eyes (M<sub>o</sub>).
        A value of 1 means there&apos;s no change, &lt; 1 means less and &gt; 1 means
        there&apos;s more movement when standing still with closed than open eyes.
      </Fragment>
    )
  } else if (type === 'Alternative Romberg Ratio') {
    tooltipContent = (
      <Fragment>
        The <b> Alternative Romberg Ratio </b>,
        (M<sub>c</sub> - M<sub>o</sub>) / (M<sub>c</sub> + M<sub>o</sub>), quantifies the amount
        of movement when standing still with closed (M<sub>c</sub>) vs. open eyes (M<sub>o</sub>).
        A value of &lt; 0, 0 and &gt;0 signifies there&apos;s less, equal and more movement with
        closed than open eyes when standing still.
      </Fragment>
    )
  } else if (type === 'Bounding Envelopes') {
    tooltipContent = (
      <Fragment>
        <b> Bounding Envelopes </b> are taken from the raw measured acceleration and gyration.
        The more movement there was, the bigger the area of the envelope will be. Also the
        orientation of the shape shows if lateral or anteposterior movement was more present.
        The given &Delta; gives the relative difference between the closed and open areas.
        <i>Note</i>, this is an experimental quantification of balance.
      </Fragment>
    )
  }

  return (
    <Tooltip
      title={tooltipContent}
      placement={'top'}
    >
      <IconButton color="primary" sx={{ fontSize: 25 }}>
        <MdOutlineInfo />
      </IconButton>
    </Tooltip>
  )
}
ViewInfo.propTypes = {
  type: PropTypes.string,
};



export const MPostResults = ({ results, pastResults }) => {
  const rombergValues = []
  for (const result of [results, ...pastResults]) {
    
    if (result.variant === 'half') {
      continue
    }

    rombergValues.push(extractMeanRombergValues(result));
  }
  
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ height: '100%', p: 1, justifyContent: 'center' }}>
            {results.variant === 'full' && (
              <Fragment>
                {Object.keys(RATIOS).map((rr, idx) =>
                  <Grid
                    item
                    align="center"
                    xl={6}
                    key={idx}
                    sx={{ minHeight: 400, height: 'calc((100vh - 160px) / 2)', p: 0 }}
                  >
                    <MStatisticsCard title={rr} icon={RATIOS[rr].icon}>
                      <Box sx={{ position: 'absolute', right: 10, top: 40 }}>
                        <ViewInfo type={rr} />
                      </Box>              
                        <Stack
                          direction="row"
                          divider={<Divider flexItem orientation="vertical" />}
                          spacing={1}
                          sx={{ height: '100%', width: '100%', p: 1 }}
                        >
                          <Stack
                            direction="column"
                            divider={<Divider flexItem orientation="horizontal" />}
                            spacing={1}
                            sx={{
                              alignItems: 'center',
                              width: 220,
                              p: 1,
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: -5 }}>
                              <Box sx={{ width: 110 }}>
                                <MStatisticsCard title="Lateral" icon={CgArrowAlignH}>
                                  {rombergValues[0][RATIOS[rr].id].lateral.toFixed(2)}
                                </MStatisticsCard>
                              </Box>
                              <Box sx={{ width: 110 }}>
                                <MStatisticsCard title="Anteposterior" icon={CgArrowAlignV}>
                                  {rombergValues[0][RATIOS[rr].id].anteposterior.toFixed(2)}
                                </MStatisticsCard>
                              </Box>
                            </Box>
                            <Box sx={{ display: 'flex', height: 1, width: 1, justifyContent: 'center', alignItems: 'center' }}>
                              <div style={{ height: 160, width: 160 }}>
                                <MRombergEllipse title={rr} ratios={rombergValues[0][RATIOS[rr].id]} />
                              </div>
                            </Box>
                          </Stack>
                          <Box sx={{ height: 1, width: 1, justifyContent: 'center' }}>
                            <div style={{ height: '90%', width: 'calc((100vh - 160px) / 2 - 105px)' }}>
                              <MRombergPlot title={rr} data={rombergValues.map((x) => x[RATIOS[rr].id])} />
                            </div>
                          </Box>
                        </Stack>
                    </MStatisticsCard>

                  </Grid>
                )}
              </Fragment>
            )}
        <Grid item xs={12} sx={{ minHeight: 400, height: 'calc((100vh - 160px) / 2)' }}>
          <MStatisticsCard title="Bounding Envelopes" icon={FcRadarPlot}>
            <Box sx={{ position: 'absolute', right: 10, top: 40 }}>
              <ViewInfo type={'Bounding Envelopes'} />
            </Box>
            <Stack
              direction="row"
              divider={<Divider flexItem orientation="vertical" />}
              spacing={2}
              sx={{ height: 1, width: 1, p: 2 }}>
              <Box sx={{ display: 'flex', height: 1, width: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Box
                  sx={{
                    minHeight: 280,
                    height: 'calc(50vh - 200px)',
                    minWidth: 280,
                    width: 'calc(50vh - 200px)'
                  }}>
                  <MPathEnvelope variant={results.variant} type="omega" data={results['bounding-envelope'].omega} />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', height: 1, width: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Box
                  sx={{
                    minHeight: 280,
                    height: 'calc(50vh - 200px)',
                    minWidth: 280,
                    width: 'calc(50vh - 200px)'
                  }}>
                  <MPathEnvelope variant={results.variant} type="xl" data={results['bounding-envelope'].xl} />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', height: 1, width: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Box
                  sx={{
                    minHeight: 280,
                    height: 'calc(50vh - 200px)',
                    minWidth: 280,
                    width: 'calc(50vh - 200px)'
                  }}>
                  < MEnvelopeAreaPlot datasets={[results, ...pastResults]} />
                </Box>
              </Box>
            </Stack>
          </MStatisticsCard>
        </Grid>
      </Grid>
    </Fragment>
  )
}


MPostResults.propTypes = {
  results: PropTypes.object,
  pastResults: PropTypes.array,
};