import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

/**
 * Renders a span element with units.
 * @param {Object} props The component properties.
 * @return {jsx} The span element with units.
 */
export const MUnit = ({ unit }) => {
  const theme = useTheme();
  return (
    <Box component="span" sx={{ color: theme.palette.text.secondary, fontSize: '0.5em' }}>
      {unit}
    </Box>
  );
};

MUnit.propTypes = {
  unit: PropTypes.string
};
