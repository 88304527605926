import React, { Fragment, useState } from 'react';
import { Bar, Radar } from 'react-chartjs-2';
import { Box, ButtonGroup, Dialog, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';
/**
 * Renders the radar and bar chart for the variability indices.
 * @param {Object} props The component properties.
 * @return {jsx} The variabilty bar chart component.
 */
export const MVariabilityDialog = ({ variabilityCoefficients }) => {
  const [isSpiderPlotOpen, setSpiderPlotOpen] = useState(false);
  const [chartView, setChartView] = useState('radar');

  const theme = useTheme();

  const handleViewChange = (event) => {
    setChartView(event.currentTarget.value);
  };

  const labels = variabilityCoefficients.map((vc) => vc.title);
  const dataLeft = variabilityCoefficients.map((vc) => Number(vc['L'].toFixed(3)));
  const dataRight = variabilityCoefficients.map((vc) => Number(vc['R'].toFixed(3)));

  const radarData = {
    labels: labels,
    datasets: [
      {
        label: 'Left',
        lineTension: 0.0,
        backgroundColor: `${theme.palette.info.main}33`,
        borderDashOffset: 0.5,
        borderColor: theme.palette.info.main,
        pointBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: dataLeft
      },
      {
        label: 'Right',
        lineTension: 0.0,
        backgroundColor: `${theme.palette.secondary.main}33`,
        borderColor: theme.palette.secondary.main,
        borderDashOffset: 0.5,
        pointBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: dataRight
      }
    ]
  };

  const barData = {
    labels: labels,
    datasets: [
      {
        backgroundColor: theme.palette.info.main,
        data: dataLeft,
        label: 'Left'
      },
      {
        backgroundColor: theme.palette.secondary.main,
        data: dataRight,
        label: 'Right'
      }
    ]
  };
  const barOptions = {
    scales: {
      yAxes: [
        {
          categoryPercentage: 1.0,
          ticks: {
            min: 0,
            max: 1,
            stepSize: 0.2
          }
        }
      ]
    }
  };

  const radarOptions = {
    responsive: true,
    scale: {
      angleLines: {
        display: true
      },
      beginAtZero: true,
      min: 0,
      max: 100,
      pointLabels: {
        fontSize: 14
      },
      ticks: {
        display: false,
        maxTicksLimit: 5
      }
    }
  };

  return (
    <Fragment>
      <MTooltipIconButton
        buttonValue="faDice20"
        title="Show CoV chart"
        buttonType="tooltipIconButton"
        onClickEvent={() => {
          setSpiderPlotOpen(true);
        }}
        buttonStyle={{
          fontSize: 18
        }}
      />
      <Dialog
        open={isSpiderPlotOpen}
        onClose={() => {
          setSpiderPlotOpen(false);
        }}
      >
        <DialogTitle>Coefficients of Variability</DialogTitle>
        <Box
          sx={{
            padding: 1.2,
            width: 500,
            height: 300
          }}
        >
          {chartView === 'radar' && <Radar data={radarData} options={radarOptions} />}
          {chartView === 'bar' && <Bar data={barData} options={barOptions} />}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: 1.2
            }}
          >
            <ButtonGroup color="primary">
              <MTooltipIconButton
                buttonValue="faDice20"
                title="Show radar chart"
                value={'radar'}
                onClickEvent={handleViewChange}
                variant={chartView === 'radar'}
              />
              <MTooltipIconButton
                buttonValue="chartBar"
                title="Show bar chart"
                value={'bar'}
                onClickEvent={handleViewChange}
                variant={chartView === 'bar'}
              />
            </ButtonGroup>
          </Box>
        </Box>
      </Dialog>
    </Fragment>
  );
};

MVariabilityDialog.propTypes = {
  variabilityCoefficients: PropTypes.array
};
