import React from 'react';
import { Box, Dialog, DialogTitle } from '@mui/material';
import { PropTypes } from 'prop-types';

// eslint-disable-next-line no-undef
Chart.defaults.global.legend.display = false;

/**
 * Shows detailed charts zoomed in.
 * @param {Object} props The component properties.
 * @return {jsx} The detailed chart component.
 */
export const MOverlay = ({ open, onClose, children, title }) => {
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>{title}</DialogTitle>
      <Box
        sx={{
          height: '40vh',
          paddingLeft: 2,
          paddingTop: 1
        }}
      >
        {children}
      </Box>
    </Dialog>
  );
};

MOverlay.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string
};
