import React from 'react';
import QRCode from 'react-qr-code';
import { Dialog, DialogContent, Divider, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { PropTypes } from 'prop-types';

import { useGlobalContext } from '../../context/AppContext.js';
import { getContent } from '../../utils/dataFetchFunctions.js';

/**
 * Renders the UserInfoDialog element, which displays the docs
 user info, such as the QR code, TODO: add editable vcard info.
 * @param {Object} props The component properties.
 * @return {jsx} The user dialog component.
 */
export const MQrCodeDialog = ({ isOpen, onClose }) => {
  const { doctorInfoEvents } = useGlobalContext();

  const docUserName = doctorInfoEvents ? getContent(doctorInfoEvents, '.username') : 'N/A';
  const docAffiliation = doctorInfoEvents ? getContent(doctorInfoEvents, '.affiliation') : 'N/A';

  const qrCodeString = docUserName + '@' + docAffiliation;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <MuiDialogTitle color="primary" align="center">
          Request Access
        </MuiDialogTitle>
        <Divider sx={{ marginBottom: 2 }} />
        <Typography variant="subtitle1" color="primary">
          Show this QR code to your patient,
          <br /> to request access to his data.
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <div>
          <QRCode level="Q" sx={{ width: 200 }} value={qrCodeString} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

MQrCodeDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};
