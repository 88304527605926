import React from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

import { colors } from '../../../styles/colors';
import { average, kernelDensityEstimation, linspace, stdDev } from '../../../utils/mathUtils';

import { PARAMETER_CFG } from './MRTConfig';


export const MRTDounghnut = ({ sidedResults }) => {
  const rotaryCount = sidedResults.valid_reactions.filter((vr) => vr.dominant_type === 'ROTARY').length
  const linearCount = sidedResults.valid_reactions.filter((vr) => vr.dominant_type === 'LINEAR').length

  const data = {
    labels: ['Linear', 'Rotary', 'Invalid'],
    datasets: [{
      data: [
        linearCount,
        rotaryCount,
        sidedResults.invalid_count
      ],
      backgroundColor: [
        colors.darkBlue,
        colors.lightBlue,
        colors.lightGrey
      ],
      borderWidth: 1,
    }]
  }

  const options = {     
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70
};

  return (
    <Box sx={{display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
        <Doughnut data={data} options={options} height="100%" width="100%"/>
        <Box sx={{position: 'absolute'}}>
          {rotaryCount + linearCount}
        </Box>
    </Box>
  )
}


MRTDounghnut.propTypes = {
  sidedResults: PropTypes.object,
};

export const MRTMiniHist = ({validReactions, signalId}) => {
  
  const {bandwidth, color, range, label} = PARAMETER_CFG[signalId]
  const datapoints = validReactions.map((vr) => vr[signalId])  

  const mean = average(datapoints)
  const std = stdDev(datapoints, mean, datapoints.length)

  const [yStart, yEnd] = range
  const y = linspace(yStart, yEnd, 200)
  
  const kde = kernelDensityEstimation(datapoints, y, bandwidth)
  
  const chartData = {
    labels: y,
    datasets: [{
      fill: true,
      borderColor: color,
      backgroundColor: `${color}40`,
      borderJoinStyle: 'round',
      lineTension: 0.2,
      pointBorderWidth: 1,
      pointRadius: 0,
      borderWidth: 0.75,
      data: kde,
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        ticks: {
          display: false,
        },
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          display: false,
        },
        gridLines: {
          display: false
        }
      }]
    },
    tooltips: {enabled: false},
    hover: {mode: null}
  }
  return (
    <Box sx={{display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Box sx={{display: 'flex', height: 20, width: '100%', flexDirection: 'row'}}>
        <Box sx={{display: 'flex', justifyContent: 'center', width: '50%'}}>
          <Typography variant="caption">
            {`${mean.toFixed(label.decimals)}${label.unit}`}
          </Typography>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'center', width: '50%' }}>
          <Typography variant="caption">
            {`${std.toFixed(label.decimals)}${label.unit}`}
          </Typography>
        </Box>
      </Box>
      <Box sx={{height: 'calc(100% - 25px)', width: '100%', opacity: 0.5 }}>
        <Line data={chartData} options={options} height="100%" width="100%"/>
      </Box>
    </Box>
  )
}

MRTMiniHist.propTypes = {
  validReactions: PropTypes.array,
  signalId: PropTypes.string
};