import React, { Fragment } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

import { colors } from '../../styles/colors.js';



export const MSlopeChart = ({points, options}) => {
    const yLimits = [0.4 * points[0], 1.6 * points[0]]
    
    const relativeDifference = 100 * (points[1] - points[0]) / points[0]
    const change = ((relativeDifference > 0) ? '+' : '') + relativeDifference.toFixed(0) + '%'
  
    const chartData = {
      datasets: [
        {
          pointStyle:'triangle',
          label: options.x0.label,
          fill: false,
          showLine: false,
          borderWidth: 1,
          borderColor: 'black',
          pointRadius: 10,
          backgroundColor: options.x0.color,
          data: [{x: 1, y: points[0]}],
        },
        {
          pointStyle:'rect',
          label: options.x1.label,
          fill: false,
          showLine: false,
          borderWidth: 1,
          borderColor: 'black',
          pointRadius: 10,
          backgroundColor: options.x1.color,
          data: [{x: 2, y: points[1]}],
        },
        {
          label: 'Line',
          fill: false,
          showLine: true,
          borderWidth: 2,
          borderColor: colors.darkGrey,
          pointRadius: 0,
          backgroundColor: colors.darkGrey,
          data: points.map( (value, idx) => ({x: idx + 1, y: value}) ),
        },
        {
          label: 'y-0-axis',
          fill: false,
          showLine: true,
          borderWidth: 0,
          borderColor: colors.darkGrey,
          pointRadius: 0,
          backgroundColor: colors.lightGrey,
          data: [{ x: 0.75, y: points[0] }, { x: 2.25, y: points[0] }],
          borderDash: [3, 3]
        },
        {
          label: 'y-mim-axis',
          fill: false,
          showLine: true,
          borderWidth: 1,
          borderColor: colors.darkGrey,
          pointRadius: 0,
          backgroundColor: colors.lightGrey,
          data: [{ x: 0.75, y: 1.4 * points[0] }, { x: 2.25, y: 1.4 *  points[0] }],
          borderDash: [1, 1]
        },
        {
          label: 'y-max-axis',
          fill: false,
          showLine: true,
          borderWidth: 1,
          borderColor: colors.darkGrey,
          pointRadius: 0,
          backgroundColor: colors.lightGrey,
          data: [{ x: 0.75, y: 0.6 * points[0] }, { x: 2.25, y: 0.6 * points[0] }],
          borderDash: [1, 1]
        },
      ]
    }
  
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        mode: 'nearest',
        intersect: true,
        callbacks: {
          label: () => {},
          footer: (point) => {
            const label = (point[0].label === '2') ? options.x1.label : options.x0.label
            const value = point[0].yLabel.toFixed(2)
            return [label, value]
        }
        },
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              min: 0.75,
              max: 2.25,
              display: false,
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              min: yLimits[0],
              max: yLimits[1],
              display: false
            }
          }
        ]
      }
    }
  
    return (
      <Fragment>
        <Scatter options={chartOptions} data={chartData} />
        <Grid container sx={{ paddingInline: 1 }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: -1.5 }}>
            <Typography align="center" sx={{ fontSize: 11, color: colors.darkBlue, fontWeight: 900 }}>
              {change}
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
  
  MSlopeChart.propTypes = {
    points: PropTypes.array,
    options: PropTypes.object,
  };