import React, { useState } from 'react';
import { IoMdDownload } from 'react-icons/io';
import { Box, Dialog, Divider, Fade, IconButton, Tooltip, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import { PropTypes } from 'prop-types';

import { useGlobalContext } from '../../context/AppContext.js';
import { pryvApiCall } from '../../data/apiCalls.js';
import { gaitActivityTypes, id2TitleMap } from '../../definitions/activityTypes.js';
import { loadPatientData } from '../../utils/auxFunctions.js';
import { formatDoctorInfo } from '../../utils/dataFetchFunctions.js';
import { annotatePdf, fetchPdf } from '../../utils/pdfTools.js';

import { MSelectAssessmentsList } from './MSelectAssessmentsList.js';

const NO_COMMENT_TEXT = 'No Comments yet, author your own comments here!';

/**
 * A Assessment Specific List Dialog.
 * @param {Object} props The component properties.
 * @return {jsx} A Assessment Specific List Dialog.
 */
export const MAssessmentListDialog = ({ open, setOpen, type }) => {
  const [selected, setSelected] = useState([]);

  const patient = loadPatientData();

  const activities = patient.activities.filter((act) => {
    if (type === 'A') {
      const isGaitActivity = gaitActivityTypes.map((at) => at.id).includes(act.type);
      return act.analysisStatus === 'analyzed' && isGaitActivity;
    } else {
      return act.analysisStatus === 'analyzed';
    }
  });

  const { doctorInfoEvents } = useGlobalContext();

  const handleClose = () => {
    setSelected([]);
    setOpen(false);
  };

  const onDownloadActivitiesClick = async () => {
    const zip = require('jszip')();

    for (const act of selected) {
      const id = act.id;
      const title = id2TitleMap(act.type);
      const date = new Date(act.time * 1000).toISOString().slice(0, -8);

      const filename = `${patient.firstName[0]}${patient.lastName}_${title}_${date}`
        .replaceAll('-', '')
        .replaceAll(':', '');

      const res = await pryvApiCall(patient.apiToken, [
        {
          method: 'events.get',
          params: {
            streams: {
              all: [`activity-${id}`],
              any: ['activity-comments']
            }
          }
        }
      ]);

      let commentText = '';
      if (res[0]['events'][0]) {
        commentText = res[0]['events'][0]['content'];
      } else {
        commentText = NO_COMMENT_TEXT;
      }

      await fetchPdf(patient.apiToken, id, filename)
        .then((pdf) => annotatePdf(pdf, patient, formatDoctorInfo(doctorInfoEvents), commentText))
        .then((aPdf) => {
          zip.file(filename + '.pdf', aPdf, { binary: true });
        });
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      const cd = new Date().toISOString().slice(0, -8).replaceAll('-', '').replaceAll(':', '');
      saveAs(content, `pdf_files_${cd}.zip`);
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} disableEnforceFocus>
      <Typography
        align="center"
        variant="h6"
        sx={{
          my: 2
        }}>
        Select a Specific Assessment
      </Typography>
      <Divider />
      <Box
        sx={{
          width: '100%'
        }}>
        <MSelectAssessmentsList
          activities={activities}
          group={type}
          setSelection={type === 'C' ? setSelected : () => {}}
          isComputing={false}
          setOpen={setOpen}
        />
      </Box>
      <Tooltip title="Download all selected reports">
        <Fade in={selected.length > 0}>
          <IconButton
            color="primary"
            sx={{ position: 'absolute', left: 10, bottom: 15 }}
            onClick={onDownloadActivitiesClick}>
            <IoMdDownload style={{ fontSize: 30 }} />
          </IconButton>
        </Fade>
      </Tooltip>
    </Dialog>
  );
};

MAssessmentListDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  type: PropTypes.string
};
