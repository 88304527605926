import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container, CssBaseline } from '@mui/material';

import { useGlobalContext } from '../context/AppContext.js';
import { About as AboutPage } from '../pages/About/index.js';
import { Assessment as AssessmentPage } from '../pages/Assessment/index.js';
import { Comparison as ComparisonPage } from '../pages/Comparison/index.js';
import { Documentation as DocumentationPage } from '../pages/Documentation/index.js';
import { Home as HomePage } from '../pages/Home/index.js';
import { Patient as PatientPage } from '../pages/Patient/index.js';
import { colors } from '../styles/colors.js';

import { MAppBar } from './AppBar/MAppBar.js';
import { MCookieConsent } from './MCookieConsent.js';
import { MSelectDBInstance } from './MSelectDBInstance.js';

const ROOT = '/';

/**
 * Makes the "App" component.
 * @return {jsx} The app component.
 */
export const App = () => {
  const { docEndpoint } = useGlobalContext();

  const [dbInstance, setDbInstance] = useState();
  const [openDbSelection, setOpenDbSelection] = useState(false);

  // Refresh persistent check if user is logged in
  const hasEndpoint = sessionStorage.getItem('docEP') || docEndpoint;

  const storedInstance = window.sessionStorage.getItem('dbInstance');
  useEffect(() => {
    if (storedInstance && !dbInstance) {
      setDbInstance(storedInstance);
      return;
    }

    const gitBranch = process.env.REACT_APP_GIT_BRANCH;
    switch (gitBranch) {
      case 'master':
        setDbInstance('production');
        window.sessionStorage.setItem('dbInstance', 'production');
        break;
      case 'staging':
        setDbInstance('staging');
        window.sessionStorage.setItem('dbInstance', 'staging');
        break;
      default:
        setOpenDbSelection(true);
        break;
    }
  }, []);

  const onConfirm = (selection) => {
    window.sessionStorage.setItem('dbInstance', selection);
    setDbInstance(selection);
    setOpenDbSelection(false);
  };

  return (
    <main
      style={{
        backgroundColor: colors['backgroundLightGrey'],
        flexGrow: 1,
        overflow: 'auto'
      }}
    >
      <MCookieConsent />
      <CssBaseline />
      <MAppBar>
        <Container maxWidth="" sx={{ height: 'calc(100vh - 100px)' }}>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/documentation">
              <DocumentationPage />
            </Route>
            <Route path="/patient/:username">
              {hasEndpoint ? <PatientPage /> : <Redirect to={{ pathname: ROOT }} />}
            </Route>
            <Route exact path="/assessment/:activityId">
              {hasEndpoint ? <AssessmentPage /> : <Redirect to={{ pathname: ROOT }} />}
            </Route>
            <Route path="/compare/:username">
              {hasEndpoint ? <ComparisonPage /> : <Redirect to={{ pathname: ROOT }} />}
            </Route>
            <Redirect to={{ pathname: ROOT }} />
          </Switch>
        </Container>
      </MAppBar>
      <MSelectDBInstance onConfirm={onConfirm} isOpen={openDbSelection} />
    </main>
  );
};
