import React, { Fragment, useEffect, useState } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';

import { MComparisonLayout } from '../../components/layout/MComparisonLayout.js';
import { useGlobalContext } from '../../context/AppContext.js';
import { assessmentCall, pryvApiCall } from '../../data/apiCalls.js';
import { getRegisteredAffiliations } from '../../data/apiCalls.js';
import environments from '../../definitions/environments.json';
import { gaitParameters } from '../../definitions/gaitParameters.js';
import { loadPatientData } from '../../utils/auxFunctions.js';
import { aggregateStats, formatAssessmentData } from '../../utils/dataFetchFunctions.js';
import { formatDoctorInfo } from '../../utils/dataFetchFunctions.js';
import { annotateRemoteReport, downloadPdf } from '../../utils/pdfTools.js';

import { MComparisonController } from './Controller/MComparisonController.js';
import { MComparisonResults } from './Results/MComparisonResults.js';
import { MComparisonStats } from './Stats/MComparisonStats.js';
import { MSetupComparison } from './MSetupComparison.js';

if (getCookieConsentValue()) {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID, {
    testMode: process.env.NODE_ENV === 'test'
  });
}

/**
 * Displays the comparison page.
 * @return {jsx} The assessment page component.
 */
export const Comparison = () => {
  const patient = loadPatientData();
  const instance = window.sessionStorage.getItem('dbInstance');
  const nushuApiURL = environments[instance].endpoints.nushu_api_url;

  const { doctorInfoEvents } = useGlobalContext();

  const [componentState, setComponentState] = useState('init');
  const [statsA, setStatsA] = useState();
  const [statsB, setStatsB] = useState();

  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/compare', title: 'Comparison Page' });
  }, []);

  // Compute stats shared to MSetupComparison & MComparisonController
  const computeStats = (grpA, grpB) => {
    if (componentState === 'computing') return;

    setComponentState('computing');
    Promise.all([compileOverallStats(grpA), compileOverallStats(grpB)]).then((values) => {
      setStatsA(values[0]);
      setStatsB(values[1]);
      setComponentState('post-computation');
    });
  };

  const compileOverallStats = async (selection) => {
    // Load all selected assessments
    const allStatsArray = await Promise.all(
      selection.map((act) => pryvApiCall(patient.apiToken, assessmentCall(act.id)))
    );
    // Format assessments
    allStatsArray.forEach((rawData, idx) => {
      allStatsArray[idx] = formatAssessmentData(rawData)[0];
    });
    // Add up totalStats
    return aggregateStats(allStatsArray);
  };

  const formatGroupStatsForReport = (group, stats) => {
    const gaitStatistics = {};
    for (const side of ['L', 'R']) {
      gaitStatistics[side] = {};

      for (const parameter of gaitParameters) {
        gaitStatistics[side][parameter.id.replace('-', '_')] = {
          avg: stats['step-stats'][side][parameter.id].avg,
          std: stats['step-stats'][side][parameter.id].std
        };
      }
    }

    return {
      activities: group.map((activitiy) => {
        const unix = new Date(activitiy.time);
        return {
          type: activitiy.type,
          date: unix.getTime() / 1000 - unix.getTimezoneOffset() * 60,
          vibration: activitiy.vibration ? activitiy.vibration : 'none'
        }
      }),
      statistics: {
        summary: stats.totalStats,
        gait: gaitStatistics
      }
    };
  };

  const onDownloadReport = async (groupA, groupB) => {
    const doctor = formatDoctorInfo(doctorInfoEvents);
    const registry = await getRegisteredAffiliations(instance);
    const patientName = `${patient.firstName} ${patient.lastName}`;
    
    const body = {
      authUrl: sessionStorage.getItem('docEP'),
      patient: {
        username: patient.username,
        name: `${patient.firstName} ${patient.lastName}`,
        birthday: patient.birthdate.getTime() / 1000,
        height: patient.height,
        weight: patient.weight
      },
      doctor: {
        username: doctor.username,
        name: `${doctor.firstName} ${doctor.lastName}`,
        email: doctor.email,
        phone: doctor.phone ? doctor.phone : '',
        affiliation: registry ? registry[doctor.affiliationShorthand].name : 'n/a'
      },
      comparison: {
        groupA: formatGroupStatsForReport(groupA, statsA),
        groupB: formatGroupStatsForReport(groupB, statsB)
      }
    };

    const report = await fetch(`https://${nushuApiURL}/generate-comp-report`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((resp) => resp.arrayBuffer());

    const filename = `${patientName.replace(' ', '')}_comparison_report`;
    annotateRemoteReport(report, doctor.affiliationShorthand).then((annotatedReport) =>
      downloadPdf(annotatedReport, filename)
    );
  };

  return (
    <Fragment>
      <MComparisonLayout
        viewState={componentState}
        containerA={<MComparisonResults statsA={statsA} statsB={statsB} />}
        containerB={<MComparisonStats statsA={statsA} statsB={statsB} />}
        containerC={<MSetupComparison computeStats={computeStats} />}
        containerD={
          <MComparisonController
            viewState={componentState}
            computeStats={computeStats}
            onDownloadReport={onDownloadReport}
          />
        }
      />
    </Fragment>
  );
};
