import React, { Fragment, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Typography } from '@mui/material';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MOverlay } from '../../../components/ui/MOverlay.js';
import { getReferenceRange } from '../../../definitions/gaitParameterRefRanges.js';
import { colors } from '../../../styles/colors.js';
import { loadPatientData } from '../../../utils/auxFunctions.js';
import { linspace } from '../../../utils/mathUtils.js';

// eslint-disable-next-line no-undef
Chart.defaults.global.legend.display = false;

/**
 * Plots timeseries data in a toggleable view
 * @param {Object} props The component properties.
 * @return {jsx} timeseries plot component.
 */
export const MParameterTimePlots = ({ parameter, datasets }) => {
  const [xAxis, setXAxis] = useState('Stride No.');
  const [isDialogOpen, setDialogOpen] = useState(false);

  const theme = useTheme();

  const { id, title, unit } = parameter;
  const {macroEvents, sex, age} = loadPatientData()

  const toggleXAxis = (event) => setXAxis(event.currentTarget.value);

  // generate plot labels
  const tStart = Math.min(...datasets.map((ds) => ds.ts[0]));
  const tEnd = Math.max(...datasets.map((ds) => ds.ts.slice(-1)));
  const noOfValues = Math.max(...datasets.map((ds) => ds.values.length));

  const plotLabels = {
    'Stride No.': linspace(1, noOfValues, noOfValues),
    Time: linspace(0, tEnd - tStart, Math.ceil((tEnd - tStart) * 10)).map((v) => v.toFixed(2))
  };

  // Resample values to new 'Time' labels
  const resampledSets = datasets.map((ds) => {
    const rs = { ...ds };

    const tLabel = plotLabels.Time;
    const rData = new Array(tLabel.length).fill(null);

    for (let i = 0; i < ds.ts.length; i++) {
      const minDeltaT = Math.min(...tLabel.map((tl) => Math.abs(tl - (ds.ts[i] - tStart))));
      const idx = tLabel.findIndex((tl) => Math.abs(tl - (ds.ts[i] - tStart)) === minDeltaT);

      rData[idx] = ds.values[i].toFixed(2);
    }

    rs.values = rData;
    return rs;
  });

  const plotData = {
    'Stride No.': datasets.map((ds) => {
      return {
        label: ds.label,
        fill: false,
        backgroundColor: ds.color,
        borderColor: ds.color,
        borderJoinStyle: 'round',
        lineTension: 0.2,
        pointBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: ds.values.map((v) => v.toFixed(2))
      };
    }),
    Time: resampledSets.map((rs) => ({
      label: rs.label,
      fill: true,
      borderWidth: 1,
      borderColor: rs.color,
      backgroundColor: `${rs.color}99`,
      data: rs.values,
      barPercentage: Math.ceil(plotLabels['Time'].length / 100) + 1
    }))
  };

  const createRangeDataset = (label, fill, val) => {
    return {
      type: 'line',
      label,
      fill,
      backgroundColor: `${theme.palette.primary.main}22`,
      borderColor: colors['darkGrey'],
      borderJoinStyle: 'round',
      borderWidth: 1,
      lineTension: 0.2,
      pointBorderWidth: 0,
      pointRadius: 0,
      pointHitRadius: 0,
      data: [
        { x: 0, y: val.toFixed(2) },
        {
          x: plotLabels[xAxis].slice(-1)[0],
          y: val.toFixed(2)
        }
      ]
    };
  };

  const range = getReferenceRange(id, sex, age);
  if (range) {
    plotData[xAxis].push(
      ...[
        createRangeDataset('Lower Bound', 'start', range[0]),
        createRangeDataset('Upper Bound', 'end', range[1])
      ]
    );
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: unit
          },
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    annotation: {
      annotations: []
    }
  };

  macroEvents
    .filter((event) => {
      return event.streamId === 'fog' && tStart <= event.time && event.time <= tEnd;
    })
    .map((event) => {
      const lTs = datasets[0].ts;
      const dts = lTs.map((ts) => Math.abs(ts - event.time));
      const minIdx = dts.indexOf(Math.min(...dts));

      const strideNo = lTs[minIdx] - event.time <= 0 ? minIdx + 0.5 : minIdx - 0.5;

      const dt = plotLabels.Time.map((tl) => Math.abs(tl - (event.time - tStart)));
      const tsIdx = dt.indexOf(Math.min(...dt));

      const eventType = event.streamId;
      options.annotation.annotations.push({
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: xAxis === 'Stride No.' ? strideNo : tsIdx,
        borderWidth: 2,
        borderColor: theme.palette.warning.main,
        label: {
          content: eventType,
          enabled: true,
          position: 'bottom',
          fontSize: 8,
          backgroundColor: theme.palette.primary.main
        }
      });
    });

  // Copy for dialog plot with x axis label
  const dialogOptions = JSON.parse(JSON.stringify(options));
  const xAxisLabel = xAxis === 'Stride No.' ? 'Stride No.' : `${xAxis} [s]`;
  dialogOptions.scales.xAxes.push({
    scaleLabel: {
      display: true,
      labelString: xAxisLabel
    }
  });

  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        <div onClick={() => setDialogOpen(true)} style={{ height: 'calc(100% - 30px)'}}>
          {xAxis === 'Stride No.' ? (
            <Line
              data={{ labels: plotLabels[xAxis], datasets: plotData[xAxis] }}
              options={options}
              lineAtIndex={[2, 4, 8]}
              height={1}
              width={1}
            />
          ) : (
            <Bar
              data={{ labels: plotLabels[xAxis], datasets: plotData[xAxis] }}
              options={options}
              height={1}
              width={1}
            />
          )}
        </div>
        <div style={{ height: 30, width: '100%', marginTop: 5 }}>
          <ToggleButtonGroup
            align="center"
            value={xAxis}
            exclusive
            onChange={toggleXAxis}
            sx={{ height: '100%' }}
          >
            <ToggleButton value="Stride No.">
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: 11.5,
                  textTransform: 'none'
                }}
              >
                Stride No.
              </Typography>
            </ToggleButton>
            <ToggleButton value="Time">
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: 11.5,
                  textTransform: 'none'
                }}
              >
                Time [s]
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Box>
      <MOverlay title={title} open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        {xAxis === 'Stride No.' ? (
          <Line
            data={{ labels: plotLabels[xAxis], datasets: plotData[xAxis] }}
            options={dialogOptions}
            lineAtIndex={[2, 4, 8]}
          />
        ) : (
          <Bar
            data={{ labels: plotLabels[xAxis], datasets: plotData[xAxis] }}
            options={dialogOptions}
          />
        )}
      </MOverlay>
    </Fragment>
  );
};

MParameterTimePlots.propTypes = {
  parameter: PropTypes.object,
  datasets: PropTypes.array
};
