import React, { useEffect, useState } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { MAssessmentLayout } from '../../components/layout/MAssessmentLayout.js';
import { MLoadingScreen } from '../../components/layout/MLoadingScreen.js';
import { assessmentCall, prevPostResultsCall, pryvApiCall } from '../../data/apiCalls.js';
import { loadPatientData } from '../../utils/auxFunctions.js';
import { formatAssessmentData } from '../../utils/dataFetchFunctions.js';

import { MFatigueResults } from './GaitParameters/MFatigueResults.js';
import { MGaitParameters } from './GaitParameters/MGaitParameters.js';
import { MPostDetails } from './Posturography/MPostDetails.js';
import { MPostResults } from './Posturography/MPostResults.js';
import {MRTDetails} from './ReactionTimeTest/MRTDetails.js'
import {MRTResults} from './ReactionTimeTest/MRTResults.js'
import { MAssessmentController } from './MAssessmentController.js';
import { MAssessmentDetails } from './MAssessmentDetails';
import { MROWSelector } from './MROWSelector.js';

if (getCookieConsentValue()) {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID, {
    testMode: process.env.NODE_ENV === 'test'
  });
}
/**
 * Displays information about a single assessment.
 * @return {jsx} The assessment page component.
 */
export const Assessment = () => {
  const [activity, setActivity] = useState();

  const patientData = loadPatientData();
  const apiToken = patientData.apiToken;
  const analyzedActivities = patientData.activities.filter(
    (item) => item.analysisStatus === 'analyzed'
  );

  const location = useLocation();

  // Initial load && Landing from select assessment list
  useEffect(() => {
    const activityId = location.pathname.split('/').reverse()[0];
    const activityIdx = analyzedActivities.findIndex((a) => a.id === activityId);
    fetchFormatActivity(activityIdx);

    ReactGA.send({ hitType: 'pageview', page: '/assessment', title: 'Assessment Page' });
  }, [location.pathname]);

  const toAssessment = (direction) => {
    const newIdx = activity.idx + direction;

    if (newIdx > analyzedActivities.length || newIdx < 0) {
      return;
    }

    window.history.replaceState(null, '', `/assessment/${analyzedActivities[newIdx].id}`);

    fetchFormatActivity(newIdx);
    ReactGA.send({ hitType: 'pageview', page: '/assessment', title: 'Assessment Page' });
  };

  const fetchFormatActivity = (idx) => {
    const id = analyzedActivities[idx].id;
    pryvApiCall(apiToken, assessmentCall(id)).then((rawData) => {
      const data = formatAssessmentData(rawData)[0];
      // Add assessment type, id & vib
      data.type = analyzedActivities[idx].type;
      data.vibrationType = analyzedActivities[idx].vibration;
      data.id = id;

      // Add rec time if tug test
      if (data.type === 'tug') {
        data.totalStats.recordingTime = rawData[0].events[0].content['recording-time'].L;
      }

      // Get the latest all previous post results
      if (data.type === 'posturography') {
        pryvApiCall(apiToken, prevPostResultsCall(rawData[0]['events'][0].time - 1)).then((res) => {
          const formattedResults = formatAssessmentData(res);
          setActivity({
            idx: idx,
            selectedRow: 0,
            results: data,
            pastResults: formattedResults
          });
        });
      } else {
        setActivity({
          idx: idx,
          selectedRow: 0,
          results: data
        });
      }
    });
  };

  const isLastOrFirstActivity = () => {
    switch (activity.idx) {
      case analyzedActivities.length - 1: {
        if (activity.idx === 0) {
          return '&';
        }
        return 1;
      }
      case 0:
        return -1;
      default:
        return;
    }
  };

  if (!activity) {
    return <MLoadingScreen />;
  } else if (activity.results.type === 'posturography') {
    return (
      <MAssessmentLayout
        containerA={<MPostResults results={activity.results} pastResults={activity.pastResults} />}
        containerB={<MPostDetails results={activity.results} />}
        containerC={undefined}
        containerD={
          <MAssessmentController edgeIdx={isLastOrFirstActivity()} toAssessment={toAssessment} />
        }
      />
    );
  } else if (activity.results.type === 'simple-haptic-reaction-time') {
    return(
      <MAssessmentLayout
        containerA={<MRTResults results={activity.results} />}
        containerB={<MRTDetails results={activity.results} />}
        containerC={undefined}
        containerD={
          <MAssessmentController edgeIdx={isLastOrFirstActivity()} toAssessment={toAssessment} />
        }
      />
    )
  } else {
    return (
      <MAssessmentLayout
        containerA={
          <MGaitParameters rowAnalysisResults={activity.results.rows[activity.selectedRow]} />
        }
        containerB={<MAssessmentDetails analysisResults={activity.results} />}
        containerC={
          activity.results.rows.length > 1 && (
            <MROWSelector activity={activity} setActivity={setActivity} />
          )
        }
        containerD={
          <MAssessmentController edgeIdx={isLastOrFirstActivity()} toAssessment={toAssessment} comparisonEnabled={activity.results.type !== 'posturography'} />
        }
        containerE={
          (activity.results.type === '3min-test' || activity.results.totalStats.steps > 300) && (
            <MFatigueResults results={activity.results} />
          )
        }
      />
    );
  }
};
