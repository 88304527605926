import React, { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { PropTypes } from 'prop-types';

/**
 * Shows success, warn, error or info messages.
 * @param {Object} props The component properties.
 * @return {jsx} The snackbar component.
 */
export const MCustomSnackbar = ({ content, handleClose }) => {
  const [open, setOpen] = useState(true);

  const closeSnackbar = () => {
    setOpen(false);
    handleClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      open={open}
      onClose={closeSnackbar}
      sx={{ position: 'fixed', top: 80 }}
    >
      <Alert onClose={handleClose} severity={content.severity} variant="filled">
        {content.message}
      </Alert>
    </Snackbar>
  );
};

MCustomSnackbar.propTypes = {
  content: PropTypes.object,
  handleClose: PropTypes.func
};
