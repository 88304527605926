import React from 'react';

import './calendar-heatmap.css';

/**
 * Renders a legend to explain the calendar elements.
 * (*Highly dependent on CalendarHeatmap*)
 * @return {jsx} The legend component.
 */
export const MCalendarLegend = () => {
  return (
    <svg className="react-calendar-heatmap" viewBox="0 0 612 20">
      <text style={{ fontSize: 8 }} x="0" y="9">
        less
      </text>
      <rect
        width="10"
        height="10"
        x="22"
        y="0"
        className="color-gitlab-0"
        data-tip="No assessments"
      />
      <rect
        width="10"
        height="10"
        x="33"
        y="0"
        className="color-gitlab-1"
        data-tip="1-4 assessments"
      />
      <rect
        width="10"
        height="10"
        x="44"
        y="0"
        className="color-gitlab-2"
        data-tip="5-9 assessments"
      />
      <rect
        width="10"
        height="10"
        x="55"
        y="0"
        className="color-gitlab-3"
        data-tip="10-19 assessments"
      />
      <rect
        width="10"
        height="10"
        x="66"
        y="0"
        className="color-gitlab-4"
        data-tip="20+ assessments"
      />
      <text style={{ fontSize: 8 }} x="80" y="9">
        more
      </text>
    </svg>
  );
};
