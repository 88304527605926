import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTheme } from '@mui/material/styles';

/**
 * Makes the "MCookieConsent" component.
 * @return {jsx} The cookie consent component.
 */
export const MCookieConsent = () => {
  const theme = useTheme();

  const onAcceptCookies = (acceptedByScrolling) => {
    if (acceptedByScrolling) {
      // this is currently disabled anyway, just here for completeness
      // need to check with legal body if this is allowed
    } else {
      // default cookie acceptance is false, thus we need to reload in order
      // to propagate the setting if it is accepted
      window.location.reload(false);
    }
  };

  const onDeclineCookies = () => {};
  return (
    <CookieConsent
      location="bottom"
      enableDeclineButton={true}
      buttonText="I accept"
      buttonStyle={{
        background: theme.palette.secondary.main,
        fontWeight: 'bolder',
        fontSize: 14,
        borderRadius: 5,
        height: 35
      }}
      declineButtonStyle={{
        border: 'solid',
        borderWidth: 0.5,
        color: theme.palette.common.white,
        background: 'none',
        fontWeight: 'bolder',
        fontSize: 14,
        borderRadius: 5,
        height: 35
      }}
      onAccept={(acceptedByScrolling) => {
        onAcceptCookies(acceptedByScrolling);
      }}
      onDecline={() => {
        onDeclineCookies();
      }}
      overlay={true}
    >
      This website uses cookies 🍪 to enhance your user experience. Click &apos;I accept&apos; to
      continue, or &apos;I decline&apos; to opt out if you wish.
    </CookieConsent>
  );
};
