import React, { Fragment, useEffect, useState } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';

import { MLoadingScreen } from '../../components/layout/MLoadingScreen.js';
import { MPatientLayout } from '../../components/layout/MPatientLayout.js';
import { allActivitiesMetadataCall, patientStatsCall, pryvApiCall } from '../../data/apiCalls.js';
import { loadPatientData, savePatientData } from '../../utils/auxFunctions.js';
import {
  extractActivity,
  formatOverallStats,
  formatTrendStats,
  getTotalsFromLastWeek
} from '../../utils/dataFetchFunctions.js';

import { MDetails } from './Details/MDetails.js';
import { MGaitProgression } from './GaitProgression/MGaitProgression.js';
import { MTimeline } from './Timeline/MTimeline.js';
import { MNavigationCards } from './MNavigationCards.js';
import { MSummaryStats } from './MSummaryStats.js';

if (getCookieConsentValue()) {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID, {
    testMode: process.env.NODE_ENV === 'test'
  });
}

/**
 * Renders the "Patient" page. Displays information about a patient.
 * @param {Object} props of the page of which patient data is the main thing.
 * @return {jsx} The patient page component.
 */
export const Patient = () => {
  const [patient, setPatient] = useState();

  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/patient', title: 'Patient Page' });
  }, []);

  useEffect(() => {
    const storedUser = loadPatientData();

    let call = patientStatsCall;
    // Handle reloading of activities on manual refresh
    if (!sessionStorage.getItem('from_redirect')) {
      call[0].params.push(...allActivitiesMetadataCall);
    }

    pryvApiCall(storedUser.apiToken, call).then((res) => {
      storedUser.stats = formatOverallStats(res[0].results[0]);
      storedUser.macroEvents = res[0].results[1].events;

      storedUser.stats.trends = formatTrendStats(res[0].results[2].results);
      storedUser.stats.weekly = getTotalsFromLastWeek(storedUser.stats.trends.all);

      if (!sessionStorage.getItem('from_redirect')) {
        storedUser.activities = res[0].results[3].events.map((me) => extractActivity(me));
      }

      setPatient(storedUser);
      savePatientData({
        ...storedUser,
        macroEvents: storedUser.macroEvents
      });

      sessionStorage.removeItem('from_redirect');
    });
  }, []);

  return (
    <Fragment>
      {!patient ? (
        <MLoadingScreen />
      ) : (
        <MPatientLayout
          username={patient.username}
          patientName={`${patient.firstName} ${patient.lastName}`}
          containerA={<MGaitProgression trend={patient.stats.trends} />}
          containerB={
            <MSummaryStats totals={patient.stats.total} weeklyChange={patient.stats.weekly} />
          }
          containerC={
            <MTimeline
              endpoint={patient.apiToken}
              macroEvents={patient.macroEvents}
              firstAssessmentDate={patient.firstAssessmentDate}
              registrationDate={patient.regDate}
            />
          }
          containerD={<MDetails patient={patient} />}
          containerE={
            <MNavigationCards username={patient.username} activities={patient.activities} />
          }
        />
      )}
    </Fragment>
  );
};

export default Patient;
