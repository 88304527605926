import React, { Fragment, useEffect, useRef, useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import { MdCheckBox, MdChevronRight, MdHourglassEmpty,MdVibration, MdWarning } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Box, Divider, List, ListItemButton, Paper, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MCustomSnackbar } from '../../../components/ui/MCustomSnackbar';
import { id2TitleMap } from '../../../definitions/activityTypes.js';
import { vibId2TitleMap } from '../../../definitions/vibrationTypes.js';
import { colors } from '../../../styles/colors.js';
import { dateTimeString, makeDateString } from '../../../utils/auxFunctions.js';

import { MCalendarLegend } from './MCalendarLegend';

import './calendar-heatmap.css';

/**
 * Renders the "MCalendarHistory" component.
 * @return {jsx} The calendar heatmap component.
 */
export const MCalendarHistory = ({ activities }) => {
  const theme = useTheme();
  const history = useHistory();

  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false
  });
  const [expanded, setExpanded] = useState(false);
  const [displayList, setDisplayList] = useState(activities);
  const [listTitle, setListTitle] = useState('All Assessments');

  const today = new Date();

  const handleClick = (value) => {
    if (!value) {
      setSnackbar({
        message: 'There was no assessment on that day.',
        severity: 'warning',
        show: true
      });

      setListTitle('All Assessments');
      setExpanded(false);
    } else {
      setSnackbar({
        message: `${value.count} items on ${makeDateString(value.date)}`,
        severity: 'info',
        show: true
      });
      setDisplayList(value.list);
      setListTitle(`Assessments on ${makeDateString(value.date)}`);
      setExpanded(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar(false);
  };

  const calendarContainer = useRef(null);

  const handleClickOutside = (event) => {
    if (calendarContainer.current && !calendarContainer.current.contains(event.target))
      setExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  const toAssessmentClick = (activity) => {
    history.push({
      pathname: `/assessment/${activity.id}`
    });
  };

  const readTime = (utcTime) => {
    const dateTime = new Date(utcTime);
    return dateTimeString(dateTime).split('@')[1];
  };

  const scrollContainer = useRef(null);
  useEffect(() => {
    scrollContainer.current.scrollLeft = 1000;
  }, []);

  return (
    <Box ref={calendarContainer}>
      <Box
        hidden={!expanded}
        sx={{
          position: 'fixed',
          top: 'calc(22vh + 200px)',
          right: '25.5vw',
          zIndex: 100
        }}
      >
        <Paper
          elevation={10}
          sx={{
            width: 400,
            overflow: 'hidden'
          }}
        >
          <Box
            sx={{
              backgroundColor: colors['lightGrey'],
              height: 40
            }}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{
                transform: 'translate(0%, 50%)'
              }}
            >
              {listTitle}
            </Typography>
          </Box>
          <Box
            sx={{
              minHeight: 100,
              maxHeight: 220,
              overflow: 'scroll',
              padding: 1,
              paddingTop: 0
            }}
          >

            <List dense>
              {displayList.map((listItem, idx) => (
                <Fragment key={idx}>
                  <ListItemButton
                    disabled={!(listItem.analysisStatus === 'analyzed')}
                    onClick={() => toAssessmentClick(listItem)}
                  > 
                    <Box sx={{ px: 1 }}>
                      < AnalysisStatusIcon analysisStatus={listItem.analysisStatus} />
                    </Box>
                    <Typography variant="subtitle1" color="textSecondary" sx={{ fontSize: 13 }}>
                      {readTime(listItem.time)}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontSize: 13, pl: 1 }}>
                      {id2TitleMap(listItem.type)}
                    </Typography>
                    {listItem.vibration && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          align="center"
                          color="primary"
                          sx={{
                            fontSize: 13,
                            marginLeft: 1
                          }}
                        >
                          +
                        </Typography>
                        <Tooltip title={vibId2TitleMap(listItem.vibration)}>
                          <Box sx={{ display: 'flex' }}>
                            <MdVibration
                              style={{
                                marginLeft: 5,
                                fontSize: 13
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                    )}
                    {(listItem.analysisStatus === 'analyzed') && (
                      <MdChevronRight
                        align="right"
                        style={{
                          position: 'absolute',
                          right: 10,
                          color: theme.palette.primary.main
                        }}
                      />
                    )}
                  </ListItemButton>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </Box>
        </Paper>
      </Box>
      <Box sx={{ height: 165, overflow: 'auto', position: 'relative' }} ref={scrollContainer}>
        <Box style={{ minWidth: 1000, top: 0, left: 0, position: 'absolute' }}>
          <CalendarHeatmap
            startDate={shiftDate(today, -364)}
            endDate={today}
            values={getDateActivityCount(activities)}
            showWeekdayLabels={false}
            classForValue={(value) => {
              if (!value) return 'color-empty';
              if (value.count < 5) return 'color-gitlab-1';
              if (value.count < 10) return 'color-gitlab-2';
              if (value.count < 20) return 'color-gitlab-3';
              return 'color-gitlab-4';
            }}
            tooltipDataAttrs={(value) => {
              if (!value.date || !value.count) {
                return;
              }
              let date;
              if (value.date instanceof Date) {
                date = makeDateString(value.date);
              }
              const plural = value.count === 1 ? '' : 's';
              return {
                'data-tip': `${value.count} assessment${plural} <br /> on ${date}`
              };
            }}
            onClick={(value) => handleClick(value)}
          />
        </Box>
      </Box>
      <MCalendarLegend />
      <ReactTooltip html={true} />
      {snackbar.show && <MCustomSnackbar content={snackbar} handleClose={handleClose} />}
    </Box>
  );
};

/**
 * Checks if two dates are on the same day
 @param {Date} first first date
 @param {Date} second second date
 @return {Bool} if the first and second date refer to the same day.
*/
const datesAreOnSameDay = (first, second) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};

/**
 * Gets the number of activities per date.
 @param {Arr} activityArray of all activities
 @return {Arr} of dates with the number of activities for each.
*/
const getDateActivityCount = (activityArray) => {
  const dateActivityCount = [];
  activityArray.forEach((activity) => {
    const iDate = new Date(activity.time);
    const idx = dateActivityCount.findIndex((dac) => datesAreOnSameDay(dac.date, iDate));
    if (idx === -1) {
      dateActivityCount.push({ date: iDate, count: 1, list: [activity] });
    } else {
      dateActivityCount[idx].count++;
      dateActivityCount[idx].list.push(activity);
    }
  });
  return dateActivityCount;
};

/**
 * Shifts a date by the given amount of days.
 * @param {date} date The initial date.
 * @param {int} days The days to count back.
 * @return {date} The shifted date.
 */
const shiftDate = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

MCalendarHistory.propTypes = {
  activities: PropTypes.array
};


const AnalysisStatusIcon = ({ analysisStatus }) => {
  const theme = useTheme();
  switch (analysisStatus) {
    case "analyzed":
      return <MdCheckBox size={18} style={{ color: theme.palette.success.main }} />
    case "failed":
      return <MdWarning size={18} style={{ color: theme.palette.warning.main }} />
    default:
      return <MdHourglassEmpty size={18} style={{ color: theme.palette.warning }} />
  } 
}

AnalysisStatusIcon.propTypes = {
  analysisStatus: PropTypes.String
};