import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Grid, Paper, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MAssessmentListDialog } from '../../components/ui/MAssessmentListDialog.js';
import { MPlotLabels } from '../../components/ui/MPlotLabels.js';
import { MTooltipIconButton } from '../../components/ui/MTooltipIconButton';
import { gaitActivityTypes } from '../../definitions/activityTypes.js';

export const MNavigationCards = ({ username, activities }) => {
  const [openAlDialog, setOpenAlDialog] = useState(false);

  const history = useHistory();
  const theme = useTheme();

  const analyzedActivities = activities.filter((act) => act.analysisStatus === 'analyzed');
  const analyzedGaitActivities = analyzedActivities.filter((act) => {
    return gaitActivityTypes.map((gat) => gat.id).includes(act.type);
  });

  const toCompareClick = () => {
    if (analyzedActivities.length < 2) {
      return;
    }
    history.push({
      pathname: '/compare/' + username
    });
  };

  const toAssessmentClick = () => {
    if (analyzedActivities.length === 0) {
      setOpenAlDialog(true);
    } else {
      history.push({
        pathname: `/assessment/${analyzedActivities[0].id}`
      });
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} md={4} align="center" display={{ md: 'none', lg: 'block' }}>
          <MPlotLabels
            colorLeft={theme.palette.info.main}
            colorRight={theme.palette.secondary.main}
          />
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          md={12}
          lg={8}
          sx={{ [theme.breakpoints.down('md')]: { pb: 4 } }}>
          <Paper
            sx={{
              display: 'flex',
              p: 1.3,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}>
              <MTooltipIconButton
                id="comparison-tool-button"
                buttonValue="compare"
                title="Comparison Tool"
                onClickEvent={toCompareClick}
                buttonType="tooltipIconButton"
                buttonStyle={{
                  fontSize: 35
                }}
                disabled={analyzedGaitActivities.length === 0}
              />
              <MTooltipIconButton
                buttonValue="listSelection"
                title="Select Specific Assessment"
                onClickEvent={() => setOpenAlDialog(true)}
                buttonType="tooltipIconButton"
                disabled={analyzedActivities.length === 0}
                buttonStyle={{
                  fontSize: 25
                }}
              />
              <MTooltipIconButton
                buttonValue="lastPage"
                title="Go to latest"
                onClickEvent={toAssessmentClick}
                buttonType="tooltipIconButton"
                id="go-to-latest-assessment"
                disabled={analyzedActivities.length === 0}
                buttonStyle={{
                  fontSize: 35
                }}
              />
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <MAssessmentListDialog open={openAlDialog} setOpen={setOpenAlDialog} type="C" />
    </Fragment>
  );
};

MNavigationCards.propTypes = {
  username: PropTypes.string,
  activities: PropTypes.array
};
