import React, { useState } from 'react';
import { Box, ButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MHistogramChart } from '../../../components/ui/MHistogramChart.js';
import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';

import { MParameterTimePlots } from './MParameterTimePlots.js';

/**
 * Shows detailed charts (line and histogram) with upper and lower bounds.
 * @param {Object} props The component properties.
 * @return {jsx} The detailed chart component.
 */
export const MParameterChartPanel = ({ parameter, statistics }) => {
  const [chartView, setChartView] = useState('line');

  const theme = useTheme();

  const handleViewChange = (event) => setChartView(event.currentTarget.value);

  const datasets = [];
  for (const side in statistics) {
    let color;
    let label;
    if (side === 'L') {
      label = 'Left';
      color = theme.palette.info.main;
    } else {
      label = 'Right';
      color = theme.palette.info.light;
    }

    datasets.push({
      label: label,
      ts: statistics[side].t0,
      values: statistics[side].values,
      color: color
    });
  }

  return (
    <Box
      alignItems="center"
      sx={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column' }}
    >
      <Box sx={{ height: 30, marginBottom: 1 }}>
        <ButtonGroup color="primary">
          <MTooltipIconButton
            buttonValue="chartLine"
            title="Show time series"
            value={'line'}
            onClickEvent={handleViewChange}
            variant={chartView === 'line'}
          />
          <MTooltipIconButton
            buttonValue="chartArea"
            title="Show histogram"
            value={'histogram'}
            onClickEvent={handleViewChange}
            variant={chartView === 'histogram'}
          />
        </ButtonGroup>
      </Box>
      <Box sx={{ height: 'calc(100% - 40px)', width: '100%' }}>
        {chartView === 'line' && <MParameterTimePlots parameter={parameter} datasets={datasets} />}
        {chartView === 'histogram' && (
          <MHistogramChart title={parameter.title} datasets={datasets} unit={parameter.unit} />
        )}
      </Box>
    </Box>
  );
};

MParameterChartPanel.propTypes = {
  parameter: PropTypes.object,
  statistics: PropTypes.object
};
