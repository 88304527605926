import React, { Fragment, useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Dialog, DialogTitle } from '@mui/material';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { PropTypes } from 'prop-types';

import { colors } from '../../../styles/colors.js';



export const MEnvelopeAreaPlot = ({datasets}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const variantToState = {
    full: ['open', 'closed'],
    half: ['open']
  }

  const getEnvelopeArea = (data, state) => {
    if (!variantToState[data.variant].includes(state)) {
      return
    }
    return {
      x: data['bounding-envelope'].xl[state].area,
      y: data['bounding-envelope'].omega[state].area
    }
  }

  const plotData = {
    datasets: [
      {
        pointStyle: 'rect',
        label: 'Closed',
        backgroundColor: `${colors.lightBlue2}50`,
        borderWidth: 2,
        borderColor: colors.lightBlue2,
        pointRadius: 15,
        data: [getEnvelopeArea(datasets[0], 'closed')],
        showLine: false,
      },
      {
        pointStyle: 'triangle',
        label: 'Open',
        backgroundColor: `${colors.lightBlue}50`,
        borderWidth: 2,
        borderColor: colors.lightBlue,
        pointRadius: 15,
        data: [getEnvelopeArea(datasets[0], 'open')],
        showLine: false,
      },
      {
        pointStyle: 'rect',
        label: 'Closed Past',
        backgroundColor: `${colors.lightGrey}20`,
        borderWidth: 2,
        borderColor: colors.lightGrey,
        pointRadius: 10,
        data: datasets.slice(1).map((ds) => getEnvelopeArea(ds, 'closed')),
        showLine: false,
      },
      {
        pointStyle: 'triangle',
        label: 'Open Past',
        backgroundColor: `${colors.darkGrey}20`,
        borderWidth: 2,
        borderColor: colors.darkGrey,
        pointRadius: 10,
        data: datasets.slice(1).map((ds) => getEnvelopeArea(ds, 'open')),
        showLine: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    legend: {
      display: true,
      align: 'end',
      labels: {
        usePointStyle: true,
        padding: 5,
        boxWidth: 20,
        fontSize: 10,
      }
    },
    tooltips: {
      mode: 'nearest',
      intersect: true,
      callbacks: {
        label: (point) => {
          const tt = {}

          for (const label of ['xLabel', 'yLabel']) {
            const items = point[label].toExponential().split('e')
            items[0] = items[0].slice(0, 4)
            tt[label] = items.join('e')

          }

          return `(${tt.xLabel}, ${tt.yLabel})`
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            labelString: 'Acc. Envelope Area',
            display: true,
          },
          ticks: {
            display: true,
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            labelString: 'Gyr. Envelope Area',
            display: true,
          },
          ticks: {
            display: true,
          }
        },
      ]
    }
  }

  return(
    <Fragment>
      <div onClick={ () => setDialogOpen(true) }>
        <Scatter options={options} data={plotData} height="100%" width="100%" />
      </div>
      <Dialog onClose={() => setDialogOpen(false)} maxWidth="xl" open={isDialogOpen}>
        <DialogTitle>{"Envelope Area"}</DialogTitle>
        <div style={{ height: 700, width: 700, padding: 10 }}>
          <Scatter options={options} data={plotData} plugins={ChartAnnotation} height={null} width={null} />
        </div>
      </Dialog>
    </Fragment>
  )
}


MEnvelopeAreaPlot.propTypes = {
  datasets: PropTypes.array,
}