import React, { Fragment } from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { ComparisonProvider } from '../../context/ComparisonContext';

import { MCardLgScroll } from './MCardLgScroll.js';

/**
 * Comparison Message Component
 * @return {jsx} Message component showed when loading or empty state.
 */
const MComparisonMessage = ({ type }) => {
  const theme = useTheme();
  let message;
  let icon;
  switch (type) {
    case 'computing':
      message = '...Compiling Stats...';
      icon = <CircularProgress sx={{ fontSize: 60 }} color="secondary" />;
      break;
    case 'init':
      message = 'Select what datasets you want to compare first.';
      icon = <MdErrorOutline style={{ fontSize: 60, color: theme.palette.error.main }} />;
      break;
    default:
  }
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
      <Fragment> {icon} </Fragment>
      <Typography color="primary">{message}</Typography>
    </Box>
  );
};

MComparisonMessage.propTypes = {
  type: PropTypes.string
};

/**
 * Patient page layout wireframe
 * @return {jsx} The layout page component.
 */
export const MComparisonLayout = ({
  viewState,
  containerA,
  containerB,
  containerC,
  containerD
}) => {
  const theme = useTheme();
  return (
    <ComparisonProvider>
      <Grid container spacing={{ xs: 0, md: 2 }} sx={{ height: '100%', width: '100%' }}>
        {viewState === 'post-computation' ? (
          <Fragment>
            <Grid item xs sx={{ height: '100%' }}>
              <MCardLgScroll title="Gait Parameters">{containerA}</MCardLgScroll>
            </Grid>
            <Grid
              item
              xs={12}
              md={1.5}
              sx={{
                height: '100%',
                [theme.breakpoints.down('md')]: {
                  mt: 4
                }
              }}>
              <MCardLgScroll title="Stats">{containerB}</MCardLgScroll>
            </Grid>
          </Fragment>
        ) : (
          <Grid item xs>
            <MComparisonMessage type={viewState} />
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          md={4}
          sx={{ height: '100%', [theme.breakpoints.down('md')]: { pt: 5 } }}>
          <Grid item xs={12} sx={{ height: 'calc(100% - 90px)' }}>
            <MCardLgScroll title="Setup Comparison">{containerC}</MCardLgScroll>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2, height: 80 }}>
            {containerD}
          </Grid>
        </Grid>
      </Grid>
    </ComparisonProvider>
  );
};

MComparisonLayout.propTypes = {
  viewState: PropTypes.string,
  containerA: PropTypes.node,
  containerB: PropTypes.node,
  containerC: PropTypes.node,
  containerD: PropTypes.node
};
