import React, { Fragment, useState } from 'react';
import { IoWarning } from 'react-icons/io5';
import { MdAccountCircle, MdAssessment, MdChevronRight } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MConfirmationBox } from '../../components/ui/MConfirmationBox';
import { MTooltipIconButton } from '../../components/ui/MTooltipIconButton';
import { useGlobalContext } from '../../context/AppContext';
import { deleteEventCall, pryvApiCall } from '../../data/apiCalls';
import { colors } from '../../styles/colors';
import { makeDateString } from '../../utils/auxFunctions.js';
/**
 * Displays patients as a card.
 * @param {Object} props The component properties.
 * @return {jsx} The patient card component.
 */
export const MPatientCard = ({ patient }) => {
  const {patientAccesses, setPatientAccesses} = useGlobalContext()
  
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [openUpdateNotification, setOpenUpdateNotification] = useState(false);
  
  const history = useHistory();
  const theme = useTheme();

  const fullName = `${patient.firstName} ${patient.lastName}`;
  const birthDate = patient.birthdate.toLocaleDateString();
  
  const firstDate = patient.firstAssessmentDate ? makeDateString(patient.firstAssessmentDate): 'n/a'; 
  const lastDate = patient.lastAssessmentDate ? makeDateString(patient.lastAssessmentDate): 'n/a'; 
  
  const username = patient.username;

  const toPatientClick = () => {
    sessionStorage.setItem('patient', JSON.stringify(patient));
    sessionStorage.setItem('from_redirect', true)
    
    history.push({
      pathname: '/patient/' + username,
    });
  };

  let cardColor;
  switch (patient.trend) {
    case '↗':
      cardColor = theme.palette.success.main;
      break;
    case '↘':
      cardColor = theme.palette.error.main;
      break;
    default:
      cardColor = colors['darkGrey'];
  }


  const deleteUser = async () => {
    const index = patientAccesses.findIndex((access) => access.endpoint === patient.apiToken)
    if (index === -1) {
      setConfirmationOpen(false)
      return
    }
    const newAccessess = [... patientAccesses]
    newAccessess.splice(index, 1)
    
    const endpoint = sessionStorage.getItem('docEP')
    
    setPatientAccesses(newAccessess)
    setConfirmationOpen(false)
    pryvApiCall(endpoint, deleteEventCall(patientAccesses[index].id))
  } 

  return (
    <Fragment>
      <Card sx={{ position: 'relative' }}>
        <LinearProgress
          value={0}
          variant="determinate"
          sx={{
            backgroundColor: cardColor
          }}
        />
        <CardHeader
          avatar={<MdAccountCircle size={40} style={{ color: cardColor }} />}
          title={fullName}
          subheader={
            <Fragment>
              {username} <br />
              {birthDate}
            </Fragment>
          }
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            Last assessment: {firstDate}
            <br />
            First assessment: {lastDate}
            <br />
            Total assessments: {patient.activities.length}
            <br />
          </Typography>
        </CardContent>
        <CardActions disableSpacing sx={{ position: 'relative' }}>
          {patient.upToDate && (
            <Box sx={{ padding: 1, color: cardColor }}>
              <Icon>
                <MdAssessment />
              </Icon>
              <Icon>{patient.trend}</Icon>
            </Box>
          )}
          {!patient.upToDate && (
            <Tooltip title="Show More Info">
              <IconButton
                onClick={() => setOpenUpdateNotification(true)}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <IoWarning style={{ color: theme.palette.warning.main }} />
                <Typography sx={{ fontSize: 12, color: theme.palette.warning.main }}>
                  Req. Update
                </Typography>
              </IconButton>
            </Tooltip>
          )}
          <Button
            variant="contained"
            color="primary"
            endIcon={<MdChevronRight />}
            onClick={toPatientClick}
            sx={{ position: 'absolute', right: 30 }}
            disabled={!patient.upToDate}
            id="patient-details-button"
          >
            Details
          </Button>
        </CardActions>
        <Box display="flex" sx={{position: 'absolute', top: 5, right: 3}}>
            <MTooltipIconButton
              title="Delete User"
              onClickEvent={() => setConfirmationOpen(true)}
              buttonType="tooltipIconButton"
              buttonValue="delete"
              iconStyle={{ fontSize: 30 }}
            />
        </Box>
      </Card>
      <Dialog open={openUpdateNotification} onClose={() => setOpenUpdateNotification(false)}>
        <Box sx={{ width: 500 }}>
          <DialogTitle>Update Needed</DialogTitle>
          <Divider sx={{ marginLeft: 5, marginRight: 5 }} />
          <DialogContent sx={{ fontSize: 15 }}>
            This user is temporarily disabled as they are running on an old version of our platform.
            To regain full access to their data, do the following:
            <ol>
              <li>Ask the patient to (re-)login to their account using the App.</li>
              <li>Ask them to rescan your QR code, as your current access is deleted.</li>
              <li>
                Wait for the system to reanalyze all of the patient&apos;s activities using our new
                and improved algorithms.
              </li>
            </ol>
          </DialogContent>
        </Box>
      </Dialog>
      <MConfirmationBox
        text={
          <>
            <p>Deletion is permament, to readd them later, make sure to delete shared access on the user&apos;s app.</p>
            <b>Are you sure you want to proceed?</b>
          </>
        }
        isOpen={isConfirmationOpen}
        onCancel={() => {setConfirmationOpen(false)}}
        onConfirmation={() => {deleteUser()}}
      />
    </Fragment>
  );
};

MPatientCard.propTypes = {
  patient: PropTypes.object
};
