import React from 'react';
import { Box, Divider, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
/**
 * Layout component: large card with a scrollable middle part
 * @return {jsx} The layout card component.
 */
export const MCardLgScroll = ({ title, children }) => {
  return (
    <Grid container sx={{ height: '100%', width: '100%', position: 'relative' }}>
      <LinearProgress value={0} variant="determinate" sx={{ width: '100%' }} />
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Typography align="center" variant="overline" sx={{ fontSize: 13 }}>
            {title}
          </Typography>
          <Divider />
        </Box>
        <Box
          sx={{
            overflow: 'scroll',
            flexGrow: 1
          }}>
          {children}
        </Box>
      </Paper>
    </Grid>
  );
};

MCardLgScroll.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node
};
