import React, { Fragment, useEffect, useState } from 'react';
import { MdAccountCircle, MdClose, MdLink, MdLinkOff, MdMenu } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Drawer,
  Icon,
  IconButton,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import Pryv from 'pryv';

import { MConfirmationBox } from '../../components/ui/MConfirmationBox.js';
import { useGlobalContext } from '../../context/AppContext.js';
import { deleteEventCall, doctorDataApiCall, pryvApiCall, updateEventCall } from '../../data/apiCalls.js';
import { appBarTheme } from '../../styles/appBarTheme.js';

import { MAuthentication } from './Authentication/MAuthentication.js';
import { MAccountInfoDialog } from './MAccountInfoDialog';
import { MCopyright } from './MCopyright';
import { MMenuList } from './MMenuList';

const DRAWER_WIDTH = 240;

const Main = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  backgroundColor: appBarTheme.color,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

/**
 * Makes the "AppBar" component.
 * @return {jsx} The app bar component.
 */
export const MAppBar = ({ children }) => {
  const { setPatientAccesses, docEndpoint, setDocEndpoint, setDoctorInfoEvents } =
    useGlobalContext();

  const [isAuthOpen, setAuthOpen] = useState(false);
  const [isLogoutOpen, setLogoutOpen] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [accountInfoDialogueOpen, setAccountInfoDialogueOpen] = useState(false);

  const theme = useTheme();
  const history = useHistory();

  useEffect(() => {
    if (!docEndpoint) {
      return;
    }
    sessionStorage.setItem('docEP', docEndpoint);
    establishDbConnection(docEndpoint);
  }, [docEndpoint]);

  // Handle manual refresh
  useEffect(() => {
    const storedEndpoint = sessionStorage.getItem('docEP');
    if (storedEndpoint) {
      setDocEndpoint(storedEndpoint);
    }
  }, []);

  const dbInstance = window.sessionStorage.getItem('dbInstance');
  

  // setup marked for comparison in sessionstorage
  if (!sessionStorage.getItem('markedActivities')) {
    sessionStorage.setItem('markedActivities', JSON.stringify([]));
  }

  /**
   * establish connection to the database, which entails fetching the doc's
   info, checking all of the patient accesses. All 'results' are stored in
   corresponding React states.
   * @param {Url} apiEndpoint
   */
  const establishDbConnection = async (apiEndpoint) => {
    if (!apiEndpoint || hasLoaded) {
      return;
    }
    pryvApiCall(apiEndpoint, doctorDataApiCall)
      .then((result) => {
        // Get Doc Data & accesses to patient data
        const usernameEvent = {
          streamIds: ['.username'],
          streamId: '.username',
          content: apiEndpoint.split('@')[1].split('.')[0]
        }
        result[0].events.push(usernameEvent)
        setDoctorInfoEvents(result[0].events);
        return result[1].events;
      })
      // Check if they're still connected to "old"
      // development/staging domains and move if so
      .then((accesses) => {
        return updateAccessDomain(accesses, apiEndpoint)
      })
      // Check Api Endpoints update or delete if necessary.
      .then((accesses) => {
        return checkApiEndpoints(accesses, apiEndpoint);
      })
      .then((validEndpoints) => {
        sessionStorage.setItem('docEP', apiEndpoint);

        // Set States Accordingly
        setPatientAccesses(validEndpoints);
        setHasLoaded(true);
      })
      .catch((err) => console.error(err));
  };

  /**
   * Check Api Endpoints of a doc's patient accesses stream, by a simple fetch
   call. If this call does not succeed, the api endpoint is assumed to be
   invalid. NOTE; it currently not deleted from the doc's patient-accessess
   stream.
   * @param {Array} accessData an array of objects containing endpoint and id
  for each patient.
   * @param {String} docApiEP api endpoint to the doc's database.
   * @return {Array} of valid api endpoints.
   */
  const checkApiEndpoints = (accesses, docApiEP) => {
    const accessPointsArray = accesses.map(async (a) => {
      let checked = false;
      const connection = new Pryv.Connection(a.content);
      for (let ii = 0; ii < 3 && !checked; ii++) {
        try {
          return await connection.accessInfo().then((res) => {
            return {
              endpoint: a.content,
              userUpToDate: res.permissions.map((x) => x.streamId).includes('diagnosis'),
              id: a.id
            };
          });
        } catch (err) {
          console.error(JSON.stringify(err));
          if (err.status === 403) {
            pryvApiCall(docApiEP, deleteEventCall(a.id)).then((res) => console.warn(res));
            checked = true;
          }
        }
      }
    });



    const validTokens = Promise.allSettled(accessPointsArray).then((res) => {
      return res
        .filter((promise) => {
          return promise.value !== undefined;
        })
        .map((response) => response.value);
    });
    return validTokens;
  };

  /**
   * Per the terminance of maintenance of Pryv (31st of JAN), we moved our development
   * and staging domains. As all "old" generated accesses still point to the old domains
   * these need to be updated if necessary.
   * @param {Array} accessData an array of objects containing endpoint and id
  for each patient.
   * @param {String} docApiEP api endpoint to the doc's database.
   * @return {Array} of updated/vetted api endpoints.
  */
  const updateAccessDomain = async (accesses, docApiEP) => {
    if (dbInstance == 'master') {
      return accesses
    }

    const pryvio = 'pryv.io'    
    const trueAccesses = await accesses.map(async (access) => {
      if (!access.content.includes(pryvio)) {
        return access
      } else {
        return pryvApiCall(
          docApiEP, updateEventCall(access.id, access.content.replace(pryvio, "net"))
        ).then((res) => {
          return res[0].event
        })
      }
    })

    return Promise.allSettled(trueAccesses).then((res) => {
      return res
        .filter((promise) => {
          return promise.value !== undefined;
        })
        .map((response) => response.value);
    });
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const onLogout = () => {
    const instance = sessionStorage.getItem('dbInstance');

    setPatientAccesses([]);
    setDocEndpoint();
    setHasLoaded(false);
    sessionStorage.clear();
    sessionStorage.setItem('dbInstance', instance);

    setLogoutOpen(false);
  };

  const onLogoutCancel = () => {
    setLogoutOpen(false);
  };

  const onAuthButtonClick = () => {
    if (docEndpoint) {
      setLogoutOpen(true);
    } else {
      setAuthOpen(true);
    }
  };

  return (
    <Fragment>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <CssBaseline />
        <AppBar sx={{ backgroundColor: appBarTheme.color }} position="fixed" open={drawerOpen}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            {/* icon + Nushu home button */}
            <Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}>
                <MdMenu />
              </IconButton>
              <Button
                id="nushu-button"
                variant="outlined"
                color="secondary"
                onClick={() => history.push('/')}>
                <Typography component="h1" variant="h5">
                  <i>
                    <b>NUSHU</b>
                  </i>
                </Typography>
              </Button>
            </Box>

            {/* DB Instance information */}
            <Box
              sx={{
                display: 'none',
                flexDirection: 'column',
                alignItems: 'center',
                [theme.breakpoints.up('md')]: {
                  display: 'flex'
                }
              }}>
              <Typography
                variant="overline"
                sx={{
                  color: appBarTheme.textColor
                }}>
                {appBarTheme.text}
              </Typography>
              {!['Research', ''].includes(appBarTheme.text) && (
                <Typography
                  variant="overline"
                  sx={{
                    color: appBarTheme.textColor,
                    fontSize: 10,
                    lineHeight: 0.1
                  }}>
                  {dbInstance && `${dbInstance}-DB [${process.env.REACT_APP_CERTIFICATION}]`}
                </Typography>
              )}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Database Connection">
                <Icon sx={{ marginX: 2 }} color="inherit">
                  {docEndpoint ? (
                    <MdLink style={{ color: theme.palette.success.main }} />
                  ) : hasLoaded ? (
                    <CircularProgress color="secondary" size={24} />
                  ) : (
                    <MdLinkOff style={{ color: theme.palette.error.main }} />
                  )}
                </Icon>
              </Tooltip>

              <Button color="inherit" onClick={onAuthButtonClick} id="login-button">
                {!docEndpoint ? 'Login' : 'Logout'}
              </Button>
              {docEndpoint && hasLoaded && (
                <Fragment>
                  <IconButton onClick={() => setAccountInfoDialogueOpen(true)}>
                    <MdAccountCircle style={{ color: theme.palette.common.white }} />
                  </IconButton>
                  <MAccountInfoDialog
                    isOpen={accountInfoDialogueOpen}
                    onClose={() => setAccountInfoDialogueOpen(false)}
                  />
                </Fragment>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: DRAWER_WIDTH,
              boxSizing: 'border-box'
            }
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <MdClose /> : <MdMenu />}
            </IconButton>
          </DrawerHeader>
          <MMenuList />

          {drawerOpen && (
            <Box sx={{ position: 'fixed', bottom: 0, width: 'inherit', mb: 5 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingBottom: '2rem',
                  [theme.breakpoints.up('md')]: {
                    display: 'none'
                  }
                }}>
                <Typography
                  variant="overline"
                  sx={{
                    color: 'black'
                  }}>
                  {appBarTheme.text}
                </Typography>
                {!['Staging Build', ''].includes(appBarTheme.text) && (
                  <Typography
                    variant="overline"
                    sx={{
                      color: 'black',
                      fontSize: 10,
                      lineHeight: 0.1
                    }}>
                    {dbInstance && `${dbInstance}-DB [${process.env.REACT_APP_CERTIFICATION}]`}
                  </Typography>
                )}
              </Box>
              <MCopyright />
            </Box>
          )}
        </Drawer>
        <Main open={drawerOpen}>
          <DrawerHeader />
          {children}
        </Main>
      </Box>
      <MConfirmationBox
        text="Are you sure you want to logout?"
        isOpen={isLogoutOpen}
        onConfirmation={onLogout}
        onCancel={onLogoutCancel}
      />
      <MAuthentication isOpen={isAuthOpen} setOpen={setAuthOpen} />
    </Fragment>
  );
};

MAppBar.propTypes = {
  children: PropTypes.node
};
