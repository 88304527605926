import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MCardLgScroll } from './MCardLgScroll.js';

/**
 * Patient page layout wireframe
 * @return {jsx} The layout page component.
 */
export const MPatientLayout = ({
  username,
  patientName,
  containerA,
  containerB,
  containerC,
  containerD,
  containerE
}) => {
  const theme = useTheme();
  return (
    <Grid container spacing={{ sx: 0, md: 2 }} sx={{ height: '100%', width: '100%' }}>
      <Grid item xs sx={{ height: '100%' }}>
        <MCardLgScroll title={'Gait Progression'}>{containerA}</MCardLgScroll>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={2.5}
        sx={{
          height: '100%',
          [theme.breakpoints.down('md')]: {
            mt: 10
          }
        }}>
        <Grid item xs={12} sx={{ height: 300 }}>
          <MCardLgScroll title={'Summary Stats'}>{containerB}</MCardLgScroll>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: 2,
            height: 'calc(100% - 314px)'
          }}>
          <MCardLgScroll title={'Timeline'}>{containerC}</MCardLgScroll>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={3.5}
        sx={{
          height: '100%',
          [theme.breakpoints.down('md')]: {
            mt: 3
          }
        }}>
        <Grid item xs={12} sx={{ height: 'calc(100% - 90px)' }}>
          <MCardLgScroll
            title={
              <Stack spacing={-1.5}>
                <Typography variant="overline" sx={{ fontSize: 10 }}>
                  {username}
                </Typography>
                <Typography variant="overline" sx={{ fontSize: 13 }}>
                  {patientName}
                </Typography>
              </Stack>
            }>
            {containerD}
          </MCardLgScroll>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 2, height: 80 }}>
          {containerE}
        </Grid>
      </Grid>
    </Grid>
  );
};

MPatientLayout.propTypes = {
  username: PropTypes.string,
  patientName: PropTypes.string,
  containerA: PropTypes.node,
  containerB: PropTypes.node,
  containerC: PropTypes.node,
  containerD: PropTypes.node,
  containerE: PropTypes.node
};
