import React, {Fragment, useEffect, useState} from 'react';
import { Autocomplete, Box, Button, CircularProgress, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

import { getRegisteredAffiliations } from '../../../data/apiCalls';
import doctorSetupCalls from '../../../definitions/doctorSetupCalls.json'
import { generateUsername } from '../../../utils/auxFunctions';


/**
 * Renders the "MRegisterForm" component.
 * @return {jsx} The about page component.
 */
export const MRegisterForm = ({domain, backToInitialView}) => {
  const [isLoading, setLoading] = useState(false)
  const [hasFinished, setFinished] = useState(false)
  const [affiliations, setAffiliations] = useState('Loading')

  const instance = window.sessionStorage.getItem('dbInstance')
  useEffect( () => {
    getRegisteredAffiliations(instance).then((res) => {
      if (res !== undefined) {
        setAffiliations(res)
      }
    })
  }, [])

  const fields = { 
    firstName: {
      label: 'First Name',
      required: true,
      regex: /[A-Za-z]$/g,
      width: 6,
    },
    lastName: {
      label: 'Last Name',
      required: true,
      regex: /[A-Za-z]$/g,
      width: 6,
    },
    affiliation: {
      label: 'Affiliation',
      required: true,
      regex: /./,
      width: 12,
    },
    street: {
      label: 'Street',
      required: false,
      regex: /^[a-zA-Z0-9.\-_./_ _]+$/g,
      width: 6,
    },
    city: {
      label: 'City',
      required: false,
      regex: /[A-Za-z]$/g,
      width: 6,
    },
    zip: {
      label: 'ZIP',
      required: false,
      regex: /^[0-9]*$/,
      width: 6,
    },
    country: {
      label: 'Country',
      required: false,
      regex: /[A-Za-z]$/g,
      width: 6,
    },
    phone: {
      label: 'Phone No.',
      required: false,
      regex: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g,
      width: 12,
    },
    email: {
      label: 'Email',
      required: true,
      regex: /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
      width: 12,
    },
    password: {
      label: 'Password',
      required: true,
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      width: 12,
    },
    confirmPassword: {
      label: 'Password Confirmation',
      required: true,
      regex: /./,
      width: 12,
    },
  }

  for (const item of Object.values(fields)) {
    const [state, setState] = useState('')
    item.value = state
    item.setValue = setState

    const [error, setError] = useState('')
    item.error = error
    item.setError = setError

    item.onChange = (event) => {
      item.setValue(event.target.value)

      if (event.target.value === '') {
        item.setError('')
        return
      }

      // Val def needed otherwise regex.test fails
      const val = event.target.value
      if (item.regex.test(val)) {
        item.setError('')
      } else {
        switch (item.label) {
          case 'Password': {
            item.setError('Min. 8 characters with at least one upper & lower case letter and one number.')
            break
          }
          default: {
            item.setError(`Entered ${item.label} invalid.`)
            break
          }
        }
      }
    }
  }

  // Check passwords matching
  useEffect( () => {
    if (fields.confirmPassword.value === '') {
      return
    }
    if (fields.password.value !== fields.confirmPassword.value) {
      fields.confirmPassword.setError('Passwords must match')
    }
  }, [fields.password.value, fields.confirmPassword.value])


  const noErrors = Object.values(fields).every( (field) => !field.error )
  const allRequiredFields = Object.values(fields).filter( (field) => field.required).every(reqField => reqField.value)
  const isReadyForSubmission = (noErrors && allRequiredFields)

  const getAvailableUsername = async () => {
    const username = generateUsername()
    const usernameRequest = await fetch(`https://reg.${domain}/${username}/check_username`)
    const usernameResponse = await usernameRequest.json()
    
    if (usernameResponse.reserved) {
      getAvailableUsername()
    } else {
      return username
    }
  }

  const checkUniqueEmail = async () => {
    const email = fields.email.value
    const emailCheckRequest = await fetch(`https://reg.${domain}/${email}/check_email`)
    const emailCheckResponse = await emailCheckRequest.json()
    if (emailCheckResponse.exists) {
      fields.email.setError('A user with this email is already registered.')
      setLoading(false)
      return false
    } else {
      return true
    }


  }
  const onRegisterClick = async () => {
    setLoading(true)
    
    if (!checkUniqueEmail()) {
      setLoading(false)
      return
    }
    
    const username = await getAvailableUsername()
    const affiliationShorthand = Object.keys(affiliations).filter( k => affiliations[k].name === fields.affiliation.value)[0]

    const createPayload = {
      appId: 'nushu-dashboard',
      email: fields.email.value,
      username: username,
      password: fields.password.value,
      affiliation: affiliationShorthand,
    }
    const createRequest = await fetch(
      `https://co1.${domain}/users`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(createPayload)
      }
    )

    if (createRequest.status >= 400) {
      setLoading(false)
      return
    }
    const createResponse = await createRequest.json()

    // Setup Calls
    const writeInfoBatchCall = [
      {
        method: 'events.create',
        params: {
          streamIds: ['name'],
          type: 'contact/magnes-v1',
          content: {
            'first-name': fields.firstName.value.trim(),
            'last-name': fields.lastName.value.trim(),
          }
        }
      },
      {
        method: 'events.create',
        params: {
          streamIds: ['address'],
          type: 'address/magnes-v1',
          content: {
            'street': fields.street.value.trim(),
            'town': fields.city.value.trim(),
            'zip': fields.zip.value ? Number(fields.zip.value.trim()) : -1,
            'country': fields.country.value.trim()
          }
        }
      },
      {
        method: 'events.create',
        params: {
          streamIds: ['phone'],
          type: 'call/telephone',
          content: fields.phone.value.trim()
        }
      }
    ]

    const setupCalls = doctorSetupCalls.concat(writeInfoBatchCall)

    const token = createResponse.apiEndpoint
      .split('@')[0]
      .split('//')[1]
  
    await fetch(
      `https://${username}.${domain}/`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Authorization': token
        },
        body: JSON.stringify(setupCalls)
      }
    )

    setLoading(false)
    setFinished(true)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      <Grid container spacing={0}>
        {Object.values(fields).map( (field) => (
          <Grid item xs={field.width} key={field.label}>
            {(() => {
                switch(field.label) {
                  case 'Affiliation': {
                    return (
                      <Fragment>
                        {(affiliations === 'Loading') && (
                          <LinearProgress color="inherit" sx={{ height: '1px', width: '100%'}} />
                        )}
                        <Autocomplete
                          disablePortal
                          disabled={hasFinished || affiliations === 'Loading'}
                          onChange={(_event, value) => {
                            const mockEvent = {
                              target: {
                                value: value
                              }
                            }
                            field.onChange(mockEvent)
                          }}
                          id="affiliation"
                          options={Object.values(affiliations).map( (affiliation) => affiliation.name)}
                          sx={{ width: '100%'}}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={field.required}
                              label={field.label}
                              value={field.value}
                              variant="filled"
                              helperText={'\u00a0'}
                            />
                          )}
                        />
                      </Fragment>
                    )
                  }
                  default: {
                    return (
                      <TextField
                        disabled={hasFinished}
                        required={field.required}
                        onChange={field.onChange}
                        error={Boolean(field.error)}
                        helperText={field.error ? field.error : '\u00a0'}
                        value={field.value}
                        label={field.label}
                        variant="filled"
                        type={field.label.includes('Password') ? 'password' : 'text'}
                        sx={{ width: '100%' }}
                      />
                    )
                  }
                }
            })()}
          </Grid>
        ))
        }
      </Grid>
      <Button
        disabled={!(isReadyForSubmission || hasFinished)}
        variant="contained"
        onClick={hasFinished ? backToInitialView : onRegisterClick}
        color={hasFinished ? 'success' : 'primary'}
        sx={{ width: '100%', height: '100%', marginTop: 2 }}>
          {( () => {
            if (isLoading) {
              return <CircularProgress color="secondary" size={20}/>
            }
            if (hasFinished) {
              return <Typography color="white"> Login </Typography>
            }
            return <>Register</>
          })()}
      </Button>
    </Box>
  );
};

MRegisterForm.propTypes = {
  domain: PropTypes.string,
  backToInitialView: PropTypes.func,
};
