import { FaNotesMedical } from 'react-icons/fa';
import { GiFalling, GiMedicines } from 'react-icons/gi';
import {
  MdAssignmentInd,
  MdComment,
  MdDirectionsWalk,
  MdForum,
  MdHealing,
  MdReport
} from 'react-icons/md';

export const timelineDefs = {
  Registration: {
    icon: MdAssignmentInd
  },
  'First Assessment': {
    icon: MdDirectionsWalk
  },
  'New Diagnosis': {
    icon: FaNotesMedical
  },
  Appointment: {
    icon: MdForum
  },
  Accident: {
    icon: MdReport
  },
  'Medication Adjustment': {
    icon: GiMedicines
  },
  Surgery: {
    icon: MdHealing
  },
  Fall: {
    icon: GiFalling
  },
  Comment: {
    icon: MdComment
  }
};
