import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { PropTypes } from 'prop-types';

/**
 * Dialog with functionality of deleting timeline entry
 * @return {jsx} Dialog with functionality of deleting timeline entry.
 */
export const MDeleteTimelineEntryDialog = ({ isOpen, handleSetDeletionDialog, deleteEntry }) => {
  return (
    <Dialog open={isOpen}>
      <Box sx={{ padding: 2 }}>
        <DialogTitle>
          <Typography align="center">Warning</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ margin: 2 }}>
          Are you sure you want to delete the timeline entry?
        </DialogContent>
        <Divider />
        <DialogActions sx={{ marginTop: 2 }}>
          <Button
            onClick={() => handleSetDeletionDialog({ open: false, id: undefined })}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={() => deleteEntry()} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

MDeleteTimelineEntryDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleSetDeletionDialog: PropTypes.func,
  deleteEntry: PropTypes.func
};
