import React, { createContext, useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [patientAccesses, setPatientAccesses] = useState([]);
  const [docEndpoint, setDocEndpoint] = useState();
  const [doctorInfoEvents, setDoctorInfoEvents] = useState(undefined);
  const [markedActivities, setMarkedActivities] = useState(new Set());

  return (
    <AppContext.Provider
      value={{
        patientAccesses,
        setPatientAccesses,
        docEndpoint,
        setDocEndpoint,
        doctorInfoEvents,
        setDoctorInfoEvents,
        markedActivities,
        setMarkedActivities
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };

AppProvider.propTypes = {
  children: PropTypes.node
};
