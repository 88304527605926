import React from 'react';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { makeDateString } from '../../../utils/auxFunctions.js';

/**
 * Shows detailed info about a patient.
 * @param {Object} props containing the patient profile data.
 * @return {jsx} The patient info component.
 */
export const MPatientInfo = ({ patient }) => {
  let diagnosis;
  if (typeof patient.diagnosis === 'object') {
    if (patient.diagnosis.content.length === 0) {
      diagnosis = ['none'];
    } else {
      diagnosis = patient.diagnosis.content.map((condition) => {
        if (condition.includes('Other')) {
          return condition;
        } else {
          return condition.split('-')[1];
        }
      });
    }
  } else {
    diagnosis = ['none'];
  }

  const tableItems = [
    {
      label: 'Condition',
      value: diagnosis.join('\n')
    },
    {
      label: 'Date of Birth',
      value: makeDateString(patient.birthdate)
    },
    {
      label: 'Height',
      value: `${patient.height} cm`
    },
    {
      label: 'Weight',
      value: `${patient.weight} kg`
    },
    {
      label: 'Shoe Size',
      value: patient.shoeSize === -1 ? `n/a` : `${patient.shoeSize} (EU)`
    }
  ];
  return (
    <Table size="small" sx={{ marginBottom: 3 }}>
      <TableBody>
        {tableItems.map((item) => (
          <TableRow key={uuidv4()}>
            <TableCell align="left" sx={{ borderBottom: 'none' }}>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                {item.label}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={{ borderBottom: 'none' }}>
              <Box
                sx={{
                  maxHeight: 65,
                  overflow: 'scroll'
                }}>
                <Typography variant="body2" sx={{ fontSize: 12, whiteSpace: 'pre-line' }}>
                  {item.value}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

MPatientInfo.propTypes = {
  patient: PropTypes.object
};
