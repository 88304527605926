import { sumStatistics } from '../utils/mathUtils.js';

const referenceRanges = {
  'gait velocity': {
    metric: 'Gait Velocity',
    units: 'cm/s',
    note: 'N/A',
    male: {
      avg: [119.6, 135.4, 133.5, 131.2, 133.5, 125.2, 123.8, 124.7, 110.5],
      std: [21.0, 16.7, 14.5, 12.8, 16.7, 14.8, 13.1, 17.3, 20.9]
    },
    female: {
      avg: [122.0, 134.2, 137.1, 131.4, 133.8, 130.3, 124.4, 117.3, 109.5],
      std: [20.5, 14.3, 14.5, 14.2, 14.7, 14.5, 17.1, 22.7, 23.0]
    }
  },
  length: {
    metric: 'Stride Length',
    units: 'cm',
    note: 'N/A',
    male: {
      avg: [99.5, 141.8, 142.2, 140.8, 143.4, 134.7, 133.3, 132.3, 121.3],
      std: [18.4, 13.7, 12.4, 12.4, 14.3, 14.3, 12.2, 12.4, 18.0]
    },
    female: {
      avg: [99.9, 134.0, 137.5, 132.4, 134.9, 132.8, 126.3, 122.3, 113.6],
      std: [18.9, 12.2, 11.0, 11.0, 11.9, 12.1, 12.2, 13.6, 16.3]
    }
  },
  duration: {
    metric: 'Stride Duration',
    units: 's',
    note: 'N/A',
    male: {
      avg: [0.84, 1.06, 1.07, 1.08, 1.07, 1.08, 1.08, 1.08, 1.1],
      std: [0.11, 0.1, 0.08, 0.07, 0.08, 0.08, 0.09, 0.1, 0.1]
    },
    female: {
      avg: [0.83, 1.01, 1.0, 1.02, 1.0, 1.01, 1.01, 1.04, 1.06],
      std: [0.14, 0.07, 0.06, 0.07, 0.07, 0.09, 0.09, 0.09, 0.11]
    }
  },
  velocity: {
    metric: 'N/A',
    units: 'cm/s',
    note: 'computed by Stride Length / Stride Duration [considered independent]',
    male: {
      avg: [118.5, 133.8, 132.9, 130.4, 134.0, 124.7, 123.4, 122.5, 110.3],
      std: [26.84, 18.06, 15.27, 14.26, 16.7, 16.15, 15.28, 16.14, 19.19]
    },
    female: {
      avg: [120.4, 132.7, 137.5, 129.8, 134.9, 131.5, 125.0, 117.6, 107.2],
      std: [30.51, 15.18, 13.75, 13.99, 15.19, 16.76, 16.43, 16.57, 18.98]
    }
  },
  swing_time: {
    metric: 'Swing Time',
    units: 's',
    note: 'N/A',
    male: {
      avg: [0.35, 0.42, 0.4, 0.41, 0.41, 0.41, 0.41, 0.41, 0.41],
      std: [0.05, 0.04, 0.04, 0.03, 0.03, 0.03, 0.04, 0.04, 0.04]
    },
    female: {
      avg: [0.34, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.39],
      std: [0.06, 0.02, 0.02, 0.02, 0.01, 0.03, 0.03, 0.03, 0.02]
    }
  },
  stance_time: {
    metric: 'Stance Time',
    units: 's',
    note: 'N/A',
    male: {
      avg: [0.49, 0.62, 0.65, 0.66, 0.65, 0.67, 0.66, 0.67, 0.69],
      std: [0.07, 0.07, 0.06, 0.09, 0.06, 0.06, 0.07, 0.07, 0.1]
    },
    female: {
      avg: [0.49, 0.6, 0.6, 0.61, 0.6, 0.62, 0.62, 0.64, 0.67],
      std: [0.08, 0.05, 0.04, 0.05, 0.06, 0.07, 0.07, 0.08, 0.09]
    }
  },
  swing_stance_ratio: {
    metric: 'N/A',
    units: 's',
    note: 'computed from swing',
    male: {
      avg: [0.71, 0.68, 0.62, 0.62, 0.63, 0.61, 0.62, 0.61, 0.59],
      std: [0.14, 0.1, 0.08, 0.1, 0.07, 0.07, 0.09, 0.09, 0.1]
    },
    female: {
      avg: [0.69, 0.67, 0.67, 0.66, 0.67, 0.65, 0.65, 0.62, 0.58],
      std: [0.17, 0.06, 0.06, 0.06, 0.07, 0.09, 0.09, 0.09, 0.08]
    }
  },
  cadence: {
    metric: 'Cadence',
    units: 'steps/min',
    note: '',
    male: {
      avg: [145.9, 115.5, 113.3, 112.4, 112.1, 112.2, 112.3, 114.0, 109.5],
      std: [18.8, 10.4, 8.4, 8.1, 8.1, 7.6, 7.9, 10.3, 10.7]
    },
    female: {
      avg: [149.9, 120.6, 120.5, 119.5, 119.6, 118.2, 118.4, 115.5, 115.4],
      std: [26.0, 8.0, 8.4, 7.8, 7.5, 7.9, 9.7, 12.4, 12.2]
    }
  },
  source: {
    author:
      'Marnee J. McKay, Jennifer N. Baldwin, Paulo Ferreira, Milena Simic, Natalie Vanicek, Elizabeth Wojciechowski, Anita Mudge, Joshua Burns',
    title:
      'Spatiotemporal and plantar pressure gait patterns of 1000 healthy individuals aged 3-101 years',
    journal: 'Gait and Posture',
    volume: '58',
    pages: '78- 87',
    year: '2017'
  }
};

export const getReferenceRange = (parameter, sex, age) => {
  if (parameter.includes('angle') || parameter.includes('heel')) {
    return;
  }

  let ageIDX = Math.floor(age / 10);
  if (ageIDX > 8) age = 8;

  let uc = 1;
  if (parameter.includes('length') || parameter.includes('velocity')) {
    uc = 0.01;
  }

  let stats;
  if (sex !== 'male' && sex !== 'female') {
    stats = sumStatistics([
      {
        avg: uc * referenceRanges[parameter]['male']['avg'][ageIDX],
        std: uc * referenceRanges[parameter]['male']['std'][ageIDX]
      },
      {
        avg: uc * referenceRanges[parameter]['female']['avg'][ageIDX],
        std: uc * referenceRanges[parameter]['female']['std'][ageIDX]
      }
    ]);
  } else {
    stats = {
      avg: uc * referenceRanges[parameter][sex]['avg'][ageIDX],
      std: uc * referenceRanges[parameter][sex]['std'][ageIDX]
    };
  }
  return [stats.avg - 1 * stats.std, stats.avg + 1 * stats.std, stats.std];
};
