export const vibrationTypes = [
  {
    id: 'hs-ho-feedback',
    shorthand: 'HH',
    title: 'HS-HO-Vibration'
  },
  {
    id: 'metronome',
    shorthand: 'M',
    title: 'Metronome Vibration'
  },
  {
    id: 'swing-vibrate',
    shorthand: 'S',
    title: 'Swing Vibration'
  },
  {
    id: 'ground-vibrate',
    shorthand: 'G',
    title: 'Ground Vibration'
  },
  {
    id: 'heel-strike-vibrate',
    shorthand: 'HS',
    title: 'Heel Strike Vibration'
  },
  {
    id: 'heel-off-vibrate',
    shorthand: 'HO',
    title: 'Heel Off Vibration'
  },
  {
    id: 'pink-metronome',
    shorthand: 'PM',
    title: 'Pink Metronome Vibration'
  },
  {
    id: 'load-vibrate',
    shorthand: 'L',
    title: 'Load Vibration'
  },
  {
    id: 'unload-vibrate',
    shorthand: 'U',
    title: 'Unload Vibrate'
  },

  {
    id: 'adaptive-metronome',
    shorthand: 'AM',
    title: 'Adaptive Metronome Vibration'
  },
  {
    id: 'circumduction-vibrate',
    shorthand: 'C',
    title: 'Cirumduction Vibration'
  },
  {
    id: 'swing-plus-vibrate',
    shorthand: 'SP',
    title: 'Swing+ Vibration'
  },
];

export const vibId2TitleMap = (iid) => {
  return vibrationTypes.filter((at) => at.id === iid)[0].title;
};
