import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';

import { colors } from '../../styles/colors.js';

const SQUARE_SIZE = 30;

const getTriangleColor = (side) => ({
  content: '""',
  display: 'block',
  position: 'absolute',
  top: 0,
  right: 0,
  width: '30px',
  height: '30px',
  border: '15px solid transparent',
  borderTopColor: side,
  borderRightColor: side
});

const useStyles = makeStyles(() =>
  createStyles({
    mPlotLabelsLeft: {
      position: 'relative',
      '&::before': {
        ...getTriangleColor(colors['lightBlue'])
      }
    },
    mPlotLabelsRight: {
      position: 'relative',
      '&::before': {
        ...getTriangleColor(colors['lightBlue2'])
      }
    }
  })
);

/**
 * Plot Labels
 * @return {jsx} Plot Labels component.
 */
export const MPlotLabels = ({ plotLabelType, colorLeft, colorRight }) => {
  const classes = useStyles();
  return (
    <Paper sx={{ display: 'flex', height: '100%' }}>
      <Grid container align="center">
        <Grid item xs={12}>
          <Typography variant="overline" align="center">
            Plot Labels
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              backgroundColor: colorLeft,
              height: SQUARE_SIZE,
              width: SQUARE_SIZE
            }}
            className={plotLabelType === 'divided' && classes.mPlotLabelsLeft}
          />
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              backgroundColor: colorRight,
              height: SQUARE_SIZE,
              width: SQUARE_SIZE
            }}
            className={plotLabelType === 'divided' && classes.mPlotLabelsRight}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography align="center" variant="body2" sx={{ fontSize: 12 }}>
            Left
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center" variant="body2" sx={{ fontSize: 12 }}>
            Right
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

MPlotLabels.propTypes = {
  plotLabelType: PropTypes.string,
  colorLeft: PropTypes.string,
  colorRight: PropTypes.string
};
