export const trendTypes = [
  'duration',
  'distance',
  'assessments',
  'steps',
  'stride-duration',
  'stride-velocity',
  'stride-length',
  'stride-swing-stance-ratio',
  'stride-supination-angle',
  'stride-strike-angle',
  'stride-heel-clearance'
];


const totals = [
  'assessments',
  'steps',
  'distance',
  'duration'
]

export const totalsTypes = totals.map( (tt) => `total-${tt}`)

export const scoreTypes = [
  'endurance',
  'symmetry',
  'activity',
  'consistency',
]
