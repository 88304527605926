import React, { Fragment, useEffect, useState } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import { FaQrcode } from 'react-icons/fa';
import { Box, Button, ButtonGroup, Fab, Grid, Paper, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';

import { MTooltipIconButton } from '../../components/ui/MTooltipIconButton';
import { useGlobalContext } from '../../context/AppContext.js';
import {
  allActivitiesMetadataCall,
  generalPatientDataApiCall,
  pryvApiCall
} from '../../data/apiCalls.js';
import { makeDateString } from '../../utils/auxFunctions.js';
import {
  computePatientTrend,
  extractActivity,
  extractGeneralPatData,
  getDateDayDifference
} from '../../utils/dataFetchFunctions.js';

import { MPatientCard } from './MPatientCard';
import { MPatientsTable } from './MPatientsTable';
import { MQrCodeDialog } from './MQrCodeDialog.js';
import { MSortButton } from './MSortButton';

if (getCookieConsentValue()) {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID, {
    testMode: process.env.NODE_ENV === 'test'
  });
}

const useStyles = makeStyles((theme) => {
  return {
    defaultSigninText: {
      fontSize: 'large',
      color: 'primary',
      variant: 'h6'
    },
    alignContainer: {
      display: 'flex'
    },
    searchBar: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'auto'
      },
      '& .MuiInputBase-root': {
        [theme.breakpoints.up('md')]: {
          marginLeft: 5
        }
      }
    }
  };
});

/**
 * Renders the "Home" page. Marks the entry point of the web app.
 * @param {Object} props with api tokens to patient data
 * @return {jsx} The "Home" page component.
 */
export const Home = () => {
  const { patientAccesses, docEndpoint } = useGlobalContext();

  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [patientsList, setPatientsList] = useState([]);
  const [hiddenIdxs, setHiddenIdxs] = useState([]);
  const [view, setView] = useState('card');

  const classes = useStyles();
  const theme = useTheme();

  const isLoggedIn = Boolean(docEndpoint);

  // Fetch from database
  useEffect(() => {
    if (!isLoggedIn) return;
    setLoading(true);
    Promise.all(
      patientAccesses.map(async (access, idx) => {
        const infoEvents = await pryvApiCall(access.endpoint, generalPatientDataApiCall)
        
        const patient = {
          ...extractGeneralPatData(infoEvents),
          id: idx,
          apiToken: access.endpoint,
          username: access.endpoint.split('@')[1].split('.')[0],
          upToDate: access.userUpToDate,
          daysSinceLast: null,
          lastAssessmentDate: null,
          firstAssessmentDate: null,
          trend: '=',
          activities: [],
          macroEvents: []
        };

        try {
          const metaEventsResponse = await pryvApiCall(patient.apiToken, allActivitiesMetadataCall);

          if (metaEventsResponse.length > 0) {
            const metaEvents = metaEventsResponse[0].events;

            if (metaEvents.length > 0) {
              patient.activities = metaEvents.map((me) => extractActivity(me));
              patient.daysSinceLast = getDateDayDifference(
                new Date(patient.activities[0].time),
                new Date()
              );
              patient.trend = computePatientTrend(patient.activities);
              patient.firstAssessmentDate = new Date(patient.activities[0].time);
              patient.lastAssessmentDate = makeDateString(
                new Date(patient.activities.slice(-1)[0].time)
              );
            }
          }
        } catch (error) {
          console.error(error);
        }
        return patient;
      })
    ).then((patients) => {
      patients.sort((a, b) => {
        if (a.daysSinceLast === null) return 1;
        if (b.daysSinceLast === null) return 1;
        return a.daysSinceLast - b.daysSinceLast;
      });
      setPatientsList(patients);
      setLoading(false);
    });
  }, [patientAccesses, isLoggedIn]);

  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Home Page' });
  }, []);

  const toDoText = () => {
    const result = { l1: '', l2: '' };
    if (isLoggedIn) {
      result.l1 = "You don't seem to have any access to patients data currently.";
      result.l2 = 'To get access, share the QR code found when pressing the button below.';
    } else {
      result.l1 = 'Welcome to NUSHU, glad to have you here.';
      result.l2 = 'Log in to continue.';
    }
    return result;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewChange = (event) => {
    setView(event.currentTarget.value);
  };

  const sortOptions = ['Last Name', 'Age', 'Days since last', 'Total'];

  const reorderList = (flip, newSortMode) => {
    let newList = [...patientsList];
    if (flip) {
      setPatientsList(newList.reverse());
      return;
    }

    if (newSortMode !== null) {
      newList.sort(function (a, b) {
        switch (newSortMode) {
          case 0:
            return a.lastName > b.lastName ? 1 : -1;
          case 1:
            return a.birthdate > b.birthdate ? 1 : -1;
          case 2:
            if (a.daysSinceLast === null) return 1;
            if (b.daysSinceLast === null) return 1;
            return a.daysSinceLast - b.daysSinceLast;
          case 3:
            return a.activities.length > b.activities.length ? -1 : 1;
          default:
            return null;
        }
      });
      setPatientsList(newList);
      return;
    }
  };

  const handleSearchInput = () => {
    let inputString = document.getElementById('Search field').value;
    inputString = inputString.toLowerCase().trim();

    const idxToHide = [];
    patientsList.forEach((patient, idx) => {
      const fullName = `${patient.firstName.toLowerCase()} ${patient.lastName.toLowerCase()}`;

      const searchInName = fullName.includes(inputString);
      const searchInUsername = patient.username.includes(inputString);
      const searchInBd = patient.birthdate.toLocaleDateString().includes(inputString);

      if (!(searchInName || searchInBd || searchInUsername)) {
        idxToHide.push(idx);
      }
    });

    setHiddenIdxs(idxToHide);
  };

  if (!isLoggedIn || isLoading || patientsList.length === 0) {
    return (
      <Fragment>
        <Box
          align="center"
          sx={{
            position: 'absolute',
            left: '50%',
            top: '40%',
            transform: 'translate(-50%, -50%)'
          }}>
          {isLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Box id="welcome-text-box">
              <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                {toDoText().l1} <br /> {toDoText().l2}
              </Typography>
              {isLoggedIn && (
                <Box
                  align="center"
                  sx={{
                    position: 'relative',
                    top: 2.5,
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <Fab
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                    }}>
                    <FaQrcode
                      sx={{
                        color: theme.palette.secondary.main,
                        fontSize: 25
                      }}
                    />
                  </Fab>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <MQrCodeDialog isOpen={open} onClose={handleClose} />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              alignItems: 'start'
            }
          }}
          marginBottom={2}>
          <ButtonGroup color="primary" sx={{ backgroundColor: theme.palette.common.white }}>
            <MTooltipIconButton
              buttonValue="list"
              title="Show list"
              value="list"
              onClickEvent={handleViewChange}
              variant={view === 'list'}
            />
            <MTooltipIconButton
              buttonValue="viewModule"
              title="Show cards"
              value="card"
              onClickEvent={handleViewChange}
              variant={view === 'card'}
            />
          </ButtonGroup>
          <TextField
            className={classes.searchBar}
            id="Search field"
            label="Search User"
            variant="outlined"
            onChange={handleSearchInput}
            sx={{ [theme.breakpoints.down('md')]: { my: 1 } }}
          />
          {view === 'card' && <MSortButton onChange={reorderList} options={sortOptions} />}
          <Button
            sx={{
              marginLeft: 'auto',
              width: 'auto',
              [theme.breakpoints.down('md')]: { width: '100%', mt: 1 }
            }}
            color="primary"
            endIcon={<FaQrcode />}
            onClick={handleClickOpen}
            variant="contained"
            id="add-patient-button">
            Add patient
          </Button>
        </Box>
        {view === 'card' && (
          <Grid container spacing={3}>
            {patientsList
              .filter((_, idx) => !hiddenIdxs.includes(idx))
              .map((patient) => (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={2.3} key={uuidv4()}>
                  <MPatientCard patient={patient} />
                </Grid>
              ))}
          </Grid>
        )}
        {view === 'list' && (
          <Paper>
            <MPatientsTable
              patientsList={patientsList.filter((_, idx) => !hiddenIdxs.includes(idx))}
            />
          </Paper>
        )}
        <MQrCodeDialog isOpen={open} onClose={handleClose} />
      </Fragment>
    );
  }
};
