import React from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { MdMoreVert } from 'react-icons/md';
import { Box, Card, CardContent, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

import { colors } from '../../../styles/colors.js';

import { MAddTimelineEntryDialog } from './MAddTimelineEntryDialog';

const BUTTON_STYLE = {
  position: 'absolute',
  top: 1,
  right: 7,
  '&:hover': {
    backgroundColor: colors['transparent']
  }
};

/**
 * Displays patients as a card.
 * @param {Object} props The component properties.
 * @return {jsx} The patient card component.
 */
export const MTimelineCard = ({ entry, handleDeleteEntry, handleEditEntry }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const isEditable = entry.type !== 'Registration' && entry.type !== 'First Assessment';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    handleDeleteEntry(entry.id);
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setOpen(true);
  };

  const editEntry = (entry) => {
    handleEditEntry(entry);
    setOpen(false);
  };

  return (
    <Card sx={{ overflow: 'scroll' }}>
      <CardContent sx={{ maxHeight: 100, padding: 1.5, minWidth: 155 }}>
        <Typography align="left" color="secondary" sx={{ fontSize: 13 }}>
          {entry.type}
        </Typography>
        <Box sx={{ maxHeight: 100, overflow: 'scroll' }}>
          <Typography align="left" variant="body2" color="primary" sx={{ fontSize: 11 }}>
            {entry.content}
          </Typography>
        </Box>
      </CardContent>
      {isEditable && (
        <IconButton sx={{ ...BUTTON_STYLE }} onClick={handleClick} size="small">
          <MdMoreVert size={20} />
        </IconButton>
      )}
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem sx={{ display: 'inline' }} onClick={handleClose}>
          <IconButton onClick={handleEditClick} sx={{ fontSize: 20 }} size="small">
            <FaEdit />
          </IconButton>
        </MenuItem>
        <MenuItem sx={{ display: 'inline' }}>
          <IconButton onClick={handleDeleteClick} size="small">
            <FaTrashAlt style={{ fontSize: 20 }} />
          </IconButton>
        </MenuItem>
      </Menu>
      <MAddTimelineEntryDialog
        entry={entry}
        isOpen={open}
        onSubmit={editEntry}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </Card>
  );
};

MTimelineCard.propTypes = {
  entry: PropTypes.any,
  handleDeleteEntry: PropTypes.func,
  handleEditEntry: PropTypes.func
};
