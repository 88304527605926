import React from 'react';
import { Bar } from 'react-chartjs-2';
import { VscCombine } from "react-icons/vsc";
import { Box } from '@mui/material';
import { PropTypes } from 'prop-types';

import { MStatisticsCard } from '../../../components/ui/MStatisticsCard';
import { colors } from '../../../styles/colors';
import theme from '../../../styles/theme';

import { PARAMETER_CFG } from './MRTConfig';

const SIDE_COLOR = {
  "L": theme.palette.info.main,
  "R": theme.palette.info.light,
}

export const MTRCombinatorial = ({ results }) => {
  const reactionEvents = []
  for (const side of ["L", "R"]) {
    for (const reactionType of ["invalid_reactions", "valid_reactions"]) {
      reactionEvents.push(...results[side][reactionType].map(event => {
        event.side = side
        return event
      }))
    }
  }

  reactionEvents.sort((a, b) => a.time - b.time)

  const arrified = {}
  for (const para in reactionEvents[0]) {
    arrified[para] = []
  }
  for (const event of reactionEvents) {
    for (const para in arrified) {
        arrified[para].push(event[para])
    }
  }
  arrified.time = arrified.time.map( (ts) => 1000 * ts )
  
  const minDuration = Math.min(...arrified.duration_ms)
  const maxDuration = Math.max(...arrified.duration_ms)
  const getBarWidth = (duration) => {
    return (duration - minDuration) / (maxDuration - minDuration) * 15 + 5
  }

  const maxIntensity = 5.
  const getAlphaHex = (intensity) => {
    let opacity = intensity / maxIntensity
    opacity = (opacity < 0.1) ? 0.1 : opacity
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return _opacity.toString(16).toUpperCase();
  }

  const plot = {
    labels: [],
    datasets: arrified.time.map((ts, idx) => {
      const isValid = arrified.valid[idx]
      return {
          label: `#${idx + 1}`,
          barPercentage: isValid ? getBarWidth(arrified.duration_ms[idx]) : 5,
          borderWidth: 2,
          borderColor: isValid ? SIDE_COLOR[arrified.side[idx]] : colors.lightGrey,
          backgroundColor: isValid ? `${SIDE_COLOR[arrified.side[idx]]}${getAlphaHex(arrified.intensity[idx])}` : `${colors.lightGrey}50`,
          xAxisID: 'x-axis-0',
          data: [{
            x: ts,
            y: isValid ? arrified.initial_time_ms[idx] : 400,
          }],
        }
    })
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    usePlugin: 'verticalLine',
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (context) => {
          const datetime = new Date(context[0].xLabel)
          const hours = String(datetime.getHours()).padStart(2, "0")
          const minutes = String(datetime.getMinutes()).padStart(2, "0")
          const seconds = String(datetime.getSeconds()).padStart(2, "0")
          return `#${context[0].datasetIndex + 1} - ${hours}:${minutes}:${seconds}`
        },
        label: (context) => {
          const labels = [(arrified.side[context.datasetIndex]) === "L" ? "Left" : "Right"]
          if (!arrified.valid[context.datasetIndex]) {
            labels.push("INVALID")
            return labels
          }

          for (const para in PARAMETER_CFG) {
            labels.push(
              `${PARAMETER_CFG[para].label.title}: ${arrified[para][context.datasetIndex].toFixed(PARAMETER_CFG[para].label.decimals)} ${PARAMETER_CFG[para].label.unit}`
            )
          }
          return labels
        }
      },
    },
    scales: {
      xAxes: [{
        id: 'x-axis-0',
        type: 'time',
        offset: true,
        distribution: 'linear',
        scaleLabel: {
          display: true,
          labelString: 'Event Time',
        },
        time: {
          stepSize: 5,
          unit: 'seconds',
          displayFormats: {
            seconds: 'HH:mm:ss'
        }
        },
        ticks: {
          display: true,
          min: (Math.floor(arrified.time[0]/5000) - 1) * 5000,
          max: (Math.ceil(arrified.time[arrified.time.length - 1]/5000) + 1) * 5000,
          sampleSize: 5,
        },
        gridLines: {
          display: true,
          offsetGridLines: true
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Time',
        },
        ticks: {
          display: true,
          min: 0,
          max: 800,
        },
        gridLines: {
          display: true
        }
      }]
    },
  }

  return (
    <MStatisticsCard title="Combinatorial" icon={VscCombine}>
        <Box sx={{display: 'flex', height: 'calc(50vh - 200px)', width: '100%', flexDirection: 'column', p: 0 }}>
          <Bar data={plot} options={options} height="100%" width="100%"/>
        </Box>
    </MStatisticsCard>
  )
}


MTRCombinatorial.propTypes = {
  results: PropTypes.object,
};
