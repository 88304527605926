import React, { Fragment } from 'react';
import { Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { gaitParameters } from '../../../definitions/gaitParameters.js';

import { MGaitParameterCard } from './MGaitParameterCard.js';

/**
 * Card with avatar layout
 * @return {jsx} The avatar card component for layout.
 */
export const MGaitParameters = ({ rowAnalysisResults }) => {
  const variabilityCoefficients = gaitParameters.map((gp) => {
    return {
      title: gp.title,
      L: rowAnalysisResults['L'][gp.id].varIDX,
      R: rowAnalysisResults['R'][gp.id].varIDX
    };
  });

  const dataByParameter = {};
  gaitParameters.forEach((gp) => {
    dataByParameter[gp.id] = {};
    ['L', 'R'].forEach((side) => {
      dataByParameter[gp.id][side] = rowAnalysisResults[side][gp.id];
      dataByParameter[gp.id][side].t0 = rowAnalysisResults[side].t0.values;
    });
  });

  return (
    <Fragment>
      <Grid container spacing={1} sx={{ height: '100%', p: 1 }}>
        {gaitParameters.map((gp) => (
          <Grid
            item
            align="center"
            xs={12}
            lg={6}
            xl={4}
            key={uuidv4()}
            sx={{ minHeight: 400, height: 'calc((100vh - 160px) / 2)' }}>
            <MGaitParameterCard
              parameter={gp}
              statistics={dataByParameter[gp.id]}
              variabilityCoefficients={variabilityCoefficients}
            />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

MGaitParameters.propTypes = {
  rowAnalysisResults: PropTypes.object
};
