import React, { Fragment, useState } from 'react';
import { AiOutlineExpandAlt } from 'react-icons/ai';
import { FaCalendarAlt } from 'react-icons/fa';
import { MdAccountCircle, MdAssessment } from 'react-icons/md';
import { Box, Grid, IconButton } from '@mui/material';
import { PropTypes } from 'prop-types';

import { MCommentField } from '../../../components/ui/MCommentField.js';
import { MStatisticsCard } from '../../../components/ui/MStatisticsCard.js';

import { MCalendarHistory } from './MCalendarHistory.js';
import { MInfoDialog } from './MInfoDialog.js';
import { MPatientInfo } from './MPatientInfo.js';
import { MPatientRadar } from './MPatientRadar.js';

/**
 * Patient overview with calendar of assessment and controller buttons
 * @return {jsx} Patient overview with calendar of assessment and controller buttons.
 */
export const MDetails = ({ patient }) => {
  const [openEPDialog, setOpenEPDialog] = useState(false);
  return (
    <Fragment>
      <Box sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={0}>
          <Grid container item xs={12} sx={{ alignItems: 'stretch' }}>
            <Grid container item md={12} xl={4} display={{ md: 'none', lg: 'block' }}>
              <MStatisticsCard title="Performance" icon={MdAssessment}>
                <MPatientRadar overallStats={patient.stats.scores} />
              </MStatisticsCard>
            </Grid>
            <Grid item xs={12} xl={8}>
              <MStatisticsCard title="Info" icon={MdAccountCircle}>
                <MPatientInfo patient={patient} />
                <IconButton
                  color="primary"
                  onClick={() => setOpenEPDialog(true)}
                  sx={{
                    position: 'absolute',
                    top: 30,
                    right: 0
                  }}>
                  <AiOutlineExpandAlt />
                </IconButton>
              </MStatisticsCard>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MStatisticsCard title="Calendar" icon={FaCalendarAlt}>
              <MCalendarHistory activities={patient.activities} />
            </MStatisticsCard>
          </Grid>
        </Grid>
        <Box
          sx={{
            marginY: 2,
            flexGrow: 1,
            minHeight: 150
          }}>
          <MCommentField apiToken={patient.apiToken} title="Patient" />
        </Box>
      </Box>
      <MInfoDialog patient={patient} open={openEPDialog} setOpen={setOpenEPDialog} />
    </Fragment>
  );
};

MDetails.propTypes = {
  patient: PropTypes.object
};
