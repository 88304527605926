import React, { Fragment, useState } from 'react';
import { FaArrowUpLong, FaRotateRight, FaX } from "react-icons/fa6";
import { PiSneakerMoveLight } from "react-icons/pi";
import { Box, ButtonGroup, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

import { MStatisticsCard } from '../../../components/ui/MStatisticsCard';
import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';
import { colors } from '../../../styles/colors';

import { MRTDounghnut, MRTMiniHist } from './MRTMiniPlots';


const reactionTypeToIcon = {
  LINEAR: FaArrowUpLong,
  ROTARY: FaRotateRight,
  INVALID: FaX,
}

const SIDE_LABEL = {
  "L": "Left",
  "R": "Right",
}

export const MRTSummary = ({ results }) => {
  const [summaryView, setSummaryView] = useState('stats')
  
  const reactionEvents = []
  for (const side of ["L", "R"]) {
    for (const reactionType of ["invalid_reactions", "valid_reactions"]) {
      reactionEvents.push(...results[side][reactionType].map(event => {
        event.side = side
        return event
      }))
    }
  }

  reactionEvents.sort((a, b) => a.time - b.time)

  const tableHeader = [
    '#', 'Side', 'Time', 'Duration', 'Intensity', 'Type',
  ]
  const rowIds = [
    'side', 'initial_time_ms', 'duration_ms', 'intensity'
  ]

  const labels = [
    '', 'time', 'duration', 'intensity'
  ]
  const labelIds = [
    'type', 'initial_time_ms', 'duration_ms', 'intensity'
  ]
  return (
    <MStatisticsCard title="Reactions" icon={PiSneakerMoveLight}>
      <Box sx={{height: 'calc(50vh - 200px)'}}>
        {summaryView === 'list' ? (
          <Fragment>
            <TableContainer sx={{height: 'calc(100% - 40px)', overflow: 'scroll'}}>
              <Table stickyHeader stickyFooter size="small">
              <TableHead>
                  <TableRow>
                  { tableHeader.map( item => (
                      <TableCell
                      key={item}
                      align="center"
                      padding="none"
                      >
                      {item}
                      </TableCell>
                  ))}
                  </TableRow>
              </TableHead>
              <TableBody sx={{backgroundColor: `${colors.lightGrey2}50`}}>
                  {reactionEvents.map((event, idx) => {
                  const Icon = reactionTypeToIcon[event.dominant_type]
                  return (
                      <TableRow key={`tbr- ${idx}`} hover padding="none" align="center">
                        <TableCell key={`${idx}-number`} align="center">
                            {idx + 1}
                        </TableCell>
                        {rowIds.map(id => (
                            <TableCell key={`${idx}-${id}`}>
                                {(typeof event[id]) === 'number' ? event[id].toFixed(0) : event[id]}
                            </TableCell>
                        ))}
                        <TableCell key={`${idx}-domtype`}>
                            <Icon/>
                        </TableCell>
                      </TableRow>
                  )
                  })}
              </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        ): (
          <Box sx={{display: 'flex', height: 'calc(100% - 40px)', width: '100%', flexDirection: 'row'}}>
            <Box sx={{display: 'flex', height: '100%', width: 30, flexDirection: 'column'}}>
              <Box sx={{height: 40, width: 30}}/>
              {labels.map((l) => (
                <Box key={l} sx={{display: 'flex', height: `calc((100% - 30px) / ${labels.length})`, justifyContent: 'center', flexDirection: 'column'}}>
                  <Typography variant="caption" sx={{transform: 'rotate(-90deg)'}}>
                    {l}
                    {(l !== '') && <Divider orientation='horizontal'/>}
                  </Typography>
                </Box>
              ))}
            </Box>
            {["L", "R"].map(side => (
              <Box key={side} sx={{display: 'flex', height: '100%', width: 'calc((100% - 30px)/2)', alignContent: 'center', flexDirection: 'column', p:1}}>
                <Typography variant="caption" align='center'>
                  {SIDE_LABEL[side]}
                </Typography>
                <Divider orientation="horizontal" />
                {labelIds.map((li) => {
                  if (li === 'type') {
                    return (
                      <Fragment key={`${side}- ${li}`}>
                        <Box sx={{display: 'flex', height: `calc((100% - 30px) / ${labelIds.length})`, width: '100%', justifyContent: 'center'}}>
                          <MRTDounghnut sidedResults={results[side]} />
                        </Box>
                        <Divider orientation="horizontal"/> 
                        <Box sx={{ display: 'flex', flexDirection: 'row', height: 20 }}>
                          <Box sx={{display: 'flex', justifyContent: 'center', width: '50%' }}>
                            <Typography variant="caption" align='center'>
                              Mean
                            </Typography>
                          </Box>
                          <Box sx={{display: 'flex', width: '50%',justifyContent: 'center' }}>
                            <Typography variant="caption" align='center'>
                              STD
                            </Typography>
                          </Box>
                        </Box> 
                        <Divider orientation="horizontal"/> 
                      </Fragment>
                    )
                  } else {
                    return (
                      <Fragment key={`${side}- ${li}`}>
                        <Box sx={{display: 'flex', height: `calc((100% - 22px) / ${labelIds.length})`, width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                          <MRTMiniHist validReactions={results[side].valid_reactions} signalId={li} />
                        </Box>
                        <Divider orientation="horizontal"/> 
                      </Fragment>
                    )
                  }
                })}
              </Box>  
            ))}
          </Box>
        )}
        <Box sx={{ display: 'flex', height: 30, marginBottom: 1, width: '100%', justifyContent: 'center', alignItems: 'flex-end' }}>
          <ButtonGroup color="primary">
            <MTooltipIconButton
                buttonValue="hashtag"
                title="Show Summary Stats"
                value={'stats'}
                onClickEvent={(event) => {setSummaryView(event.currentTarget.value)}}
                variant={summaryView === 'stats'}

            />
            <MTooltipIconButton
                buttonValue="listSelection"
                title="Show all Events"
                value={'list'}
                onClickEvent={(event) => {setSummaryView(event.currentTarget.value)}}
                variant={summaryView === 'list'}
            />
        </ButtonGroup>
        </Box>
      </Box>
    </MStatisticsCard>
  )
}


MRTSummary.propTypes = {
  results: PropTypes.object,
};