import React, { useState } from 'react';
import { Box, ButtonGroup, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MHistogramChart } from '../../../components/ui/MHistogramChart.js';
import { MSlopeChart } from '../../../components/ui/MSlopeChart.js';
import { MStatisticsCard } from '../../../components/ui/MStatisticsCard.js';
import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton.js';
import { gaitParameters } from '../../../definitions/gaitParameters.js';
import { colors } from '../../../styles/colors.js';
import {
  createOverallDataset,
  getSortingIndexes,
  sortByIndexes
} from '../../../utils/dataFetchFunctions.js';
import { average, stdDev } from '../../../utils/mathUtils.js';

const fatigue = {};
for (const param of ['length', 'velocity', 'heel-clearance']) {
  fatigue[param] = gaitParameters.filter((gp) => gp.id === param)[0];
}

const SLICE_RATIO = 1 / 3;

/**
 * Shows an assessment's fatigue analysis.
 * @return {jsx} The MFatigueResults component
 */
export const MFatigueResults = ({ results }) => {
  const theme = useTheme();
  Object.keys(fatigue).forEach((param) => {
    const [view, setView] = useState('numbers');

    fatigue[param].view = view;
    fatigue[param].setView = setView;
  });

  const handleViewChange = (event, param) => {
    param.setView(event.currentTarget.value);
  };
  const overallResults = createOverallDataset(results.rows);

  const sortingIdxs = getSortingIndexes(overallResults.L.t0.concat(overallResults.R.t0));

  const cutOffIdx = Math.ceil(SLICE_RATIO * sortingIdxs.length);

  for (const para in fatigue) {
    const sortedValues = sortByIndexes(
      overallResults.L[para].concat(overallResults.R[para]),
      sortingIdxs
    );

    fatigue[para].data = [
      {
        label: 'Start',
        color: colors.darkGrey,
        values: sortedValues.slice(0, cutOffIdx)
      },
      {
        label: 'End',
        color: colors.lightBlue,
        values: [...sortedValues].reverse().slice(0, cutOffIdx)
      }
    ];
    fatigue[para].data.forEach((dataset) => {
      const mean = average(dataset.values);
      const std = stdDev(dataset.values, mean, dataset.values.length);

      dataset.stats = {
        mean: mean,
        std: std,
        cov: std / mean
      };
    });
  }

  return (
    <Grid container spacing={2} sx={{ p: 1 }}>
      {Object.values(fatigue).map((param) => (
        <Grid item xs={12} md={4} key={param.id}>
          <MStatisticsCard
            title={<big>{param.title}</big>}
            icon={param.icon}
            color={colors.lightgrey}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '80vh',
                [theme.breakpoints.up('md')]: { height: '25vh' }
              }}>
              {param.view === 'histogram' && (
                <MHistogramChart title={param.title} datasets={param.data} unit={param.unit} />
              )}
              {param.view === 'numbers' && (
                <Grid container spacing={0.5} key={`gc-${param}`} sx={{ width: '100%' }}>
                  {['mean', 'std', 'cov'].map((stat, idx) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={`${param}-${stat}`}
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          height: '100%',
                          flexDirection: 'column',
                          width: '100%',
                          borderRadius: 2,
                          backgroundColor: `${colors.lightBlue}${(idx + 1) * 15}`
                        }}>
                        <Typography
                          align="center"
                          variant="overline"
                          sx={{ fontSize: 9, paddingBottom: 1 }}>
                          {stat}
                        </Typography>
                        <Grid container sx={{ paddingInline: 1 }}>
                          <Grid item xs={6}>
                            <Typography align="left" sx={{ fontSize: 10 }}>
                              Start
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography align="right" sx={{ fontSize: 10 }}>
                              End
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider orientation="horizontal" sx={{ height: 1 }} />
                          </Grid>
                          <Grid item xs={6} sx={{ marginTop: 1 }}>
                            <Typography align="left" sx={{ fontSize: 10 }}>
                              {`${param.data[0].stats[stat].toFixed(2)} ` +
                                (stat !== 'cov' ? param.unit : '')}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ marginTop: 1 }}>
                            <Typography align="right" sx={{ fontSize: 10 }}>
                              {`${param.data[1].stats[stat].toFixed(2)} ` +
                                (stat !== 'cov' ? param.unit : '')}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box sx={{ height: 'calc(100% - 100px)', width: '100%' }}>
                          <MSlopeChart
                            points={[param.data[0].stats[stat], param.data[1].stats[stat]]}
                            options={{
                              x0: {
                                color: colors.darkGrey,
                                label: 'Start'
                              },
                              x1: {
                                color: colors.lightBlue,
                                label: 'End'
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
            <Box
              sx={{
                height: 25,
                marginTop: 1,
                marginBottom: 1,
                justifyContent: 'center',
                display: 'flex'
              }}>
              <ButtonGroup color="primary">
                <MTooltipIconButton
                  buttonValue="hashtag"
                  title="Show time series"
                  value={'numbers'}
                  onClickEvent={(event) => handleViewChange(event, param)}
                  variant={param.view === 'numbers'}
                />
                <MTooltipIconButton
                  buttonValue="chartArea"
                  title="Show histogram"
                  value={'histogram'}
                  onClickEvent={(event) => handleViewChange(event, param)}
                  variant={param.view === 'histogram'}
                />
              </ButtonGroup>
            </Box>
          </MStatisticsCard>
        </Grid>
      ))}
    </Grid>
  );
};

MFatigueResults.propTypes = {
  results: PropTypes.object
};
