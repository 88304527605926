import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MStatisticsCard } from '../../../components/ui/MStatisticsCard.js';
/**
 * Show pie charts of performance stats in comp.
 * @param {Object} props The component properties.
 * @return {jsx} The chart component.
 */
export const MComparisonStatsCard = ({ data, title, unit, icon, decimals }) => {
  const theme = useTheme();

  const absoluteValueNumberSum = Math.abs(data[0]) + Math.abs(data[1]);
  const chartData = {
    labels: ['Group A', 'Group B'],
    datasets: [
      {
        data: [data[0].toFixed(decimals), data[1].toFixed(decimals)],
        backgroundColor: [theme.palette.success.dark, theme.palette.info.main],
        borderWidth: 3,
        barPercentage: 0.5
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: Math.PI,
    circumference: Math.PI,
    usePlugin: 'N/A',
    layout: {
      padding: {
        bottom: 5
      }
    },
    legend: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem, chartData) => {
          const dataset = chartData['datasets'][0];
          const value = dataset['data'][tooltipItem['index']];
          const unitValue = unit ? unit : '';
          const ratio = Math.abs(value / absoluteValueNumberSum);
          const percent = Math.round(ratio * 100);
          return `${value} ${unitValue} (${percent}%)`;
        }
      }
    }
  };

  return (
    <Box sx={{ paddingBottom: 1 }}>
      <MStatisticsCard title={title} icon={icon}>
        <Box
          sx={{
            position: 'absolute',
            height: '30%',
            width: '100%',
            left: 0,
            top: '50%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Divider orientation="vertical" />
        </Box>
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ height: 60 }}>
            <Doughnut data={chartData} options={options} />
          </Box>
          <Typography
            color="primary"
            align="center"
            variant="caption"
            display="block"
            sx={{ backgroundColor: theme.palette.common.white, fontSize: 11 }}
          >
            {unit}
          </Typography>
          <Grid
            container
            sx={{
              position: 'absolute',
              bottom: 0
            }}
          >
            <Grid item xs={6}>
              <Typography color="primary" align="center" sx={{ fontSize: 12, paddingRight: 1.2 }}>
                {data[0].toFixed(decimals)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" align="center" sx={{ fontSize: 12, paddingLeft: 1.2 }}>
                {data[1].toFixed(decimals)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </MStatisticsCard>
    </Box>
  );
};

MComparisonStatsCard.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  unit: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  decimals: PropTypes.number
};
