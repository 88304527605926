import React, { Fragment, useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Dialog, DialogTitle } from '@mui/material';
import { PropTypes } from 'prop-types';

import { colors } from '../../../styles/colors.js';


const DEFS = {
  xl: {
    title: 'Acceleration',
    unit: 'm/s\u00B2',
    limit: 0.05,
    dirMap: {
      x: 'y',
      y: 'x'
    }
  },
  omega: {
    title: 'Gyration',
    unit: 'rad/s',
    limit: 0.01,
    dirMap: {
      x: 'x',
      y: 'y'
    }
  }
}


export const MPathEnvelope = ({ variant, type, data }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const { title, unit, limit, dirMap } = DEFS[type]

  const path = {
    closed: [],
    open: []
  }
  for (const state in path) {
    for (let index = 0; index < data[state].path.x.length; index++) {
      path[state].push({
        x: data[state].path[dirMap.x][index],
        y: data[state].path[dirMap.y][index]
      })
    }
    path[state].push({
      x: data[state].path[dirMap.x][0],
      y: data[state].path[dirMap.y][0]
    })
  }

  const delta = (data.closed.area - data.open.area) / data.open.area * 100
  const deltaStr = '\u0394 = ' + (delta > 0 ? '+' : '') + delta.toFixed(2) + '%'


  const plotData = {
    datasets: [
      {
        label: 'Open Eyes',
        backgroundColor: `${colors.lightBlue}50`,
        borderWidth: 2,
        borderColor: colors.lightBlue,
        pointRadius: 0,
        data: path.open,
        showLine: true,
      },
      {
        label: 'Closed Eyes',
        backgroundColor: `${colors.lightBlue2}20`,
        borderWidth: 2,
        borderColor: colors.lightBlue2,
        pointRadius: 0,
        data: path.closed,
        showLine: true,
      },
    ],
  };

  const setOptions = (fixedAxes) => {
    let bound = {
      max: limit,
      min: -limit
    }

    if (!fixedAxes) {
      const allValues = []
      for (const state in path) {
        for (const point of path[state]) {
          allValues.push(...Object.values(point))
        }
      }
      const customLimit = Math.max(...allValues.map((x) => Math.abs(x)))
      bound = {
        max: customLimit,
        min: -customLimit,
      }

    }
    return {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 1,
      legend: {
        display: true,
        align: 'end',
        labels: {
          padding: 5,
          boxWidth: 20,
          fontSize: 10,
        }
      },
      tooltips: {
        enabled: false
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              labelString: `Lateral ${title} [${unit}]`,
              display: true,
            },
            ticks: {
              ...bound,
              display: true,
              callback: (value, index, values) => {
                if (index === 0 || index == values.length - 1) {
                  return undefined
                }
                return value
              }
              
            }
          }
        ],
        yAxes: [
          {
            scaleLabel: {
              labelString: `Anteposterior ${title} [${unit}]`,
              display: true,
            },
            ticks: {
              ...bound,
              display: true,
              callback: (value, index, values) => {
                if (index === 0 || index == values.length - 1) {
                  return undefined
                }
                return value
              }
            }
          },
        ]
      },
      annotation: {
        annotations: [
          (variant === 'full') ? {
            drawTime: 'beforeDatasetsDraw',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-1',
            value: 0.9 * bound.min,
            borderColor: 'transparent',
            label: {
              content: deltaStr,
              enabled: true,
              position: 'center',
              fontSize: 10,
              backgroundColor: colors.darkGrey,
            }
          } : {}
        ]
      }
    }
  }

  return (
    <Fragment>
      <div onClick={() => setDialogOpen(true)}>
        <Scatter options={setOptions(true)} data={plotData} height="100%" width="100%" />
      </div>
      <Dialog onClose={() => setDialogOpen(false)} maxWidth="xl" open={isDialogOpen}>
        <DialogTitle>{`${title} Envelope`}</DialogTitle>
        <div style={{ height: 700, width: 700, padding: 10 }}>
          <Scatter options={setOptions(false)} data={plotData} height={null} width={null} />
        </div>
      </Dialog>
    </Fragment>
  )
}


MPathEnvelope.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
};