import React, { Fragment, useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Grid, Paper, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MCustomSnackbar } from '../../../components/ui/MCustomSnackbar.js';
import { MPlotLabels } from '../../../components/ui/MPlotLabels.js';
import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';
import { useComparisonContext } from '../../../context/ComparisonContext';
import { eventsFromStreams, pryvApiCall } from '../../../data/apiCalls.js';
import { colors } from '../../../styles/colors.js';
import { loadPatientData } from '../../../utils/auxFunctions.js';

import { MLoadComparisonDialog } from './MLoadComparisonDialog.js';
import { MSaveComparisonDialog } from './MSaveComparisonDialog.js';

export const MComparisonController = ({ viewState, computeStats, onDownloadReport }) => {
  const { selectionA, setSelectionA, selectionB, setSelectionB } = useComparisonContext();

  const [savedComparisons, setSavedComparisons] = useState([]);
  const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);
  const [isLoadDialogOpen, setLoadDialogOpen] = useState(false);
  const [reportStatus, setReportStatus] = useState('pending');
  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false
  });

  const theme = useTheme();
  const patientData = loadPatientData();
  const docToken = sessionStorage.getItem('docEP');

  useEffect(() => {
    pryvApiCall(docToken, eventsFromStreams(['comparisons'])).then((res) => {
      setSavedComparisons(
        res[0].events.filter((event) => event.content.patient === patientData.username)
      );
    });
  }, []);

  useEffect(() => {
    if (viewState === 'post-computation') {
      setReportStatus('init');
    }
  }, [viewState]);

  useEffect(() => {
    if (viewState === 'post-computation') {
      setReportStatus('pending');
    }
  }, [selectionA, selectionB]);

  const loadComparisonClick = () => setLoadDialogOpen(true);
  const onLoadComparison = (grpA, grpB) => {
    computeStats(grpA, grpB);
    setSelectionA(grpA);
    setSelectionB(grpB);
    setLoadDialogOpen(false);
  };

  const saveComparisonClick = () => {
    if (selectionA.length === 0 || selectionB.length === 0) {
      setSnackbar({
        message: 'To save, first select assessments from both groups',
        severity: 'warning',
        show: true
      });
      return;
    }
    setSaveDialogOpen(true);
  };

  const downloadReportClick = async () => {
    setReportStatus('fetching');
    onDownloadReport(selectionA, selectionB)
      .then(() => {
        setReportStatus('ready');
        setSnackbar({
          message: 'Report downloaded successfully.',
          severity: 'success',
          show: true
        });
      })
      .catch(() => {
        setReportStatus('init');
        setSnackbar({
          message: 'Something went wrong creating your report.',
          severity: 'error',
          show: true
        });
      });
  };

  return (
    <Fragment>
      {snackbar.show && (
        <MCustomSnackbar
          content={snackbar}
          handleClose={() =>
            setSnackbar({
              message: '',
              severity: '',
              show: false
            })
          }
        />
      )}
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} md={4} align="center" display={{ md: 'none', lg: 'block' }}>
          <MPlotLabels
            plotLabelType="divided"
            colorLeft={theme.palette.success.dark}
            colorRight={colors['lightGreen4']}
          />
        </Grid>
        <Grid container item alignItems="center" md={12} lg={8}>
          <Paper
            sx={{
              display: 'flex',
              p: 1.3,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}>
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}>
              <MTooltipIconButton
                buttonValue="outlineCloudDownload"
                title="Load saved Comparison"
                onClickEvent={() => loadComparisonClick()}
                buttonType="tooltipIconButton"
                iconStyle={{ fontSize: 35 }}
              />
              <MTooltipIconButton
                buttonValue="fillSave"
                title="Save current Comparison"
                onClickEvent={() => saveComparisonClick()}
                buttonType="tooltipIconButton"
                iconStyle={{ fontSize: 35 }}
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ height: 1, width: 1 }}>
                {reportStatus === 'fetching' ? (
                  <CircularProgress color="secondary" size={30} />
                ) : (
                  <MTooltipIconButton
                    buttonValue="filledDownload"
                    title="Download Comparison Report"
                    onClickEvent={() => downloadReportClick()}
                    buttonType="tooltipIconButton"
                    iconStyle={{ fontSize: 30 }}
                    disabled={reportStatus === 'pending'}
                  />
                )}
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <MSaveComparisonDialog
        isOpen={isSaveDialogOpen}
        setOpen={setSaveDialogOpen}
        savedComparisons={savedComparisons}
        setSavedComparisons={setSavedComparisons}
      />
      <MLoadComparisonDialog
        isOpen={isLoadDialogOpen}
        setOpen={setLoadDialogOpen}
        savedComparisons={savedComparisons}
        setSavedComparisons={setSavedComparisons}
        onLoad={(grpA, grpB) => onLoadComparison(grpA, grpB)}
      />
    </Fragment>
  );
};

MComparisonController.propTypes = {
  viewState: PropTypes.string,
  computeStats: PropTypes.func,
  onDownloadReport: PropTypes.func
};
