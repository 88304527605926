import React, { Fragment, useEffect, useState } from 'react';
import { Chart, Line } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MOverlay } from '../../components/ui/MOverlay.js';
import { average, kernelDensityEstimation, linspace, stdDev } from '../../utils/mathUtils.js';

/**
 * Shows detailed charts (line and histogram) with upper and lower bounds.
 * @param {Object} props The component properties.
 * @return {jsx} The detailed chart component.
 */
export const MHistogramChart = ({ title, datasets, unit }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    Chart.pluginService.register({
      afterDraw: (chart) => {
        if (chart.config.options.usePlugin === 'verticalLine') {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.controller.tooltip._active[0];
            const ctx = chart.ctx;
            const x = activePoint.tooltipPosition().x;
            const topY = chart.scales['y-axis-0'].top;
            const bottomY = chart.scales['y-axis-0'].bottom;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = theme.palette.primary.main;
            ctx.stroke();
            ctx.restore();
          }
        }
      }
    });
  });

  // Get xrange of histogram & kernel bandwidth
  let xMax = -1e5;
  let xMin = 1e5;

  let kernelBandWidth = 0;
  for (const data of datasets) {
    const values = data['values'];

    const avg = average(values);
    const std = stdDev(values, avg, values.length);

    const ub = avg + 3 * std;
    xMax = xMax > ub ? xMax : ub;

    const lb = avg - 3 * std;
    xMin = xMin < lb ? xMin : lb;

    kernelBandWidth += std;
  }

  const xi = linspace(xMin, xMax, 300);
  kernelBandWidth /= 6 * datasets.length;

  // Compute bandwidth
  const chartData = {
    labels: xi.map((x) => x.toFixed(2)),
    datasets: datasets.map((ds) => ({
      label: ds.label,
      fill: true,
      borderColor: ds.color,
      backgroundColor: `${ds.color}40`,
      borderJoinStyle: 'round',
      lineTension: 0.2,
      pointBorderWidth: 1,
      pointRadius: 0,
      pointHitRadius: 10,
      data: kernelDensityEstimation(ds.values, xi, kernelBandWidth).map(
        (x) => Math.round(x * 100) / 100
      )
    }))
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    usePlugin: 'verticalLine',
    legend: {
      display: false
    },
    tooltips: {
      intersect: false,
      mode: 'index',
      callbacks: {
        label: (tooltipItem, chartData) => {
          const dLabel = chartData.datasets[tooltipItem.datasetIndex].label;
          return `${dLabel} - ${tooltipItem.yLabel}`;
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: unit === '' ? '[-]' : `[${unit}]`,
            beginAtZero: true
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: ` Ratio [%]`
          },
          ticks: {
            display: false,
            beginAtZero: false
          }
        }
      ]
    }
  };

  return (
    <Fragment>
      <Box onClick={() => setDialogOpen(true)} sx={{ height: '100%', width: '100%' }} id={`#${title}-histogram`}>
        <Line data={chartData} options={options} height="100%" width="100%"/>
      </Box>
      <MOverlay open={isDialogOpen} onClose={() => setDialogOpen(false)} title={title}>
        <Line data={chartData} options={options} />
      </MOverlay>
    </Fragment>
  );
};

MHistogramChart.propTypes = {
  title: PropTypes.string,
  datasets: PropTypes.array,
  unit: PropTypes.string
};
