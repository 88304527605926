import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

/**
 * Renders a radar (spider web) chart of a patient.
 * @param {Object} props containing the patient data.
 * @return {jsx} The patient radar chart component.
 */
export const MPatientRadar = ({ overallStats }) => {
  const theme = useTheme();
  const labels = ['Symmetry', 'Consistency', 'Endurance', 'Activity'];

  const radarStats = [
    overallStats.symmetry,
    1 - overallStats.consistency >= 0 ? 1 - overallStats.consistency : 0,
    Math.abs(1 - overallStats.endurance),
    1 / (1 + Math.exp(-4 * (overallStats.activity - 1)))
  ];

  radarStats.forEach((item, i) => {
    if (item < 0) {
      radarData[i] = 0;
    }
  });

  const radarData = {
    labels: labels.map((label) => label[0]),
    datasets: [
      {
        label: 'Overall Score',
        lineTension: 0.0,
        backgroundColor: `${theme.palette.secondary.main}33`,
        borderColor: theme.palette.secondary.main,
        borderDashOffset: 0.5,
        pointBackgroundColor: theme.palette.secondary.main,
        pointBorderColor: theme.palette.common.white,
        pointBorderWidth: 0.8,
        pointRadius: 3,
        pointHitRadius: 10,
        pointHoverBackgroundColor: `${theme.palette.secondary.main}33`,
        data: radarStats.map((item) => Math.round(item * 100))
      }
    ]
  };

  const radarOptions = {
    responsive: true,
    legend: {
      display: false,
      position: 'top'
    },
    maintainAspectRatio: false,
    scale: {
      angleLines: {
        display: true
      },
      beginAtZero: true,
      pointLabels: {
        fontSize: 14
      },
      ticks: {
        display: false,
        max: 100,
        min: 0,
        stepSize: 25
      }
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        label: (tooltipItem) => `${labels[tooltipItem.index]} ${tooltipItem.label}`,
        title: () => {}
      }
    }
  };

  return (
    <Box>
      <Radar data={radarData} options={radarOptions} />
    </Box>
  );
};

MPatientRadar.propTypes = {
  overallStats: PropTypes.object
};
