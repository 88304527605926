import React, { Fragment, useEffect, useState } from 'react';
import { BsBookmarkStarFill } from 'react-icons/bs';
import { MdChevronRight, MdVibration } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import {
  Box,
  ButtonBase,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListSubheader,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { id2TitleMap } from '../../definitions/activityTypes.js';
import { vibId2TitleMap } from '../../definitions/vibrationTypes.js';
import { colors } from '../../styles/colors.js';
import { makeDateString } from '../../utils/auxFunctions.js';

import { MActivityTypeToggle } from './MActivityTypeToggle.js';

/**
 * MSelectAssessmentsList
 * @param {Object} props The component properties.
 * @return {jsx} The MSelectAssessmentsList component.
 */
export const MSelectAssessmentsList = ({ activities, group, setSelection, selectedActs, setOpen }) => {
  const markedActivities = new Set(JSON.parse(sessionStorage.getItem('markedActivities')));

  const theme = useTheme();
  const history = useHistory();

  const [filter, setFilter] = useState({
    activityType: 'all',
    vibrationType: 'none'
  });
  const [displayList, setDisplayList] = useState([]);
  const [checked, setChecked] = useState([0]);

  // Set checkmarks if selectedActs is passed.
  useEffect(() => {
    if (selectedActs) setChecked([0, ...selectedActs]);
  }, [selectedActs]);

  let color;
  let dColor;

  switch (group) {
    case 'A':
      color = colors['green'];
      dColor = colors['lightGreen2'];
      break;
    case 'B':
      color = theme.palette.secondary.main;
      dColor = theme.palette.secondary.light;
      break;
    default:
      color = theme.palette.primary.main;
      dColor = colors['lightGrey'];
  }

  useEffect(() => {
    setDisplayList(formatActList(activities));
  }, []);

  useEffect(() => {
    let selected = activities;

    // filter by activity type
    if (filter.activityType !== 'all') {
      selected = selected.filter((x) => x.type === filter.activityType);
    }

    // filter by vibration
    switch (filter.vibrationType) {
      case 'none':
        break;
      case 'any':
        selected = selected.filter((x) => x.vibration);
        break;
      case 'without':
        selected = selected.filter((x) => !x.vibration);
        break;
      default:
        selected = selected.filter((x) => x.vibration === filter.vibrationType);
        break;
    }
    setDisplayList(formatActList(selected));
  }, [filter]);

  const handleToggle = (value) => {
    const currentIndex = checked.findIndex((x) => x.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);
    setSelection(newChecked.slice(1));
    setChecked(newChecked);
  };

  const dateTimeString = (dd) => {
    let hrs = dd.getHours().toString();
    let min = dd.getMinutes().toString();

    if (hrs.length < 2) hrs = `0${hrs}`;
    if (min.length < 2) min = `0${min}`;
    const time = `${hrs}:${min}`;

    return `${makeDateString(dd)}  @  ${time}`;
  };

  const formatActList = (actList) => {
    const list = actList;
    list.forEach((act, idx) => {
      list[idx]['dateTime'] = dateTimeString(new Date(act.time));
    });

    const dateSet = new Set(
      list.map((act) => {
        return act.dateTime.split('@')[0].slice(0, -1);
      })
    );

    const actByDate = [];
    dateSet.forEach((uniqueDate) => {
      actByDate.push({
        date: uniqueDate,
        activities: list.filter((act) => {
          const dAct = act.dateTime.split('@')[0].slice(0, -1);
          return dAct === uniqueDate;
        })
      });
    });
    return actByDate;
  };

  const toAssessmentClick = (activity) => {
    if (group === 'D') {
      history.replace({
        pathname: `/assessment/${activity.id}`
      });
      setOpen(false);
    } else {
      history.push({
        pathname: '/assessment/' + activity.id
      });
    }
  };

  const checkAssessmentsByDate = (day) => {
    day.hasBeenToggled = !day.hasBeenToggled;
    const checkedAssessments = [...checked];
    day.activities.forEach((act) => {
      const aIdx = checkedAssessments.indexOf(act);

      if (day.hasBeenToggled && aIdx === -1) checkedAssessments.push(act);
      if (!day.hasBeenToggled && aIdx !== -1) checkedAssessments.splice(aIdx, 1);
    });
    setSelection(checkedAssessments.slice(1));
    setChecked(checkedAssessments);
  };

  const textSize = ['A', 'B'].includes(group) ? 12 : 15;

  const activityTypeToggleProps = {
    replaceOverall: 'All',
    activityType: filter.activityType,
    vibrationType: filter.vibrationType,
    setActivityType: (at) => setFilter({ activityType: at, vibrationType: filter.vibrationType }),
    setVibrationType: (vt) => setFilter({ activityType: filter.activityType, vibrationType: vt }),
    popperPlacement: 'top'
  };

  const GroupsScrollPaperStyle = {
    minHeight: 215,
    marginTop: 2,
    maxHeight: 215,
    overflow: 'scroll'
  };
  const ABGroupsScrollPaperStyle = {
    minHeight: '100%',
    maxHeight: '100%',
    position: 'relative'
  };

  const CDGroupsScrollPaperStyle = {
    minHeight: 350,
    maxHeight: 350,
    position: 'relative'
  };

  return (
    <Fragment>
      <Grid container alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        {!['C', 'D', 'E'].includes(group) && (
          <Grid item xs={12}>
            <MActivityTypeToggle {...activityTypeToggleProps} size={13} />
          </Grid>
        )}
        <Grid item xs={12} sx={{ height: 'calc(100% - 50px)' }}>
          <Paper
            variant="outlined"
            sx={
              ['C', 'D', 'E'].includes(group)
                ? { ...GroupsScrollPaperStyle, ...CDGroupsScrollPaperStyle }
                : { ...GroupsScrollPaperStyle, ...ABGroupsScrollPaperStyle }
            }
          >
            {displayList.length === 0 ? (
              <Box
                align="center"
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                <Typography
                  align="center"
                  color="textSecondary"
                  sx={{
                    fontSize: 12,
                    fontWeight: 'bold'
                  }}
                >
                  No Assessments available.
                </Typography>
              </Box>
            ) : (
              <List dense subheader={<li />}>
                {displayList.map((day) => (
                  <li key={uuidv4()}>
                    <ul
                      style={{
                        padding: 0
                      }}
                    >
                      <ListSubheader
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          padding: 0
                        }}
                      >
                        <ButtonBase
                          onClick={() => checkAssessmentsByDate(day)}
                          sx={{
                            backgroundColor: dColor,
                            margin: 0,
                            height: 50,
                            width: '100%',
                            '&:hover': {
                              opacity: 0.8
                            }
                          }}
                        >
                          <Typography
                            color="primary"
                            variant="button"
                            sx={{
                              fontSize: textSize
                            }}
                          >
                            {day.date}
                          </Typography>
                        </ButtonBase>
                      </ListSubheader>
                      {day.activities.map((act) => {
                        const bookmark = markedActivities.has(act.id);
                        const checkedItem = checked
                          .slice(1)
                          .map((cAct) => {
                            return cAct.id;
                          })
                          .includes(act.id);
                        return (
                          <ListItem
                            button
                            onClick={() => {
                              if (group === 'D') toAssessmentClick(act);
                              else handleToggle(act);
                            }}
                            key={uuidv4()}
                            sx={{
                              height: 40
                            }}
                          >
                            <Box
                              sx={{
                                width: 40,
                                height: '100%',
                                display: 'flex'
                              }}
                            >
                              {!(group == 'D') && (
                                <Checkbox
                                  edge="start"
                                  checked={checkedItem}
                                  tabIndex={-1}
                                  disableRipple
                                  size="small"
                                  style={{ color }}
                                />
                              )}
                              {bookmark && (
                                <BsBookmarkStarFill
                                  style={{
                                    height: 30,
                                    top: !(group == 'C') ? 5 : 0,
                                    marginLeft: -5,
                                    color: theme.palette.primary.main
                                  }}
                                />
                              )}
                            </Box>
                            <Typography
                              color="textSecondary"
                              sx={{
                                fontSize: textSize,
                                marginRight: `${textSize}px`
                              }}
                            >
                              {act.dateTime.split('@')[1]}
                            </Typography>
                            <Typography sx={{ fontSize: textSize }}>
                              {id2TitleMap(act.type)}
                            </Typography>
                            {act.vibration && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <Typography
                                  align="center"
                                  variant="p"
                                  color="primary"
                                  sx={{
                                    fontSize: textSize,
                                    mx: 0.5
                                  }}
                                >
                                  +
                                </Typography>
                                <Tooltip title={vibId2TitleMap(act.vibration)}>
                                  <Box sx={{ display: 'flex' }}>
                                    <MdVibration
                                      style={{
                                        marginLeft: 5,
                                        fontSize: textSize
                                      }}
                                    />
                                  </Box>
                                </Tooltip>
                              </Box>
                            )}
                            {group !== 'E' && (
                              <ListItemSecondaryAction>
                                <IconButton size="small" onClick={() => toAssessmentClick(act)}>
                                  <MdChevronRight size={25} style={{ color }} />
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
        {['C', 'D'].includes(group) && (
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2.5,
              marginBottom: 2.5,
              display: 'flex',
              justifyContent: 'center',
              marginRight: 2.5
            }}
          >
            <MActivityTypeToggle {...activityTypeToggleProps} size={13} />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

MSelectAssessmentsList.propTypes = {
  activities: PropTypes.array,
  group: PropTypes.string,
  setSelection: PropTypes.func,
  selectedActs: PropTypes.array,
  setOpen: PropTypes.func,
  comparisonPageHeightContainer: PropTypes.number
};
