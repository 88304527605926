import React, { Fragment, useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Dialog, DialogTitle } from '@mui/material';
import { PropTypes } from 'prop-types';

import reference from '../../../definitions/posturographyReference.json';
import { colors } from '../../../styles/colors.js';
import { linspace } from '../../../utils/mathUtils.js';


const THETA = linspace(0, 2 * Math.PI, 50);

export const MRombergEllipse = ({ title, ratios }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const id = title.toLowerCase().split(' ').join('-')

  const data = JSON.parse(JSON.stringify(ratios))

  const referenceRange = {}
  for (const dir in reference.healthy[id]) {
    const val = reference.healthy[id][dir]
    referenceRange[dir] = val.mean + val.std
  }

  if (title === 'Alternative Romberg Ratio') {
    for (const dir in referenceRange) {
      data[dir] += 1;
      referenceRange[dir] += 1;
    }
  }

  const unitCircle = THETA.map((th) => {
    return {
      x: Math.cos(th),
      y: Math.sin(th)
    }
  })

  const referenceEllipse = THETA.map((th) => {
    const rX = referenceRange.lateral
    const rY = referenceRange.anteposterior

    return {
      x: rX * Math.cos(th),
      y: rY * Math.sin(th)
    }
  })

  const rombergEllipse = THETA.map((th) => {
    const rX = data.lateral
    const rY = data.anteposterior

    return {
      x: rX * Math.cos(th),
      y: rY * Math.sin(th)
    }
  })



  const plotData = {
    datasets: [
      {
        label: 'Unitcircle',
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: colors.lightSlateGrey,
        pointRadius: 0,
        data: unitCircle,
        showLine: true,
        borderDash: [5, 5]
      },
      {
        label: 'Reference  ',
        backgroundColor: `${colors.lightGreen2}50`,
        borderWidth: 2,
        borderColor: colors.lightGreen3,
        pointRadius: 0,
        data: referenceEllipse,
        showLine: true,
      },
      {
        label: 'Ratio  ',
        backgroundColor: `${colors.lightBlue2}20`,
        borderWidth: 2,
        borderColor: colors.lightBlue2,
        pointRadius: 0,
        data: rombergEllipse,
        showLine: true,
      },
    ],
  };

  // Manually scaling x & y axis :'(
  const allValues = [];
  for (const dir of ['x', 'y']) {
    allValues.push(
      ...referenceEllipse.map((d) => d[dir]),
    )
  }

  const limit = 2 * Math.max(...allValues)
  const bound = {
    min: -(limit),
    max: limit,
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          },
          scaleLabel: {
            labelString: ' ←    Lateral    →',
            display: true,
          },
          ticks: {
            ...bound,
            display: false,
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false
          },
          scaleLabel: {
            labelString: ' ←   Anteposterior   →',
            display: true,
          },
          ticks: {
            ...bound,
            display: false,
          }
        },
      ]
    }
  }

  return (
    <Fragment>
      <div onClick={() => setDialogOpen(true)}>
        <Scatter options={options} data={plotData} height={null} width={null} />
      </div>
      <Dialog onClose={() => setDialogOpen(false)} maxWidth="xl" open={isDialogOpen}>
        <DialogTitle>{title}</DialogTitle>
        <div style={{ height: 700, width: 700, padding: 10 }}>
          <Scatter options={options} data={plotData} height={null} width={null} />
        </div>
      </Dialog>
    </Fragment>
  )
}


MRombergEllipse.propTypes = {
  title: PropTypes.string,
  ratios: PropTypes.object
};