/*eslint-disable*/
import React, { Fragment, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { Divider, Grid, IconButton, Paper, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MAssessmentListDialog } from '../../components/ui/MAssessmentListDialog.js';
import { MPlotLabels } from '../../components/ui/MPlotLabels';
import { MTooltipIconButton } from '../../components/ui/MTooltipIconButton';
import { loadPatientData } from '../../utils/auxFunctions.js';
/**
 * MAssessmentController component
 * @return {jsx} Renders MAssessmentController component.
 */
export const MAssessmentController = ({ edgeIdx, toAssessment, comparisonEnabled }) => {
  const [openAlDialog, setOpenAlDialog] = useState(false);

  const theme = useTheme();
  const history = useHistory();

  const user = loadPatientData()
  const analyzedActivities = user.activities.filter((act) => act.analysisStatus === 'analyzed');
  
  const disableToComparisonButton = (!comparisonEnabled || analyzedActivities < 2)

  const toComparisonToolClick = () => {
    history.push({
      pathname: '/compare/' + user.username,
      state: {
        activity: window.location.href.split("/").reverse()[0]
      }
    });
  }
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={4} align="center" display={{ md: 'none', lg: 'block' }}>
          <MPlotLabels
            colorLeft={theme.palette.info.main}
            colorRight={theme.palette.secondary.main}
          />
        </Grid>
        <Grid container item alignItems="center" md={12} lg={8}>
          <Paper
            sx={{
              display: 'flex',
              p: 1.3,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}
          >
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <IconButton
                id="back-one-assessment-button"
                onClick={() => toAssessment(1)}
                disabled={edgeIdx === 1 || edgeIdx === '&'}
                sx={{ color: theme.palette.primary.main }}
              >
                <FaAngleLeft style={{ fontSize: 25 }} />
              </IconButton>
              <MTooltipIconButton
                id="comparison-tool-button"
                buttonValue="compare"
                title="Comparison Tool"
                onClickEvent={() => toComparisonToolClick()}
                buttonType="tooltipIconButton"
                buttonStyle={{
                  fontSize: 25
                }}
                disabled={disableToComparisonButton}
              />
              <MTooltipIconButton
                title="Select Specific Assessment"
                onClickEvent={() => setOpenAlDialog(true)}
                buttonType="tooltipIconButton"
                buttonValue="listSelection"
                buttonStyle={{
                  fontSize: 25
                }}
              />
              <IconButton
                onClick={() => toAssessment(-1)}
                disabled={edgeIdx === -1 || edgeIdx === '&'}
                sx={{ color: theme.palette.primary.main }}
              >
                <FaAngleRight style={{ fontSize: 25 }} />
              </IconButton>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <MAssessmentListDialog open={openAlDialog} setOpen={setOpenAlDialog} type="D" />
    </Fragment>
  );
};

MAssessmentController.propTypes = {
  edgeIdx: PropTypes.number,
  toAssessment: PropTypes.func,
  comparisonEnabled: PropTypes.bool,
};
