/* eslint-disable*/
import React, {Fragment} from 'react';
import {
  AiFillEdit,
  AiFillFilePdf,
  AiFillSave,
  AiOutlineCloudDownload,
  AiOutlineFileUnknown,
  AiOutlineNumber
} from 'react-icons/ai';
import { BiArrowFromBottom } from 'react-icons/bi';
import { BsBookmarkStar, BsBookmarkStarFill } from 'react-icons/bs';
import {
  FaChartArea,
  FaChartBar,
  FaChartLine,
  FaDiceD20,
  FaFileDownload,
  FaSave,
  FaTrashAlt
} from 'react-icons/fa';
import { GiDuration, GiStopwatch, GiSpeedometer } from "react-icons/gi";
import {
  MdAddComment,
  MdChevronRight,
  MdCompare,
  MdLastPage,
  MdList,
  MdModeEdit,
  MdViewModule
} from 'react-icons/md';
import { TiDeleteOutline } from 'react-icons/ti'
import { VscListSelection } from 'react-icons/vsc';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { PropTypes } from 'prop-types';

/**
 * Renders Tooltip Icon  Button.
 * @param {Object} props The component properties.
 * @return {jsx} Tooltip Icon Button.
 */
export const MTooltipIconButton = ({
  id,
  buttonValue,
  title,
  value,
  onClickEvent,
  variant,
  buttonType,
  tooltipPosition,
  disabled,
  iconStyle = {},
  buttonStyle = {}
}) => {
  let tooltipRight = 0;
  const icons = {
    chartArea: <FaChartArea style={{ ...iconStyle }} />,
    chartBar: <FaChartBar style={{ ...iconStyle }} />,
    chartLine: <FaChartLine style={{ ...iconStyle }} />,
    faDice20: <FaDiceD20 style={{ ...iconStyle }} />,
    list: <MdList style={{ ...iconStyle }} />,
    viewModule: <MdViewModule style={{ ...iconStyle }} />,
    addEntry: <MdAddComment style={{ ...iconStyle }} />,
    addComment: <MdModeEdit style={{ ...iconStyle }} />,
    modeEdit: <MdModeEdit style={{ ...iconStyle }} />,
    save: <FaSave style={{ ...iconStyle }} />,
    trash: <FaTrashAlt style={{ ...iconStyle }} />,
    bookmarksStarFill: <BsBookmarkStarFill style={{ ...iconStyle }} />,
    bookmarksStar: <BsBookmarkStar style={{ ...iconStyle }} />,
    filledDownload: <FaFileDownload style={{ ...iconStyle }} />,
    arrowFromButton: <BiArrowFromBottom style={{ ...iconStyle }} />,
    listSelection: <VscListSelection style={{ ...iconStyle }} />,
    chevronRight: <MdChevronRight style={{ ...iconStyle }} />,
    outlineCloudDownload: <AiOutlineCloudDownload style={{ ...iconStyle }} />,
    fillSave: <AiFillSave style={{ ...iconStyle }} />,
    fillFilePdf: <AiFillFilePdf style={{ ...iconStyle }} />,
    outlineFileUnknown: <AiOutlineFileUnknown style={{ ...iconStyle }} />,
    fillEdit: <AiFillEdit style={{ ...iconStyle }} />,
    compare: <MdCompare style={{ ...iconStyle }} />,
    lastPage: <MdLastPage style={{ ...iconStyle }} />,
    delete: <TiDeleteOutline style={{ ...iconStyle }} />,
    hashtag: <AiOutlineNumber style={{ ...iconStyle }} />,
    duration: <GiDuration style={{ ...iconStyle }} />,
    timed: <GiStopwatch style={{ ...iconStyle }} />,
    intensity: <GiSpeedometer style={{ ...iconStyle }} />
  };

  if (tooltipPosition) {
    tooltipRight = tooltipPosition;
  }

  const getContent = (value) => {
    let isIcon = icons[value];

    if (isIcon) {
      return icons[value];
    } else {
      return value;
    }
  };

  const selectTooltipIconButton = (type) => {
    switch (type) {
      case 'absoluteTooltipMIconButton':
        return (
          <Tooltip
            placement="top"
            title={title}
            arrow
            sx={{ position: 'absolute', right: tooltipRight, bottom: 1 }}
          >
            <Box id={id}>
              <IconButton
                onClick={(e) => onClickEvent(e)}
                sx={{
                  ...buttonStyle
                }}
              >
                {getContent(buttonValue)}
              </IconButton>
            </Box>
          </Tooltip>
        );
      case 'tooltipIconButton':
        return (
          <Tooltip title={title}>
            <IconButton
              id={id}
              color="primary"
              onClick={(e) => onClickEvent(e)}
              disabled={disabled}
              sx={{
                ...buttonStyle
              }}
            >
              {getContent(buttonValue)}
            </IconButton>
          </Tooltip>
        );
      default:
        return (
          <Tooltip placement="top" title={title} arrow>
            <Button
              id={id}
              value={value}
              onClick={(e) => onClickEvent(e)}
              variant={variant ? 'contained' : 'outlined'}
              disabled={disabled}
              sx={{ ...buttonStyle }}
            >
              {getContent(buttonValue)}
            </Button>
          </Tooltip>
        );
    }
  };
  return <Fragment>{selectTooltipIconButton(buttonType)}</Fragment>;
};

MTooltipIconButton.propTypes = {
  buttonValue: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  onClickEvent: PropTypes.func,
  variant: PropTypes.bool,
  buttonType: PropTypes.string,
  buttonStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  tooltipPosition: PropTypes.number,
  id: PropTypes.string,
  disabled: PropTypes.bool
};
