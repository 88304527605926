import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  FaChartArea,
} from 'react-icons/fa';
import { Box, ButtonGroup } from '@mui/material';
import { PropTypes } from 'prop-types';

import { MStatisticsCard } from '../../../components/ui/MStatisticsCard';
import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';
import theme from '../../../styles/theme';
import { kernelDensityEstimation, linspace } from '../../../utils/mathUtils';

import { PARAMETER_CFG } from './MRTConfig';


export const MTRDistributions = ({ results }) => {
  useEffect(() => {
    Chart.pluginService.register({ //eslint-disable-line
      afterDraw: (chart) => {
        if (chart.config.options.usePlugin === 'verticalLine') {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.controller.tooltip._active[0];
            const ctx = chart.ctx;
            const x = activePoint.tooltipPosition().x;
            const topY = chart.scales['y-axis-0'].top;
            const bottomY = chart.scales['y-axis-0'].bottom;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = theme.palette.primary.main;
            ctx.stroke();
            ctx.restore();
          }
        }
      }
    });
  });

  const [parameter, setParameter] = useState('initial_time_ms')

  const config = PARAMETER_CFG[parameter]

  const data = {}
  for (const side of ["L", "R"]) {
    data[side] = results[side].valid_reactions.map(reaction => reaction[parameter])
  }

  
  const maxX = Math.max(...[...data.L, ...data.R])
  if (maxX > config.range[1]){
    config.range[1] = Math.ceil(maxX / config.tickStep) * config.tickStep
  }

  const x = linspace(config.range[0], config.range[1], 300)

  const kde = {}
  for (const side of ["L", "R"]) {
    kde[side] = kernelDensityEstimation(data[side], x, config.bandwidth)
  }

  const maxY = 1.08 * Math.max(...[...kde.L, ...kde.R])

  const plot = {
    labels: [],
    datasets: [
      {
        label: 'Left',
        fill: true,
        borderColor: theme.palette.info.main,
        backgroundColor: `${theme.palette.info.main}90`,
        borderJoinStyle: 'round',
        lineTension: 0.2,
        pointBorderWidth: 1,
        pointRadius: 0,
        borderWidth: 2,
        data: kde.L.map((val, idx) => ({
          x: x[idx],
          y: val
        })),
      },
      {
        label: 'Right',
        fill: true,
        borderColor: theme.palette.info.light,
        backgroundColor: `${theme.palette.info.light}90`,
        borderJoinStyle: 'round',
        lineTension: 0.2,
        pointBorderWidth: 1,
        pointRadius: 0,
        borderWidth: 2,
        data: kde.R.map((val, idx) => ({
          x: x[idx],
          y: -1 * val
        })),
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    usePlugin: 'verticalLine',
    tooltips: {
      intersect: false,
      mode: 'index',
      callbacks: {
        title: (item) => (item[0].xLabel.toFixed(1)),
        label: () => ('')
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    legend: {
      display: true,
      align: 'end',
      labels: {
        padding: 5,
        boxWidth: 20,
        fontSize: 10,
      }
    },
    scales: {
      xAxes: [{
        type: 'linear',
        distribution: 'series',
        scaleLabel: {
          display: true,
          labelString: `${config.label.title} [${config.label.unit}]`,
        },
        ticks: {
          display: true,
          beginAtZero: true,
          min: 0,
          max: config.range[1],
          stepSize: config.tickStep,
        },
        gridLines: {
          display: true
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Occurences',
        },
        ticks: {
          display: false,
          min: -maxY,
          max: maxY,
        },
        gridLines: {
          display: true
        }
      }]
    },
  }

  return (
    <MStatisticsCard title="Distributions" icon={FaChartArea}>
        <Box sx={{display: 'flex', height: 'calc(50vh - 200px)', width: '100%', flexDirection: 'column', p: 1 }}>
            <Box sx={{display: 'flex', height: 'calc(100% - 40px)', width: '100%'}}>
              <Line data={plot} options={options} height="100%" width="100%"/>
            </Box>
            <Box sx={{ display: 'flex', height: 30, marginBottom: 1, width: '100%', justifyContent: 'center', alignItems: 'flex-end' }}>
                <ButtonGroup color="primary">
                  <MTooltipIconButton
                    buttonValue="timed"
                    title="Show Time"
                    value={'initial_time_ms'}
                    onClickEvent={(event) => {setParameter(event.currentTarget.value)}}
                    variant={parameter === 'initial_time_ms'}
                  />
                  <MTooltipIconButton
                      buttonValue="duration"
                      title="Show Duration"
                      value={'duration_ms'}
                      onClickEvent={(event) => {setParameter(event.currentTarget.value)}}
                      variant={parameter === 'duration_ms'}
                  />
                  <MTooltipIconButton
                      buttonValue="intensity"
                      title="Show Intensity"
                      value={'intensity'}
                      onClickEvent={(event) => {setParameter(event.currentTarget.value)}}
                      variant={parameter === 'intensity'}
                  />
                </ButtonGroup>
            </Box>
        </Box>
    </MStatisticsCard>
  )
}


MTRDistributions.propTypes = {
  results: PropTypes.object,
};
