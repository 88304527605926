import { colors } from './colors.js';
import theme from './theme';

const makeAppBarTheme = () => {
  switch (process.env.REACT_APP_GIT_BRANCH) {
    case 'development':
      return {
        color: colors['lightBrown'],
        text: 'Development Build',
        textColor: theme.palette.common.white
      };
    case 'staging':
      return {
        color: colors['brown'],
        text: 'Staging Build',
        textColor: theme.palette.common.white
      };
    case 'master':
      return {
        color: 'secondary',
        text: 'Research',
        textColor: theme.palette.common.white
      };
    case 'certified':
      return {
        color: 'primary',
        text: '',
        textColor: colors['transparent']
      };
    default:
      return {
        color: colors['lightBrown'],
        text: '... under construction ...',
        textColor: theme.palette.common.white
      };
  }
};

export const appBarTheme = makeAppBarTheme();
