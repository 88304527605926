import React, { Fragment, useEffect, useState } from 'react';
import {
  FaCalendarAlt,
  FaClock,
  FaWalking
} from 'react-icons/fa';
import { MdVibration } from 'react-icons/md';
import { MdAccountCircle } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { MCommentField } from '../../../components/ui/MCommentField.js';
import { MStatisticsCard } from '../../../components/ui/MStatisticsCard.js';
import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';
import { useGlobalContext } from '../../../context/AppContext.js';
import { id2TitleMap } from '../../../definitions/activityTypes.js';
import {
  loadPatientData,
  makeDateString
} from '../../../utils/auxFunctions.js';
import { formatDoctorInfo } from '../../../utils/dataFetchFunctions.js';
import { annotatePdf, downloadPdf, fetchPdf } from '../../../utils/pdfTools.js';

const DATE_FORMAT = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
};


/**
 * Assessment details component
 * @return {jsx} The Assessment details component.
 */
export const MPostDetails = ({ results }) => {
  const [isMarked, setMarked] = useState(false);

  const { doctorInfoEvents } = useGlobalContext();

  const history = useHistory();

  const patientData = loadPatientData();
  const patientName = patientData.firstName + ' ' + patientData.lastName;
  const apiToken = patientData.apiToken;

  const activityID = results.id;
  const markedActivities = new Set(JSON.parse(sessionStorage.getItem('markedActivities')));

  useEffect(() => {
    setMarked(markedActivities.has(activityID));
  }, [activityID]);

  const markForComparisonClick = () => {
    if (isMarked) {
      markedActivities.delete(activityID);
    } else {
      markedActivities.add(activityID);
    }

    sessionStorage.setItem('markedActivities', JSON.stringify([...markedActivities]));
    setMarked(!isMarked);
  };

  const downloadPdfClick = async () => {
    try {
      const assessmentDate = results.date.toISOString().slice(0, -8);

      const filename =
        `${patientData.firstName[0]}${patientData.lastName}_${results.type}_${assessmentDate}`
          .replaceAll('-', '')
          .replaceAll(':', '');

      const commentText = '';
      fetchPdf(apiToken, activityID, filename)
        .then((pdf) => {
          return annotatePdf(
            pdf,
            patientData,
            formatDoctorInfo(doctorInfoEvents),
            commentText
          );
        })
        .then((aPdf) => {
          downloadPdf(aPdf, filename);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const backToPatientClick = () => {
    history.push(
      {
        pathname: '/patient/' + patientData.username,
        state: location.state
      }
    );
  };

  const makeTime = (date) => {
    let min = date.getMinutes().toString();
    if (min.length < 2) {
      min = '0' + min;
    }
    return date.getHours().toString() + ':' + min;
  };

  const titlePostfix = (results.variant === 'full') ? '' : ' [Partial]'
  const generalDetails = () => [
    {
      title: 'Patient',
      icon: MdAccountCircle,
      value: patientName
    },
    {
      title: 'Date',
      icon: FaCalendarAlt,
      value: makeDateString(results.date, DATE_FORMAT)
    },
    {
      title: 'Time',
      icon: FaClock,
      value: makeTime(results.date)
    },
    {
      title: 'Type',
      icon: FaWalking,
      value:  id2TitleMap(results.type) + titlePostfix
    }
  ];

  return (
    <Fragment>
      <Box sx={{ display: 'flex', width: '100%', position: 'absolute', top: 2 }}>
        <Grid container>
          <Grid item xs={6} alignItems="start" justify="flex-start" sx={{ display: 'flex' }}>
            <Box>
              <MTooltipIconButton
                id="mark-for-comparison-button"
                buttonValue={isMarked ? 'bookmarksStarFill' : 'bookmarksStar'}
                title={isMarked ? 'Unmark' : 'Mark'}
                buttonType="tooltipIconButton"
                onClickEvent={markForComparisonClick}
              />
            </Box>
            <Box>
              <MTooltipIconButton
                id="download-report-button"
                buttonValue="filledDownload"
                title="Download Report"
                buttonType="tooltipIconButton"
                onClickEvent={downloadPdfClick}
              />
            </Box>
          </Grid>
          <Grid item xs={6} alignItems="end" justifyContent="flex-end" sx={{ display: 'flex' }}>
            <Box>
              <MTooltipIconButton
                id="go-back-to-patient-page-button"
                buttonValue="arrowFromButton"
                title="Go to Patient Page"
                buttonType="tooltipIconButton"
                onClickEvent={backToPatientClick}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          overflow: 'scroll',
          height: '100%',
          p: 1
        }}
      >
        <Stack spacing={2} sx={{ height: '100%' }}>
          <Grid container justifyContent="center" align="center">
            {generalDetails().map((detail) => {
              return (
                <Grid item xs key={uuidv4()}>
                  <MStatisticsCard icon={detail.icon} title={detail.title}>
                    <Box
                      sx={{
                        display: 'flex',
                        height: 35,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {detail.title === 'Patient' ? (
                        <Typography
                          align="center"
                          variant="p"
                          color="primary"
                          sx={{ fontSize: 14, lineHeight: 1.1 }}
                        >
                          {detail.value.split(' ')[0]} <br />
                          {detail.value.split(' ')[1]}
                        </Typography>
                      ) : (
                        <Box sx={{ display: 'flex' }}>
                          <Typography
                            align="center"
                            variant="p"
                            color="primary"
                            sx={{ fontSize: 14, lineHeight: 1.1 }}
                          >
                            {detail.value}
                          </Typography>
                          {results.vibrationType && detail.title === 'Type' && (
                            <Fragment>
                              <Typography
                                align="center"
                                variant="p"
                                color="primary"
                                sx={{
                                  fontSize: 14,
                                  marginLeft: 0.5,
                                  lineHeight: 1.1
                                }}
                              >
                                +
                              </Typography>
                              <Tooltip title={results.vibrationType}>
                                <Box>
                                  <MdVibration
                                    style={{
                                      marginLeft: 5,
                                      fontSize: 18
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                            </Fragment>
                          )}
                        </Box>
                      )}
                    </Box>
                  </MStatisticsCard>
                </Grid>
              );
            })}
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <MCommentField apiToken={apiToken} title="Assessment Comments" id="AssessmentComment" />
          </Box>
        </Stack>
      </Box>
    </Fragment>
  );
};

MPostDetails.propTypes = {
  results: PropTypes.object,
};
