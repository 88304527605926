import React from 'react';
import { Link, Typography } from '@mui/material';

const branch2versionSuffix = () => {
  switch (process.env.REACT_APP_GIT_BRANCH) {
    case 'development':
      return 'a';
    case 'staging':
      return 'b';
    default:
      return '';
  }
};

/**
 * Renders the "Copright" component, a notice including link.
 * @return {jsx} The about page component.
 */
export const MCopyright = () => {
  const suffix = branch2versionSuffix();
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://www.magnes.ch">
        Magnes AG
      </Link>
      <span>
        {' '}
        © {new Date().getFullYear()}
        <br />
        {`v${process.env.REACT_APP_VERSION}${suffix}`}
      </span>
    </Typography>
  );
};
