import React, { Fragment } from 'react';
import { Box, ButtonGroup, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MHistogramChart } from '../../../components/ui/MHistogramChart.js';
import { MSlopeChart } from '../../../components/ui/MSlopeChart.js';
import { MStatisticsCard } from '../../../components/ui/MStatisticsCard.js';
import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';
import { colors } from '../../../styles/colors.js';


/**
 * Show left and right symmetry.
 * @param {Object} props The component properties.
 * @return {jsx} The symmetry chart component.
 */
export const MComparisonResultCard = ({ parameter, data }) => {
  const [chartType, setChartType] = React.useState('bar');

  const theme = useTheme();

  const { icon, title, unit } = parameter;

  const changeChartType = (event) => {
    setChartType(event.currentTarget.value);
  };

  const colorMap = {
    'Group A': {
      L: colors['green'],
      R: colors['lightGreen']
    },
    'Group B': {
      L: theme.palette.info.main,
      R: theme.palette.info.light
    }
  };
  const datasets = [];
  for (const group of ['Group A', 'Group B']) {
    for (const side of ['L', 'R']) {
      datasets.push({
        label: `${group} - ${side}`,
        color: colorMap[group][side],
        values: data[group][side].values
      });
    }
  }

  const chartHeights = {
    avg: '60%',
    std: '40%',
  }
  return (
    <MStatisticsCard title={title} icon={icon}>
      <Stack sx={{ height: 'calc((100vh - 330px) * 0.5)', minHeight: 300, marginTop: -1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 35px)' }}>
          {chartType === 'bar' && (
            <Fragment>
              <Grid container spacing={0} key={`gc-${title}`} sx={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%'}}>
                { ['avg', 'std'].map( (stat, idx) => (
                  <Grid container xs={12} key={`${parameter}-${stat}`} sx={{ display: 'flex', height: chartHeights[stat], justifyContent: 'center' }}>
                    <Grid item xs={12}>
                      <Divider spacing={1}>
                        <Typography variant="overline" sx={{ fontSize: 9 }}>
                            {stat}
                        </Typography>
                      </Divider>
                    </Grid>
                    {
                      ["L", "R"].map( (side) => (
                        <Grid container xs={6} key={`gc-${title}-${side}`} sx={{ display: 'flex', justifyContent: 'center', paddingInline: 1, height: 'calc(100% - 12px)' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              height: 'calc(100% - 20px)',
                              width: '100%',
                              overflow: 'hidden',
                              borderRadius: 2,
                              backgroundColor: `${colors.darkGrey}${((idx + 1) * 35 )}`
                            }}>
                            <Grid container sx={{ paddingInline: 1, top: 0 }}>
                              <Grid item xs={6}>
                                <Typography align="left" sx={{ fontSize: 10 }}>
                                  {`${data['Group A'][side][stat].toFixed(2)}${parameter.unit}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography align="right" sx={{ fontSize: 10 }}>
                                  {`${data['Group B'][side][stat].toFixed(2)}${parameter.unit}`}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Box sx={{ height: 'calc(100% - 30px)', width: '100%' }}>
                              <MSlopeChart
                                points={[
                                  data['Group A'][side][stat],
                                  data['Group B'][side][stat]
                                ]}
                                options={{
                                  x0: {
                                    color: colorMap["Group A"]["L"],
                                    label: 'Group A'
                                  },
                                  x1: {
                                    color: colorMap["Group B"]["L"],
                                    label: 'Group B'
                                  }
                                }}/>
                            </Box>
                          </Box>
                        </Grid>
                      ))
                    }
                  </Grid>
                ))}
              <Grid container xs={12}>
                      <Grid item xs={6}>
                        <Typography align="center" sx={{ fontSize: 10 }}>
                          Left
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="center" sx={{ fontSize: 10 }}>
                          Right
                        </Typography>
                      </Grid>
                    </Grid>
              </Grid>
            </Fragment>
          )}
          {chartType === 'hist' && (
            <MHistogramChart title={title} datasets={datasets} unit={unit}/>
          )}
        </Box>

        {/* Card controllers */}
        <Box
          align="center"
          sx={{
            display: 'flex',
            align: 'center',
            justifyContent: 'center',
            height: 30
          }}
        >
          <ButtonGroup color="primary" sx={{ align: 'center' }}>
            <MTooltipIconButton
              buttonValue="chartBar"
              title="Show Statistics"
              value="bar"
              onClickEvent={changeChartType}
              variant={chartType === 'bar'}
            />
            <MTooltipIconButton
              buttonValue="chartArea"
              title="Show Histogram"
              value="hist"
              onClickEvent={changeChartType}
              variant={chartType === 'hist'}
            />
          </ButtonGroup>
        </Box>
      </Stack>
    </MStatisticsCard>
  );
};

MComparisonResultCard.propTypes = {
  parameter: PropTypes.object,
  data: PropTypes.object
};
