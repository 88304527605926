import { AiOutlineColumnHeight } from 'react-icons/ai';
import {
  FaPercentage,
  FaRulerCombined,
  FaRulerHorizontal,
  FaStopwatch,
  FaTachometerAlt
} from 'react-icons/fa';

const gaitParameters = [
  {
    id: 'length',
    title: 'Stride Length',
    icon: FaRulerHorizontal,
    unit: 'm'
  },
  {
    id: 'velocity',
    title: 'Stride Velocity',
    icon: FaTachometerAlt,
    unit: 'm/s'
  },
  {
    id: 'duration',
    title: 'Stride Duration',
    icon: FaStopwatch,
    unit: 's'
  },
  {
    id: 'swing_stance_ratio',
    title: 'Swing vs Stance',
    icon: FaPercentage,
    unit: ''
  },
  {
    id: 'strike_angle',
    title: 'Strike Angle',
    icon: FaRulerCombined,
    unit: '°'
  },
  // {
  //   id: 'supination_angle',
  //   title: 'Supination Angle',
  //   icon: FaRulerCombined,
  //   unit: '°',
  // },
  {
    id: 'heel-clearance',
    title: 'Heel Clearance',
    icon: AiOutlineColumnHeight,
    unit: 'cm'
  }
];

gaitParameters.forEach((gp) => {
  gp['stat-id'] = `stride-${gp.id.split('_').join('-')}`;
});

export { gaitParameters };
