import React from 'react';
import { Grid, Stack } from '@mui/material';
import { PropTypes } from 'prop-types';

import { MCardLgScroll } from './MCardLgScroll.js';

/**
 * Patient page layout wireframe
 * @return {jsx} The layout page component.
 */
export const MAssessmentLayout = ({
  containerA,
  containerB,
  containerC,
  containerD,
  containerE
}) => {
  return (
    <Grid container spacing={2} sx={{ height: '100%', width: '100%' }}>
      <Grid item xs sx={{ height: '100%', overflow: 'scroll' }}>
        <Stack spacing={4} direction="column">
          <MCardLgScroll title="Results">{containerA}</MCardLgScroll>
          {containerE && <MCardLgScroll title="Fatigue">{containerE}</MCardLgScroll>}
        </Stack>
      </Grid>
      <Grid container item xs={12} md={3.5} sx={{ height: '100%' }}>
        <Grid item xs={12} sx={{ height: containerC ? 'calc(100% - 307px)' : 'calc(100% - 90px)' }}>
          <MCardLgScroll title="Assessment Details">{containerB}</MCardLgScroll>
        </Grid>
        {containerC && (
          <Grid item xs={12} sx={{ marginTop: 2, height: 200, backgroundColor: 'green' }}>
            <MCardLgScroll title="Regions of Walking">{containerC}</MCardLgScroll>
          </Grid>
        )}
        <Grid item xs={12} sx={{ marginTop: 2, height: 80 }}>
          {containerD}
        </Grid>
      </Grid>
    </Grid>
  );
};

MAssessmentLayout.propTypes = {
  containerA: PropTypes.node,
  containerB: PropTypes.node,
  containerC: PropTypes.node,
  containerD: PropTypes.node,
  containerE: PropTypes.node
};
