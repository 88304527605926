import React from 'react';
import { FaRoute, FaShoePrints, FaTags } from 'react-icons/fa';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MStatisticsCard } from '../../components/ui/MStatisticsCard.js';
import { MUnit } from '../../components/ui/MUnit.js';
import { colors } from '../../styles/colors.js';
import { makeDistanceString, makeTimeString } from '../../utils/auxFunctions.js';

/**
 * Card with Summary stats and timeline details
 * @return {jsx} Card with Summary stats and timeline details.
 */
export const MSummaryStats = ({totals, weeklyChange}) => {
  const theme = useTheme();
  const cardItems = [
    {
      title: 'Total Steps',
      id: 'total-steps',
      icon: FaShoePrints,
      total: {
        value: totals['total-steps'],
        unit: ''
      },
      new: {
        value: weeklyChange['total-steps'],
        unit: ''
      }
    },
    {
      title: 'Total Assessments',
      id: 'total-assessments',
      icon: FaTags,
      total: {
        value: totals['total-assessments'],
        unit: ''
      },
      new: {
        value: weeklyChange['total-assessments'],
        unit: ''
      }
    },
    {
      title: 'Total Distance',
      id: 'total-distance',
      icon: FaRoute,
      total: makeDistanceString(totals['total-distance']),
      new: makeDistanceString(weeklyChange['total-distance'])
    },
    {
      title: 'Total Duration',
      id: 'total-duration',
      icon: FaTags,
      total: makeTimeString(totals['total-duration']),
      new: makeTimeString(weeklyChange['total-duration'])
    }
  ];

  return (
    <Box sx={{ p: 1, pt: 0, width: '100%' }}>
      <Grid container spacing={2} align="center" justifyContent="center">
        {cardItems.map((item) => {
          return (
            <Grid item xs={12} md={6} key={item.id}>
              <MStatisticsCard title={item.title} icon={item.icon}>
                <Typography align="center" color="secondary" sx={{ fontSize: 20 }}>
                  {item.total.value}
                  <MUnit unit={item.total.unit} />
                </Typography>
                {Number(item.new.value) !== 0 ? (
                  <Typography
                    align="center"
                    sx={{ fontSize: 10, color: theme.palette.success.main }}
                  >
                    {`+${item.new.value} ${item.new.unit}`}
                  </Typography>
                ) : (
                  <Typography align="center" sx={{ fontSize: 10, color: colors['transparent'] }}>
                    `xx`
                  </Typography>
                )}
              </MStatisticsCard>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

MSummaryStats.propTypes = {
  totals: PropTypes.object,
  weeklyChange: PropTypes.object,
};