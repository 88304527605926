import React, { Fragment, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import { MOverlay } from '../../../components/ui/MOverlay.js';
import { linearRegression, linspace } from '../../../utils/mathUtils';

/**
 * Renders an extended trend chart for left and right data series.
 * @param {Object} props The component properties.
 * @return {jsx} The trend chart component.
 */
export const MTrendChart = ({ title, data, xAxisLabels, yAxisLabel }) => {
  const [overlay, setOverlay] = useState(false);
  const theme = useTheme();

  const dataLength = Object.values(data)[0].length;

  const chartData = {
    labels: xAxisLabels,
    datasets: Object.entries(data).map(([k, values]) => {
      let color;
      let labelTxt;
      switch (k) {
        case 'values-left':
          color = theme.palette.info.main;
          labelTxt = 'Left';
          break;
        case 'values-right':
          color = theme.palette.secondary.main;
          labelTxt = 'Right';
          break;
        default:
          color = theme.palette.secondary.main;
          labelTxt = 'Total';
          break;
      }
      return {
        label: labelTxt,
        fill: false,
        backgroundColor: `${color}aa`,
        borderColor: color,
        borderJoinStyle: 'round',
        lineTension: 0.2,
        pointBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        barPercentage: dataLength < 100 ? 1 : 2,
        data: values.map((v) => Number(v).toFixed(2))
      };
    })
  };

  // Make trend lines only if there's not a massive amount of datapoints
  const nonZeroesLength = Object.values(data)[0].filter((value) => {
    if (value !== 0) {
      return value;
    }
  }).length;
  if (dataLength < 100 && nonZeroesLength > 3) {
    chartData.datasets.unshift(
      Object.entries(data).map(([k, values]) => {
        let color;
        let labelTxt;
        switch (k) {
          case 'values-left':
            color = theme.palette.primary.main;
            labelTxt = 'Left';
            break;
          case 'values-right':
            color = theme.palette.grey['A400'];
            labelTxt = 'Right';
            break;
          default:
            color = theme.palette.primary.main;
            labelTxt = '';
            break;
        }
        return {
          label: `${labelTxt} Trend`,
          borderColor: color,
          borderDash: [3, 2],
          fill: false,
          type: 'line',
          data: linearRegression(linspace(0, values.length - 1, values.length), values).map((v) =>
            v.toFixed(2)
          )
        };
      })
    );
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: yAxisLabel
          },
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  return (
    <Fragment>
      <Box onClick={() => setOverlay(true)} sx={{ pb: 2, height: '100%' }}>
        <Bar data={chartData} options={options} />
      </Box>
      <MOverlay open={overlay} onClose={() => setOverlay(false)} title={title}>
        <Bar data={chartData} options={options} />
      </MOverlay>
    </Fragment>
  );
};

MTrendChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  xAxisLabels: PropTypes.array.isRequired,
  yAxisLabel: PropTypes.string
};
