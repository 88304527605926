import React, { Fragment, useEffect, useState } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import { AiOutlineFileUnknown } from 'react-icons/ai';
import { HiOutlineDocumentSearch } from 'react-icons/hi';
import { MdOutlineHelpOutline } from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, CircularProgress, Dialog, Divider, Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { MStatisticsCard } from '../../components/ui/MStatisticsCard';
import { MTooltipIconButton } from '../../components/ui/MTooltipIconButton';
import { downloadPdf } from '../../utils/pdfTools.js';

if (getCookieConsentValue()) {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID, {
    testMode: process.env.NODE_ENV === 'test'
  });
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DEFINITION_FILE = { id: '1woWwBwusmBbM5jbBBqTPl73MGb4m1-EY', type: 'document' };
const QUICK_GUIDE_FILE = { id: '1frRkkDZ858ADaAQtvix0D_Si95JClvsl', type: 'presentation' };

/**
 * Renders the "About" page. Gives general info about Magnes and Nushu.
 * @return {jsx} The about page component.
 */
export const Documentation = () => {
  const defaultShowPdf = { 'Gait Parameters': false, 'Quick Guide': false };

  const [documentPdf, setDocumentPdf] = useState(undefined);
  const [quickGuidePdf, setQuickGuidePdf] = useState(undefined);
  const [showPdf, setShowPdf] = useState(defaultShowPdf);
  const [numberPages, setNumberPages] = useState(null);

  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/compare', title: 'Documentation Page' });
  }, []);

  useEffect(() => {
    const getFile = async ({ id, type }) => {
      const url = `https://docs.google.com/${type}/d/${id}/export?format=pdf`;
      const pdfFileAb = await fetch(url).then((res) => res.arrayBuffer());

      return new Uint8Array(pdfFileAb);
    };
    if (!documentPdf || documentPdf !== 'loading') {
      setDocumentPdf('loading');
      getFile(DEFINITION_FILE)
        .then((res) => setDocumentPdf(res))
        .catch((err) => {
          console.error(err);
          setDocumentPdf('failed');
        });
    }

    if (!quickGuidePdf || quickGuidePdf !== 'loading') {
      setQuickGuidePdf('loading');
      getFile(QUICK_GUIDE_FILE)
        .then((res) => setQuickGuidePdf(res))
        .catch((err) => {
          console.error(err);
          setQuickGuidePdf('failed');
        });
    }
  }, []);

  const items = [
    {
      title: 'Gait Parameters',
      description:
        'Documentation on how the shown gait parameter are defined and how they are calculated by our NUSHU system.',
      pdf: documentPdf,
      icon: HiOutlineDocumentSearch
    },
    {
      title: 'Quick Guide',
      description: 'A guide to the dashboard, detailing all available features.',
      pdf: quickGuidePdf,
      icon: MdOutlineHelpOutline
    }
  ];

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumberPages(numPages);
  };

  const handleShowPdfClick = (title) => {
    const _showPdf = { ...defaultShowPdf };
    _showPdf[title] = true;
    setShowPdf(_showPdf);
  };

  const handleClosePdfClick = () => {
    setShowPdf(defaultShowPdf);
  };

  const isDialogOpen = () => {
    const open = !Object.values(showPdf).every((val) => val === false);
    return open;
  };

  const dialogPdf = () => {
    const dp = items.filter((item) => showPdf[item.title]);
    if (dp.length !== 1) {
      return undefined;
    } else {
      return dp[0].pdf;
    }
  };

  const pdfFilename = () => {
    const dp = items.filter((item) => showPdf[item.title]);
    if (dp.length !== 1) {
      return undefined;
    } else {
      const filename = 'Nushu' + dp[0].title.replaceAll(' ', '');
      return filename;
    }
  };

  return (
    <Fragment>
      <Grid container justifyContent="center" align="center" spacing={4}>
        {items.map((item) => {
          return (
            <Grid item xs={12} md={4} align="center" justifyContent="center" key={uuidv4()}>
              <MStatisticsCard title={<big>{item.title}</big>} icon={item.icon}>
                <Box
                  align="center"
                  justifyContent="center"
                  sx={{ display: 'flex', flexGrow: 1, pl: 5, pr: 5 }}>
                  <Typography variant="subtitle2" align="center">
                    {item.description}
                  </Typography>
                </Box>
                <Box sx={{ height: 60 }}>
                  <Divider />
                  {item.pdf === 'loading' && (
                    <CircularProgress color="secondary" style={{ marginTop: 10 }} />
                  )}
                  {item.pdf === 'failed' && <AiOutlineFileUnknown style={{ fontSize: 40 }} />}
                  {typeof item.pdf === 'object' && (
                    <MTooltipIconButton
                      buttonValue="fillFilePdf"
                      title="View File"
                      onClickEvent={() => handleShowPdfClick(item.title)}
                      buttonType="tooltipIconButton"
                      iconStyle={{ fontSize: 40 }}
                    />
                  )}
                </Box>
              </MStatisticsCard>
            </Grid>
          );
        })}
      </Grid>
      <Fragment>
        <Dialog open={isDialogOpen()} onClose={handleClosePdfClick}>
          <Box sx={{ padding: 2, overflow: 'scroll' }}>
            <Document
              file={{ data: dialogPdf() }}
              options={{ workerSrc: '/pdf.worker.js' }}
              onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numberPages), (el, index) => (
                <Page key={uuidv4()} pageNumber={index + 1} />
              ))}
            </Document>
          </Box>
          <Box sx={{ mx: 'auto', py: 1 }}>
            <MTooltipIconButton
              buttonValue="filledDownload"
              title="Download"
              onClickEvent={() => downloadPdf(dialogPdf(), pdfFilename())}
              buttonType="tooltipIconButton"
              iconStyle={{ fontSize: 30 }}
            />
          </Box>
        </Dialog>
      </Fragment>
    </Fragment>
  );
};
