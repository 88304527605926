import React, { Fragment } from 'react';
import { Grid } from '@mui/material';
import { PropTypes } from 'prop-types';

import { MTRCombinatorial } from './MRTCombinatorial';
import { MTRDistributions } from './MRTDistributions';
import { MRTSummary } from './MRTSummary';


export const MRTResults = ({ results }) => {

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ height: '100%', p: 1, justifyContent: 'center' }}>
        <Grid item xs={6}>
          <MTRDistributions results={results}/>
        </Grid>
        <Grid item xs={6}>
          <MRTSummary results={results}/>
        </Grid>
        <Grid item xs={12} sx={{ minHeight: 400, height: 'calc((100vh - 160px) / 2)' }}>
          <MTRCombinatorial results={results} />
        </Grid>
      </Grid>
    </Fragment>
  )
}


MRTResults.propTypes = {
  results: PropTypes.object,
};