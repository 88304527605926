import React from 'react';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { PropTypes } from 'prop-types';

const columns = [
  {
    field: 'username',
    headerName: 'Username',
    width: 140
  },
  {
    field: 'firstName',
    headerName: 'Surname',
    width: 130
  },
  {
    field: 'lastName',
    headerName: 'Name',
    width: 130
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90
  },
  {
    field: 'firstAssessment',
    headerName: 'First assessment date',
    type: 'number',
    width: 150
  },
  {
    field: 'lastAssessment',
    headerName: 'Last assessment date',
    type: 'number',
    width: 150
  },
  {
    field: 'total-assessments',
    headerName: 'Total assessments',
    type: 'number',
    width: 180
  },
  {
    field: 'trend',
    headerName: 'Trend',
    type: 'number',
    width: 120
  }
];

/**
 * Displays patients data in a grid table.
 * @param {Object} props The component properties.
 * @return {jsx} The patient data table component.
 */
export const MPatientsTable = ({ patientsList }) => {
  const history = useHistory();
  
  const redirectToPage = (item) => {
    const patient = item.row;
    if (!patient.upToDate) return;

    sessionStorage.setItem('patientData', JSON.stringify(patient));
    history.push({
      pathname: '/patient/' + patient.username
    });
  };

  return (
    <div style={{ height: 800, width: '100%' }}>
      <DataGrid columns={columns} onRowClick={redirectToPage} pageSize={13} rows={patientsList} />
    </div>
  );
};

MPatientsTable.propTypes = {
  patientsList: PropTypes.array
};
